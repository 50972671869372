import { ILocalizedError, IMessage, IMessageList } from '@localina/core';
import { useInfiniteQuery, useMutation, UseMutationOptions, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { UseInfiniteQueryOptions } from '@tanstack/react-query/src/types';
import { LocalinaApiContext } from '../../../index';
import { useRestaurantId } from '../../utils/RestaurantUtils';
import { queryKeys } from './query-keys';
import { getOptionsEnabled } from './utils';

type TPageParam = {
    page: number;
    limit: number;
};

function useRestaurantMessages(
    options?: UseInfiniteQueryOptions<IMessageList, ILocalizedError, IMessageList, IMessageList, TQueryKey>,
) {
    const optionsEnabled = getOptionsEnabled(options);
    const restaurantId = useRestaurantId();

    return useInfiniteQuery({
        queryFn: ({ pageParam = { page: 0, limit: 20 } }: { pageParam?: TPageParam }) => {
            return LocalinaApiContext.serviceApi.getMessageList(restaurantId, pageParam.page, pageParam.limit);
        },
        queryKey: queryKeys.restaurants.single.messages.all(restaurantId),
        ...options,
        enabled: Boolean(restaurantId && optionsEnabled),
        getNextPageParam: (lastPage) => {
            return lastPage.pages !== 0 && lastPage.currentPage < lastPage.pages - 1
                ? { page: lastPage.currentPage + 1, limit: lastPage.limit }
                : undefined;
        },
        getPreviousPageParam: (firstPage) => {
            return firstPage.pages !== 0 && firstPage.currentPage > 0
                ? { page: firstPage.currentPage - 1, limit: firstPage.limit }
                : undefined;
        },
    });
}

function useGetMessage(
    messageId: string,
    options?: UseQueryOptions<
        IMessage,
        ILocalizedError,
        IMessage,
        ReturnType<typeof queryKeys.restaurants.single.messages.single>
    >,
) {
    const optionsEnabled = getOptionsEnabled(options);
    const restaurantId = useRestaurantId();

    return useQuery({
        queryFn: () => LocalinaApiContext.serviceApi.getMessage(restaurantId, messageId),
        queryKey: queryKeys.restaurants.single.messages.single(restaurantId, messageId),
        ...options,
        enabled: Boolean(restaurantId && optionsEnabled),
    });
}

const useCreateMessage = (options?: UseMutationOptions<IMessage, ILocalizedError, IMessage>) => {
    const restaurantId = useRestaurantId();

    return useMutation({
        mutationFn: createMessageMutationFn(restaurantId),
        ...options,
    });
};

const useUpdateMessage = (options?: UseMutationOptions<void, ILocalizedError, IMessage>) => {
    const restaurantId = useRestaurantId();

    return useMutation({
        mutationFn: updateMessageMutationFn(restaurantId),
        ...options,
    });
};
const useDeleteMessage = (options?: UseMutationOptions<void, ILocalizedError, IMessage>) => {
    const restaurantId = useRestaurantId();

    return useMutation({
        mutationFn: deleteMessageMutationFn(restaurantId),
        ...options,
    });
};
const createMessageMutationFn = (restaurantId: string) => (message: IMessage) =>
    LocalinaApiContext.serviceApi.createMessage(restaurantId, message);
const updateMessageMutationFn = (restaurantId: string) => (message: IMessage) =>
    LocalinaApiContext.serviceApi.updateMessage(restaurantId, {
        ...message,
        displayTexts: message.displayTexts.map((dt) => {
            return {
                ...dt,
                language: dt.language.toLocaleLowerCase(),
            };
        }),
    });

const deleteMessageMutationFn = (restaurantId: string) => (message: IMessage) =>
    LocalinaApiContext.serviceApi.deleteMessage(restaurantId, message);

type TQueryKey = ReturnType<typeof queryKeys.restaurants.single.messages.all>;

export { useRestaurantMessages, useGetMessage, useCreateMessage, useUpdateMessage, useDeleteMessage };
