import {
    DATE_FORMAT,
    DATE_FORMAT_SHORTEN,
    GuestStatus,
    IReservation,
    IReservationsDateTimeGroup,
    IReservationsShiftGroupWithGuestsNumber,
    IRestaurantConfiguration,
    Label,
    ReservationUtils,
    StringUtils,
    TIME_FORMAT,
    TLanguageKey,
} from '@localina/core';
import { AddCircleIcon, CompanyIcon, PeopleIcon, TextBlockIcon } from '@localina/icons';
import { CommentOutlined } from '@mui/icons-material';
import { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHaveAccountFeatures } from '../../api/queries/account';
import { IReservationForm } from '../../schemas/interfaces';
import { getTablesNamesByIds } from '../../utils/TablePlanService';
import { GuestStatusIcon } from '../GuestStatusIcon';
import { ReservationGuestAdditionalInfo } from '../ReservationGuestAdditionalInfo';
import ReservationOriginIcon from '../ReservationOriginIcon/ReservationOriginIcon';
import { getIconForStatus } from '../ReservationStatus';
import ReservationStatusActionIcon from '../ReservationStatus/ReservationStatusActionIcon';
import { ReservationTableNumbers } from '../ReservationTableNumbers';
import ReservationAreaCodes from './ReservationAreaCodes';

interface IProps extends IReservationsShiftGroupWithGuestsNumber {
    restaurantConfiguration: IRestaurantConfiguration;
    onCreate: (defaultValues?: Partial<IReservationForm>) => void;
    onEdit: (reservation: IReservation) => void;
    unassignedShift?: boolean;
}

const ReservationsShiftGroup: React.FC<IProps> = (props) => {
    const { i18n, t } = useTranslation();

    const { width, ref: shiftgroupWrapperRef } = useObserveDivWidth();

    const mediaQueryClassName = useMemo(() => {
        let reservationsSize = '';
        if (width > 1200) {
            reservationsSize = 'extra-large';
        } else if (width > 1000) {
            reservationsSize = 'large';
        } else if (width > 700) {
            reservationsSize = 'medium';
        } else if (width > 480) {
            reservationsSize = 'small';
        } else {
            reservationsSize = 'extra-small';
        }

        let reservationCommentsDisplayed = '';
        if (width > 1015) {
            reservationCommentsDisplayed = 'display-comments';
            if (width < 1172) {
                reservationCommentsDisplayed += ' dense-table';
            }
        }

        return StringUtils.combineStrings([reservationsSize, reservationCommentsDisplayed]);
    }, [width]);

    const [reservationsGroups, setReservationsGroups] = React.useState<IReservationsDateTimeGroup[]>([]);

    const [canUseTablePlans, canUseColorIndicator] = useHaveAccountFeatures(['tablePlans', 'reservationColor']);

    React.useEffect(() => {
        if (props.reservations) {
            setReservationsGroups(ReservationUtils.groupReservationsForDateTime(props.reservations));
        }
    }, [props.reservations]);

    const handleCreateReservation = (datetime: string) => () => {
        props.onCreate({
            date: [DateTime.fromISO(datetime).toFormat(DATE_FORMAT)],
            time: DateTime.fromISO(datetime).toFormat(TIME_FORMAT),
        });
    };

    const handleEditReservation = (reservation: IReservation) => () => {
        props.onEdit(reservation);
    };

    const shiftNotAssignedOrBad = props.unassignedShift || !props.shift;

    return (
        <div
            ref={shiftgroupWrapperRef}
            className={StringUtils.combineStrings([
                mediaQueryClassName,
                'reservations-shiftgroup',
                shiftNotAssignedOrBad && 'unassigned-shift',
                !reservationsGroups.length && 'display-none',
            ])}
        >
            <div className="reservations-shiftgroup__header">
                <Label
                    type="title"
                    value={props.shift?.name[i18n.language as TLanguageKey] ?? t('reservations.group.unknown')}
                    variant="body2"
                />
                <div className="reservations-shiftgroup__header__info">
                    <Label type="info" value={t('reservations.group.participants')} />
                    <Label type="info" value={props.guests} />
                </div>
            </div>
            <div className="reservations-shiftgroup__body">
                {reservationsGroups.map((reservationsGroup) => {
                    const timeGroupPeopleCount = reservationsGroup.reservations.reduce((acc, res) => {
                        if (!ReservationUtils.isReservationCancelled(res)) {
                            acc += res.participants;
                        }
                        return acc;
                    }, 0);
                    return (
                        <div key={reservationsGroup.datetime} className="reservations-shiftgroup__time-group">
                            <div className="reservations-shiftgroup__time-group__header">
                                {timeGroupPeopleCount > 0 && (
                                    <Label
                                        type={'info'}
                                        value={t('reservations.view.countOfGuestsAtExactTime.label', {
                                            count: timeGroupPeopleCount,
                                        })}
                                    />
                                )}
                                <Label
                                    type="text"
                                    value={DateTime.fromISO(reservationsGroup.datetime, { zone: 'utc' })
                                        .toLocal()
                                        .toFormat(TIME_FORMAT)}
                                />
                                {DateTime.local() <
                                    DateTime.fromISO(reservationsGroup.datetime, { zone: 'utc' }).toLocal() && (
                                    <div className="reservations-shiftgroup__time-group__header__action">
                                        <AddCircleIcon onClick={handleCreateReservation(reservationsGroup.datetime)} />
                                    </div>
                                )}
                            </div>
                            <div className="reservations-shiftgroup__time-group__body">
                                {reservationsGroup.reservations.map((reservation) => (
                                    <div
                                        key={reservation.id}
                                        className={StringUtils.combineStrings([
                                            'reservations-shiftgroup__time-group__reservation',
                                            ReservationUtils.isReservationCancelled(reservation) &&
                                                'reservations-shiftgroup__time-group__cancelled',
                                            reservation.guestInfo?.guestStatus === GuestStatus.VIP && 'vip',
                                            reservation.guestInfo?.guestStatus === GuestStatus.BLACKLIST && 'blacklist',
                                            !reservation.guestInfo && 'walkin',
                                        ])}
                                        onClick={handleEditReservation(reservation)}
                                    >
                                        {Boolean(canUseColorIndicator && reservation.color) && (
                                            <div
                                                className={'reservations-shiftgroup__time-group__color-indicator'}
                                                style={{ backgroundColor: reservation.color! }}
                                            />
                                        )}
                                        <div className="reservation__guest">
                                            <div className="reservation__guest__guest-status">
                                                <GuestStatusIcon guestStatus={reservation.guestInfo?.guestStatus} />
                                            </div>
                                            <div className="reservation__guest__name-wrapper">
                                                <Label
                                                    type="text"
                                                    value={
                                                        reservation.guestInfo
                                                            ? reservation.guestInfo.lastName
                                                            : t('reservations.view.fields.walkin')
                                                    }
                                                />
                                                {reservation.guestInfo?.firstName && (
                                                    <>
                                                        <Label
                                                            type="info"
                                                            extraClassName="d-xs-none"
                                                            value={reservation.guestInfo.firstName}
                                                        />
                                                        <Label
                                                            type="text"
                                                            extraClassName="d-xs-block"
                                                            value={reservation.guestInfo.firstName}
                                                        />
                                                    </>
                                                )}
                                            </div>
                                            {Boolean(reservation.auditInfo) && (
                                                <div className="date-created-and-type d-xs-block">
                                                    {Boolean(reservation.auditInfo!.createdAt) && (
                                                        <Label
                                                            type="info"
                                                            value={DateTime.fromISO(
                                                                reservation.auditInfo!.createdAt,
                                                            ).toFormat(DATE_FORMAT_SHORTEN)}
                                                        />
                                                    )}
                                                    <div className="reservation-origin-wrapper">
                                                        <ReservationOriginIcon reservation={reservation} />
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="reservation__details">
                                            <div
                                                className="reservation__info__wrapper"
                                                onClick={handleEditReservation(reservation)}
                                            >
                                                <div className="reservation__guest__company-wrapper">
                                                    {Boolean(reservation.guestInfo?.company) && (
                                                        <>
                                                            <CompanyIcon />
                                                            <Label
                                                                type="text"
                                                                extraClassName="d-xs-none"
                                                                value={reservation.guestInfo?.company}
                                                            />
                                                            <Label
                                                                type="info"
                                                                extraClassName="d-xs-block"
                                                                value={reservation.guestInfo?.company}
                                                            />
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="restaurant-comments">
                                                <Label
                                                    icon={<CommentOutlined />}
                                                    type={'text'}
                                                    value={reservation.guestInfo?.restaurantComment}
                                                />
                                                <Label
                                                    icon={<TextBlockIcon />}
                                                    type={'text'}
                                                    value={reservation.comment}
                                                />
                                            </div>
                                            <div className="reservation__info">
                                                <div className="reservation__participants d-xs-none">
                                                    <PeopleIcon />
                                                    <Label type="text" value={reservation.participants} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="reservation__info__icon d-xs-none">
                                            <ReservationGuestAdditionalInfo reservation={reservation} />
                                        </div>
                                        <div className="reservation__info__icon allergies-only">
                                            <ReservationGuestAdditionalInfo reservation={reservation} allergiesOnly />
                                        </div>
                                        <div className="tables-and-area-codes-container">
                                            <div className="reservation__info__icon d-xs-block">
                                                <ReservationGuestAdditionalInfo reservation={reservation} />
                                            </div>
                                            {canUseTablePlans && (
                                                <div className="reservation__tablenumber">
                                                    <div className="reservation__participants d-xs-block">
                                                        <PeopleIcon />
                                                        <Label type="text" value={reservation.participants} />
                                                    </div>
                                                    {reservation.tableIds && (
                                                        <ReservationTableNumbers
                                                            tableNumbers={getTablesNamesByIds(
                                                                reservation.tableIds,
                                                                props.restaurantConfiguration.virtualAreas,
                                                            )}
                                                        />
                                                    )}
                                                </div>
                                            )}
                                            <ReservationAreaCodes reservation={reservation} />
                                        </div>
                                        <div className={'area-code-and-status-wrapper'}>
                                            <ReservationStatusActionIcon
                                                reservation={reservation}
                                                className="d-sm-none"
                                            />
                                        </div>
                                        <ReservationStatusActionIcon
                                            reservation={reservation}
                                            className="status-action-icon-absolute-extra-small d-xs"
                                        />
                                        {Boolean(reservation.auditInfo) && (
                                            <div className="date-created-and-type d-xs-none">
                                                {Boolean(reservation.auditInfo!.createdAt) && (
                                                    <Label
                                                        type="info"
                                                        value={DateTime.fromISO(
                                                            reservation.auditInfo!.createdAt,
                                                        ).toFormat(DATE_FORMAT_SHORTEN)}
                                                    />
                                                )}
                                                <div className="reservation-origin-wrapper">
                                                    <div className="reservation__status d-xs-none">
                                                        {getIconForStatus(reservation.status, !reservation.guestInfo)}
                                                    </div>
                                                    <ReservationOriginIcon reservation={reservation} />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

function useObserveDivWidth<T extends HTMLDivElement>() {
    const [width, setWidth] = React.useState(0);
    const ref = React.useRef<T>(null);

    React.useEffect(() => {
        const observer = new ResizeObserver((entries) => {
            setWidth(entries[0].contentRect.width);
        });

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            ref.current && observer.unobserve(ref.current);
        };
    }, []);

    return {
        width,
        ref,
    };
}

export default ReservationsShiftGroup;
