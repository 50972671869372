export var DATE_FORMAT = 'dd.MM.yyyy';
export var DATE_FORMAT_SHORTEN = 'dd.MM.yy';
export var DAY_DATE_FORMAT = 'ccc dd.MM.yyyy';
export var MONTH_DATE = 'LLL dd';
export var TIME_FORMAT = 'HH:mm';
export var TIME_DURATION_FORMAT = 'hh:mm:ss';
export var DATE_TIME_FORMAT = "".concat(DATE_FORMAT, " ").concat(TIME_FORMAT);
export var SERVER_DATE_FORMAT = 'yyyy-MM-dd';
export var SERVER_DATE_TIME_FORMAT = "".concat(SERVER_DATE_FORMAT, " ").concat(TIME_FORMAT);
export var DAY_FORMAT = 'ccc';
export var DAY_LONG_FORMAT = 'cccc';
export var MAX_ALLOWED_SHIFTS = 6;
export var AMOUNT_OF_DATES_FOR_STATS = 7;
export var DEFAULT_INFINITE_LIST_LIMIT = 20;
export var RESERVATION_DROP_TYPE = 'RESERVATION';
export var RESERVATION_CHANGE_TABLE_DROP_TYPE = 'RESERVATION_CHANGE_TABLE';
export var CANVAS_TABLE_DROP_TYPE = 'CANVAS_TABLE';
export var AREA_LIST_ITEM_DROP_TYPE = 'AREA_LIST_ITEM_DROP_TYPE';
export var MAINTENANCE_MODE = false;
export var RESERVATION_LIST_FILTERS_COLLAPSE_STATE = 'reservationListFiltersCollapseState';
export var RESERVATIONS_FILTERS_COLLAPSE_STATE = 'reservationsFiltersCollapseState';
