import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLogoutMutation } from '../../../api/queries/auth';
import { Path } from '../../../enums';
import { Category, ICategoryItem } from './Category';

export const Support: React.FC = () => {
    const { t } = useTranslation();

    const logoutMutation = useLogoutMutation();

    const handleLogout = () => {
        logoutMutation.mutate();
    };

    const categoryItems: ICategoryItem[] = [
        {
            title: t('common.menu.categories.support.menu.accounts'),
            url: Path.SUPPORT_ACCOUNTS,
        },
        {
            title: t('common.menu.categories.support.menu.users'),
            url: Path.SUPPORT_USERS,
        },
        {
            title: t('common.menu.categories.support.menu.logout'),
            onClick: handleLogout,
        },
    ];

    return <Category name="support" title={t('common.menu.categories.support.title')} items={categoryItems} />;
};

export default Support;
