import { FabButton, ISupportUser, List } from '@localina/core';
import { AddIcon } from '@localina/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSupportUsers } from '../../../api/queries/supportUsers';
import { Page, SupportUserListItem } from '../../../components';
import { Path } from '../../../enums';
import { PathUtils } from '../../../utils';

const SupportUsers: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const usersQuery = useSupportUsers();

    const handleEditUser = (item: ISupportUser) => {
        navigate(
            PathUtils.generateUrl(Path.SUPPORT_USER, {
                userId: item.id,
            }),
        );
    };

    const handleCreateUser = () => {
        navigate(
            PathUtils.generateUrl(Path.SUPPORT_USER, {
                userId: 'new',
            }),
        );
    };

    return (
        <Page name="support-users" title={t('supportUsers.title')} isLoading={usersQuery.isLoading}>
            <List>
                {usersQuery.isSuccess &&
                    usersQuery.data.map((user, i) => (
                        <SupportUserListItem key={i} user={user} onClick={handleEditUser} />
                    ))}
            </List>
            <FabButton icon={<AddIcon />} onClick={handleCreateUser} />
        </Page>
    );
};

export default SupportUsers;
