import { Spinner } from '@localina/core';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetAuthUser } from '../../api/queries/auth';
import { useGetRestaurants } from '../../api/queries/restaurants';
import { Path } from '../../enums';
import { PathUtils, SupportUtils, useIsPublicPath } from '../../utils';

type IProps = {
    children?: React.ReactNode;
};

export const AuthenticationLayer: React.FC<IProps> = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [restaurantsRequested, setRestaurantsRequested] = React.useState(false);

    const isPublicPath = useIsPublicPath();

    const authUserQuery = useGetAuthUser({ enabled: !isPublicPath });
    const restaurantsQuery = useGetRestaurants({
        enabled: restaurantsRequested,
    });
    const showChildren = isPublicPath || authUserQuery.isSuccess || restaurantsQuery.isSuccess;
    const isLoading = authUserQuery.isInitialLoading || restaurantsQuery.isInitialLoading;

    useEffect(() => {
        SupportUtils.initialize(location.search);
    }, []);

    useEffect(() => {
        if (isPublicPath || location.pathname === Path.SUPPORT_ACCOUNTS) {
            setRestaurantsRequested(false);
        }
    }, [location.pathname, isPublicPath]);

    useEffect(() => {
        if (authUserQuery.isSuccess) {
            if (!authUserQuery.data.user) {
                navigate(Path.LOGIN);
            } else if (SupportUtils.isSupportPanel(authUserQuery.data.user)) {
                if (location.pathname === '/' || isPublicPath) {
                    navigate(Path.SUPPORT_ACCOUNTS);
                }
            } else {
                setRestaurantsRequested(true);
            }
        } else if (authUserQuery.isError && !isPublicPath) {
            navigate(Path.LOGIN);
        }
    }, [authUserQuery.status]);

    useEffect(() => {
        if (restaurantsQuery.isSuccess && restaurantsRequested) {
            if (restaurantsQuery.data.restaurants.length === 0) {
                navigate(Path.LOGIN);
            } else {
                // props.loadAccount()
                if (location.pathname === '/' || isPublicPath) {
                    const restaurantId = restaurantsQuery.data.restaurants[0].id;
                    navigate(PathUtils.generateUrl(Path.RESTAURANT_DASHBOARD, { restaurantId }));
                }
            }
        }
    }, [restaurantsQuery.status]);

    return (
        <>
            {showChildren && props.children}
            {isLoading && <Spinner />}
        </>
    );
};

export default AuthenticationLayer;
