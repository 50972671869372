import { createEmptyRestaurant, Drawer, IRestaurant, Page, StringUtils, useConfirmContext } from '@localina/core';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
    useCreateRestaurantMutation,
    useGetRestaurants,
    useRestaurantById,
    useUpdateRestaurantMutation,
} from '../../api/queries/restaurants';
import { Availability, Configuration, Done, Info, Start } from './Steps';

interface IProps {
    restaurant?: IRestaurant;
    onClose: () => void;
}

const emptyRestaurant = createEmptyRestaurant();

export const RestaurantWizard: React.FC<IProps> = (props) => {
    const { t } = useTranslation();

    const [step, setStep] = React.useState(props.restaurant?.info.name ? 2 : 0);
    const [restaurant, setRestaurant] = React.useState(emptyRestaurant);

    const createRestaurantMutation = useCreateRestaurantMutation();
    const updateRestaurantMutation = useUpdateRestaurantMutation(createRestaurantMutation.data?.id);

    const restaurantsQuery = useGetRestaurants();
    const isInitial =
        restaurantsQuery.data?.restaurants.length === 1 && !restaurantsQuery.data.restaurants[0].info.name;

    const createdRestaurantQuery = useRestaurantById(createRestaurantMutation.data?.id || '', {
        enabled: Boolean(createRestaurantMutation.data?.id),
    });

    const { snackbar } = useConfirmContext();
    const scrollToTop = () => {
        const wizard = document.getElementsByClassName('restaurant-wizard')[0];
        const main = wizard.getElementsByTagName('main')[0];
        main.scrollTop = 0;
    };
    const handleNext = () => {
        setStep((prev) => prev + 1);
        scrollToTop();
    };
    const handleBack = () => {
        setStep(step - 1);
        scrollToTop();
    };
    const handleClose = () => {
        if (!isInitial) {
            props.onClose();
        }
    };

    const handleSaveInfo = () => {
        if (isInitial || createRestaurantMutation.isSuccess || restaurant.id) {
            updateRestaurantMutation.mutate(
                { info: restaurant.info },
                {
                    onSuccess: handleNext,
                    onError: () => {
                        snackbar({
                            msg: t('wizard.error'),
                            severity: 'error',
                        });
                    },
                },
            );
        } else {
            createRestaurantMutation.mutate(
                { restaurant },
                {
                    onSuccess: handleNext,
                    onError: () => {
                        snackbar({
                            msg: t('wizard.error'),
                            severity: 'error',
                        });
                    },
                },
            );
        }
    };

    const getPage = () => {
        switch (step) {
            case 0:
                return (
                    <Start
                        onNext={handleNext}
                        onClose={props.onClose}
                        message={
                            isInitial ? 'wizard.messages.start' : 'wizard.messages.welcomeMessageMultipleRestaurants'
                        }
                    />
                );
            case 1:
                return <Info restaurant={restaurant} onChange={setRestaurant} onNext={handleSaveInfo} />;
            case 2:
                return <Configuration restaurant={restaurant} onBack={handleBack} onNext={handleNext} />;
            case 3:
                return <Availability restaurant={restaurant} onBack={handleBack} onSave={handleNext} />;
            case 4:
                return <Done restaurant={restaurant} onClose={props.onClose} id={restaurant.id || ''} />;
        }
    };

    const getClassName = () => {
        switch (step) {
            case 0:
                return 'start';
            case 1:
                return 'info';
            case 2:
                return 'configuration';
            case 3:
                return 'availability';
            case 4:
                return 'done';
        }
    };

    const isLoading = createRestaurantMutation.isLoading || updateRestaurantMutation.isLoading;

    React.useEffect(() => {
        setRestaurant(props.restaurant ? props.restaurant : emptyRestaurant);
    }, [props.restaurant]);

    useEffect(() => {
        if (createdRestaurantQuery.data) {
            setRestaurant(createdRestaurantQuery.data);
        }
    }, [createdRestaurantQuery.data]);

    return (
        <Drawer
            open
            className={StringUtils.combineStrings(['restaurant-wizard', getClassName()])}
            position="right"
            onClose={handleClose}
        >
            <Page
                isLoading={isLoading}
                name="restaurant-wizard__page"
                step={step ? step : undefined}
                title={step ? restaurant.info.name : undefined}
            >
                {getPage()}
            </Page>
        </Drawer>
    );
};

export default RestaurantWizard;
