import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Label } from '@localina/core';
import { CloseIcon } from '@localina/icons';
import { Divider } from '@mui/material';
import { useGetAuthUser } from '../../../api/queries/auth';
import { RoleUtils } from '../../../utils';

interface IProps {
    onNext: () => void;
    onClose: () => void;
    message: string;
}

export const Start: React.FC<IProps> = (props) => {
    const { t } = useTranslation();
    const authUser = useGetAuthUser();

    const isSupportUser = (): boolean => {
        return (
            authUser.isSuccess &&
            authUser.data.user &&
            authUser.data.isLoggedIn &&
            RoleUtils.hasSupportRole(authUser.data.user)
        );
    };

    return (
        <>
            {isSupportUser() && (
                <>
                    <div className="restaurant-wizard__header">
                        <div className="grow" />
                        <CloseIcon onClick={props.onClose} />
                    </div>
                    <div className="restaurant-wizard__divider">
                        <Divider />
                    </div>
                </>
            )}
            <Label type="title" value={t('wizard.titles.start')} variant="h6" align="center" />
            <Label type="text" value={t(props.message)} align="center" />
            <div className="grow" />
            <div className="buttons">
                <Button label={t('wizard.buttons.start')} onClick={props.onNext} />
            </div>
        </>
    );
};
