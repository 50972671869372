import {
    BrownDollarCircle,
    DollarCircle,
    EmptyCircle,
    RedDollarCircle,
    RedEmptyCircle,
    BrownEmptyCircle,
} from '@localina/icons';
import React from 'react';

export const getRestaurantPlanIcon = (plan: string, classname = ''): React.ReactNode => {
    if (plan === 'standard') return <DollarCircle className={`restaurants-plan-icon ${classname}`} />;
    if (plan === 'free') return <EmptyCircle className={`restaurants-plan-icon ${classname}`} />;
    return;
};

export const getRestaurantTestPlanIcon = (plan: string, restaurantStatus: string, classname = ''): React.ReactNode => {
    if (plan === 'standard')
        switch (restaurantStatus) {
            case 'active':
                return <DollarCircle className={`restaurants-plan-icon ${classname}`} />;
            case 'inactive':
                return <BrownDollarCircle className={`restaurants-plan-icon ${classname}`} />;
            case 'test':
                return <RedDollarCircle className={`restaurants-plan-icon ${classname}`} />;
        }
    if (plan === 'free')
        switch (restaurantStatus) {
            case 'active':
                return <EmptyCircle className={`restaurants-plan-icon ${classname}`} />;
            case 'inactive':
                return <BrownEmptyCircle className={`restaurants-plan-icon ${classname}`} />;
            case 'test':
                return <RedEmptyCircle className={`restaurants-plan-icon ${classname}`} />;
        }
};

export const getRestaurantTestIcon = (restaurantStatus: string, classname = ''): React.ReactNode => {
    switch (restaurantStatus) {
        case 'active':
            return <EmptyCircle className={`restaurants-plan-icon ${classname}`} />;
        case 'inactive':
            return <BrownEmptyCircle className={`restaurants-plan-icon ${classname}`} />;
        case 'test':
            return <RedEmptyCircle className={`restaurants-plan-icon ${classname}`} />;
    }
};

export const createRandomPassword = (passwordLength = 12) => {
    const uppercaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const lowercaseChars = 'abcdefghijklmnopqrstuvwxyz';
    const numberChars = '0123456789';
    const specialChars = '!@#$%^&*()_+~`|}{[]:;?><,./-=';
    const allChars = uppercaseChars + lowercaseChars + numberChars + specialChars;

    function getRandomCharFrom(characters: string) {
        return characters.charAt(Math.floor(Math.random() * characters.length));
    }

    function shuffleString(string: string) {
        const array = string.split('');
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array.join('');
    }

    let password = '';

    // Generate password with at least 1 uppercase, 1 number, and 1 special character
    password += getRandomCharFrom(uppercaseChars);
    password += getRandomCharFrom(numberChars);
    password += getRandomCharFrom(specialChars);

    // Fill remaining password with random characters
    for (let i = 0; i < passwordLength - 3; i++) {
        password += getRandomCharFrom(allChars);
    }

    // Shuffle the password to make it more random
    return shuffleString(password);
};
