import { ILocalizedError, IReservations, MarkReservationAsReadRequest } from '@localina/core';
import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { LocalinaApiContext } from '../../../index';
import { useRestaurantId } from '../../utils/RestaurantUtils';
import { queryKeys } from './query-keys';
import { usePreservedRestaurant } from './restaurants';
import { getOptionsEnabled } from './utils';

const useUnreadReservations = (options?: UseQueryOptions<IReservations, ILocalizedError>) => {
    const optionsEnabled = getOptionsEnabled(options);
    const restaurantQuery = usePreservedRestaurant(false);

    return useQuery({
        queryFn: () => {
            return LocalinaApiContext.serviceApi.getUnreadReservations(restaurantQuery.data?.id || '');
        },
        queryKey: queryKeys.restaurants.single.notifications.unreadReservations(restaurantQuery.data?.id || ''),
        ...options,
        enabled: Boolean(optionsEnabled && restaurantQuery.data?.id),
    });
};

const useUpdateReservationReadStatus = (
    restaurantId?: string,
    options?: UseMutationOptions<string[], ILocalizedError, MarkReservationAsReadRequest>,
) => {
    const restaurantIdFromUrl = useRestaurantId();
    const restaurantIdToUse = restaurantId || restaurantIdFromUrl;
    return useMutation({
        mutationFn: (request) => {
            return LocalinaApiContext.serviceApi.markReservationAsRead(restaurantIdToUse, request);
        },
        ...options,
    });
};

export { useUnreadReservations, useUpdateReservationReadStatus };
