import {
    ButtonGroup,
    DATE_FORMAT,
    DATE_TIME_FORMAT,
    DatesField,
    FormField,
    FormTextField,
    IAreaTime,
    IAvailableAreaTimeRequested,
    IReservation,
    Label,
    ReservationStatus,
    ReservationType,
    ReservationUtils,
    RestaurantUtils,
    SelectField,
    StringUtils,
    Switch,
    TIME_FORMAT,
    TimeField,
    TLanguageKey,
} from '@localina/core';
import { DateIcon, PeopleIcon, TableIcon, TextBlockIcon } from '@localina/icons';
import { ColorLensOutlined } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import { DateTime } from 'luxon';
import React, { Dispatch, SetStateAction, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHaveAccountFeatures } from '../../api/queries/account';
import { useRestaurant } from '../../api/queries/restaurants';
import { IReservationForm } from '../../schemas/interfaces';
import { getIconForStatus } from '../ReservationStatus';
import { UserFeature } from '../UserFeature';
import ReservationFileUpload from './ReservationFileUpload';
import { ReservationSlots } from './ReservationSlots';
import { addMinutesToTime, getLowestDate, hasAvailability } from './utils';

const statuses = [
    ReservationStatus.APPROVED,
    ReservationStatus.FULFILLED,
    ReservationStatus.NO_SHOW,
    ReservationStatus.RESTAURANT_CANCELLED,
    ReservationStatus.GUEST_CANCELLED,
];
const disabledStatuses = [ReservationStatus.GUEST_CANCELLED];

interface IProps {
    isWalkin?: boolean;
    reservation?: IReservation;
    setAreaTimeVariables: Dispatch<SetStateAction<IAvailableAreaTimeRequested[]>>;
    availableAreaTimes: IAreaTime[];
    availableAreaTimesIsLoading?: boolean;
    document?: File;
    setDocument: Dispatch<SetStateAction<File | undefined>>;
}

function ReservationFormFields(props: IProps) {
    const { t, i18n } = useTranslation();
    const { watch, setValue, formState, trigger } = useFormContext<IReservationForm>();
    const { errors } = formState;

    const [participants, dates, time, areaTimes, occupancyTime] = watch([
        'participants',
        'date',
        'time',
        'areaTimes',
        'occupancyTime',
    ]);

    const [canUseTablePlans, canUseReservationColors, canUseSerialReservation] = useHaveAccountFeatures([
        'tablePlans',
        'reservationColor',
        'serialReservation',
    ]);
    const restaurantQuery = useRestaurant();

    const date = getLowestDate(dates);
    const reservationDateTime = DateTime.fromFormat(`${date} ${time}`, DATE_TIME_FORMAT);

    const reservationMinutes = reservationDateTime.valueOf() / 60000;
    const shiftId = areaTimes.length > 0 ? areaTimes[0].shiftId : '';
    const areaIds = areaTimes.map(({ areaId }) => areaId);

    const selectedTimeSlots = useMemo(
        () =>
            areaIds
                .map((aId) =>
                    ReservationUtils.getTimeSlotForReservationTime(
                        props.availableAreaTimes.find((at) => at.shiftId === shiftId && at.areaId === aId)?.timeSlots ||
                            [],
                        time,
                    ),
                )
                .filter((areaTimeSlot) => Boolean(areaTimeSlot)),
        [JSON.stringify(areaIds), shiftId, time, props.availableAreaTimes],
    );

    const shiftsOnDate = useMemo(
        () =>
            RestaurantUtils.getShiftsForDate(
                DateTime.fromFormat(date, DATE_FORMAT),
                restaurantQuery.data?.configuration,
            ),
        [date, restaurantQuery.data?.configuration],
    );

    const relevantShifts = reservationDateTime.isValid
        ? shiftsOnDate.filter((it) => {
              const itFromMinutes = DateTime.fromFormat(`${date} ${it.shift.from}`, DATE_TIME_FORMAT).valueOf() / 60000;
              const itToMinutes = DateTime.fromFormat(`${date} ${it.shift.to}`, DATE_TIME_FORMAT).valueOf() / 60000;

              return itFromMinutes - reservationMinutes < 60 && reservationMinutes - itToMinutes < 60;
          })
        : [];

    const minimumSlotIntervalOnDate = shiftsOnDate.length
        ? shiftsOnDate.reduce(
              (min, { shift }) => (min > shift.slotInterval ? shift.slotInterval : min),
              shiftsOnDate[0].shift.slotInterval,
          )
        : 15 * 60;

    const { timePickerMinTime, timePickerMaxTime } = useMemo(
        () =>
            shiftsOnDate.length
                ? shiftsOnDate.reduce(
                      (acc, { shift }) => ({
                          timePickerMinTime:
                              acc.timePickerMinTime > DateTime.fromFormat(shift.from, TIME_FORMAT)
                                  ? DateTime.fromFormat(shift.from, TIME_FORMAT)
                                  : acc.timePickerMinTime,
                          timePickerMaxTime:
                              acc.timePickerMaxTime < DateTime.fromFormat(shift.to, TIME_FORMAT)
                                  ? DateTime.fromFormat(shift.to, TIME_FORMAT)
                                  : acc.timePickerMaxTime,
                      }),
                      {
                          timePickerMinTime: DateTime.fromFormat(shiftsOnDate[0].shift.from, TIME_FORMAT),
                          timePickerMaxTime: DateTime.fromFormat(shiftsOnDate[0].shift.to, TIME_FORMAT),
                      },
                  )
                : {
                      timePickerMinTime: undefined,
                      timePickerMaxTime: undefined,
                  },
        [shiftsOnDate],
    );

    const occupancyTimeOptions =
        (
            selectedTimeSlots[0] &&
            RestaurantUtils.generateSlotsForShift(
                restaurantQuery.data?.configuration,
                shiftId,
                selectedTimeSlots[0].timeSlot || '',
            )
        )?.map((slot) => ({
            value: Number(slot),
            label: slot,
            className: hasAvailability(
                selectedTimeSlots[0]!,
                props.availableAreaTimes,
                Number(slot),
                Number(participants) ?? 0,
                relevantShifts.find((shift) => shift.id === shiftId)?.shift,
            )
                ? ''
                : 'overbooked',
        })) || [];

    const hideSendConfirmationMailSwitch = true;

    const colorOptions = [
        { color: '', label: t('reservations.view.fields.color.options.noColor') },
        { color: '#821E3E', label: t('reservations.view.fields.color.options.bordeaux') },
        { color: '#43b5c9', label: t('reservations.view.fields.color.options.petrol') },
        { color: '#003A5C', label: t('reservations.view.fields.color.options.navy') },
        { color: '#686767', label: t('reservations.view.fields.color.options.darkGrey') },
        { color: '#EAEAEA', label: t('reservations.view.fields.color.options.lightGrey') },
    ];

    React.useEffect(() => {
        const nParticipants = Number(participants);
        if (!restaurantQuery.data || !date || !time || isNaN(nParticipants) || nParticipants < 1) {
            props.setAreaTimeVariables([]);
            return;
        }
        const val = relevantShifts.flatMap((shift) => {
            return shift.areas
                .filter((area) => {
                    return area.type === 'regular' || props.reservation?.areaIds.includes(area.id);
                })
                .map((area) => {
                    return {
                        areaId: area.id,
                        shiftId: shift.id,
                        expectedOccupancyTime: shift.shift.slotInterval,
                        participants: nParticipants,
                        reservationDateTime: reservationDateTime.toISO() || '',
                        dates: dates,
                    };
                });
        });
        props.setAreaTimeVariables(val);
    }, [dates, time, participants]);

    useEffect(() => {
        if (restaurantQuery.isSuccess && restaurantQuery.data) {
            if (!shiftId || !selectedTimeSlots.length) {
                if (!props.reservation?.id) {
                    setValue('occupancyTime', 0);
                    void trigger('occupancyTime');
                }
                return;
            }
            const shift = restaurantQuery.data.configuration.shifts.find((s) => s.id === shiftId);
            if (!shift) {
                setValue('occupancyTime', 0);
                void trigger('occupancyTime');
                return;
            }
            if (!occupancyTime) {
                const maxSlot = occupancyTimeOptions.reduce((acc, slot) => {
                    const nSlot = Number(slot.value);
                    if (acc > nSlot) {
                        return acc;
                    }
                    return nSlot;
                }, 0);
                setValue('occupancyTime', Math.min(maxSlot, shift.expectedOccupancyTime / 60));
                void trigger('occupancyTime');
            }
        }
    }, [shiftId, occupancyTime, selectedTimeSlots, props.reservation]);

    useEffect(() => {
        if (occupancyTime && occupancyTimeOptions.length && time) {
            const occupancyTimeInOptions = occupancyTimeOptions.find(
                (option) => Number(option.value) === occupancyTime,
            );

            if (!occupancyTimeInOptions) {
                setValue('occupancyTime', Number(occupancyTimeOptions[occupancyTimeOptions.length - 1].value));
                void trigger('occupancyTime');
            }
        }
    }, [occupancyTimeOptions]);

    useEffect(() => {
        if (
            shiftId &&
            props.availableAreaTimes.length > 0 &&
            props.availableAreaTimes.every((at) => at.shiftId !== shiftId)
        ) {
            setValue('areaTimes', []);
        }
    }, [props.availableAreaTimes, shiftId]);

    useEffect(() => {
        if (areaIds.length && shiftId && props.availableAreaTimes.length > 0) {
            const availableShiftAreas = props.availableAreaTimes
                .filter((aat) => aat.shiftId === shiftId)
                .map((aat) => aat.areaId);
            const newAreaIds = areaIds.filter((aId) => availableShiftAreas.includes(aId));
            setValue(
                'areaTimes',
                newAreaIds.map((aId) => ({ shiftId, areaId: aId })),
                { shouldValidate: true },
            );
        }
    }, [props.availableAreaTimes, JSON.stringify(areaIds), shiftId]);

    const tableOptions = (() => {
        if (!time) {
            return [];
        }

        if (!restaurantQuery.data || !selectedTimeSlots.length) {
            return [];
        }

        const tablePlanIds = restaurantQuery.data.configuration.virtualAreas
            ?.flatMap((va) => va.areas)
            .filter((s) => areaIds.includes(s.id))
            .flatMap((area) => area.areaShifts)
            .filter((as) => as.shiftId === shiftId)
            .map((as) => as.tablePlanId);

        const tables = restaurantQuery.data.configuration.virtualAreas
            ?.flatMap((va) => va.areas)
            .flatMap((a) => a.tablePlans)
            .filter((tp) => tablePlanIds.includes(tp.id))
            .flatMap((tp) =>
                tp.tables.map((table) => {
                    return {
                        ...table,
                        tablePlanName: tp.name,
                    };
                }),
            );

        const shift = restaurantQuery.data.configuration.shifts.find((s) => s.id === shiftId);

        return tables
            .map((table) => {
                const tableArea = RestaurantUtils.getTableArea(restaurantQuery.data?.configuration, table.id);
                const selectedTimeSlot = selectedTimeSlots.find((ts) => ts?.areaId === tableArea?.id);

                const { occupiedTables } =
                    props.availableAreaTimes.find((at) => at.shiftId === shiftId && at.areaId === tableArea?.id) || {};

                let isSeverityError =
                    selectedTimeSlot &&
                    selectedTimeSlot.tableOccupancy &&
                    occupancyTime !== undefined &&
                    selectedTimeSlot.tableOccupancy[table.id] !== undefined &&
                    selectedTimeSlot.tableOccupancy[table.id] / 60 < Number(occupancyTime);

                let tableLabel = `${table.tablePlanName} - ${table.name}`;

                if (isSeverityError && shift) {
                    const busySlots = occupiedTables?.[table.id] || [];
                    let firstAvailableTime = null;

                    for (let i = 0; i < busySlots.length; i++) {
                        if (selectedTimeSlot!.timeSlot < busySlots[i].to) {
                            //skipped busy slots in the past
                            if (busySlots[i].to === shift.to) {
                                // occupancy ends in the time when shift ends
                                firstAvailableTime = '';
                                break;
                            } else {
                                const reservationTo = addMinutesToTime(busySlots[i].to, Number(occupancyTime));
                                if (i === busySlots.length - 1) {
                                    // this is the last occupancy in the shift
                                    firstAvailableTime = reservationTo > shift.to ? '' : busySlots[i].to;
                                } else if (reservationTo <= busySlots[i + 1].from) {
                                    // time to the next occupied slot is corresponding one
                                    firstAvailableTime = busySlots[i].to;
                                    break;
                                }
                            }
                        }
                    }

                    if (firstAvailableTime === null) {
                        isSeverityError = false;
                    } else {
                        tableLabel += firstAvailableTime
                            ? t('reservations.view.fields.tablesAvailability.tableNotAvailableUntil', {
                                  time: firstAvailableTime,
                              })
                            : t('reservations.view.fields.tablesAvailability.tableNotAvailableInThisShift');
                    }
                }
                return {
                    value: table.id,
                    label: tableLabel,
                    severity: isSeverityError ? 'error' : ('info' as 'error' | 'info'),
                };
            })
            .sort((a, b) => {
                return a.label.localeCompare(b.label, 'en', { numeric: true });
            });
    })();

    const [timeFieldOpen, setTimeFieldOpen] = useState(false);

    useLayoutEffect(() => {
        let timeoutVariable: number;
        if (timeFieldOpen) {
            if (!time && date === DateTime.now().toFormat(DATE_FORMAT)) {
                timeoutVariable = window.setTimeout(() => {
                    const scrollableElement = document.querySelector(
                        '.localina-timefield__timepicker__popper ul.MuiMultiSectionDigitalClockSection-root',
                    );
                    const nowHours = DateTime.now().toFormat('H');
                    const matchingHoursElement = document.querySelector(
                        `.localina-timefield__timepicker__popper li.MuiMultiSectionDigitalClockSection-item[aria-label="${nowHours} hours"]`,
                    );
                    if (scrollableElement && matchingHoursElement && matchingHoursElement instanceof HTMLElement) {
                        const elementRect = matchingHoursElement.getBoundingClientRect();
                        const popupRect = scrollableElement.getBoundingClientRect();
                        if (elementRect.top < popupRect.top || elementRect.bottom > popupRect.bottom) {
                            scrollableElement.scrollTop = matchingHoursElement.offsetTop;
                        }
                    }
                }, 100);
            }
        }
        return () => {
            clearTimeout(timeoutVariable);
        };
    }, [timeFieldOpen]);

    return (
        <>
            <FormTextField
                name="participants"
                label={t('reservations.view.fields.participants')}
                type="number"
                required
                icon={<PeopleIcon />}
            />
            <div className="row-direction date-time-row">
                <FormField
                    disabled={props.isWalkin}
                    accepter={DatesField}
                    label={t('reservations.view.fields.date')}
                    name="date"
                    icon={<DateIcon />}
                    required
                    singleDate={!canUseSerialReservation || Boolean(props.reservation?.id)}
                    shouldHighlightDate={(calendarDate: DateTime) =>
                        RestaurantUtils.isClosedAndHasNoShiftsOnDate(calendarDate, restaurantQuery.data?.configuration)
                            ? 'restaurant-closed-day'
                            : false
                    }
                />
                <div className="date-time-spacer" />
                <FormField
                    accepter={TimeField}
                    disabled={props.isWalkin}
                    label={t('reservations.view.fields.time')}
                    name="time"
                    injectErrorMessage
                    required
                    TimePickerProps={{
                        timeSteps: {
                            minutes: minimumSlotIntervalOnDate / 60,
                        },
                        skipDisabled: true,
                        maxTime: timePickerMaxTime,
                        minTime: timePickerMinTime,
                        onOpen: () => {
                            setTimeFieldOpen(true);
                        },
                        onClose: () => {
                            setTimeFieldOpen(false);
                        },
                        open: timeFieldOpen,
                    }}
                />
            </div>
            <Label type="title" value={t('reservations.view.fields.reservationsTime')} required />
            <div className={StringUtils.combineStrings(['selection-focus-rectangle', errors['areaTimes'] && 'error'])}>
                {!participants || !dates.length || !time || errors.participants || errors.date || errors.time ? (
                    <Label
                        type={'info'}
                        value={t('reservations.view.fields.error.missing.fields.reservationMissingFieldsError')}
                        align={'center'}
                    />
                ) : (
                    <>
                        {props.availableAreaTimesIsLoading ? (
                            <Label
                                type={'info'}
                                value={t('reservations.view.fields.info.slotsAreLoading')}
                                align={'center'}
                                icon={<CircularProgress />}
                            />
                        ) : (
                            <>
                                {!relevantShifts.some(
                                    (shift) =>
                                        (!shiftId || shift.id === shiftId) &&
                                        props.availableAreaTimes.some((at) => at.shiftId === shift.id),
                                ) ? (
                                    <Label
                                        type={'info'}
                                        value={t(
                                            'reservations.view.fields.error.no.available.shifts.reservationNoAvailableShiftsError',
                                        )}
                                        align={'center'}
                                    />
                                ) : (
                                    <FormField
                                        accepter={ReservationSlots}
                                        name="areaTimes"
                                        shiftId={shiftId}
                                        participants={Number(participants)}
                                        reservationTime={time}
                                        availableAreaTimes={props.availableAreaTimes}
                                        shifts={relevantShifts}
                                        language={i18n.language as TLanguageKey}
                                        areasOrderings={restaurantQuery.data?.configuration.orderings.areas || []}
                                        occupancyTime={occupancyTime}
                                    />
                                )}
                            </>
                        )}
                    </>
                )}
            </div>
            {Boolean(areaTimes.length && selectedTimeSlots.length && props.availableAreaTimes.length) && (
                <>
                    <Label type="title" value={t('reservations.view.fields.occupancyTime')} />
                    <FormField accepter={ButtonGroup} name="occupancyTime" fullWidth options={occupancyTimeOptions} />
                </>
            )}
            {props.reservation?.id && !props.isWalkin && (
                <FormField
                    name="status"
                    accepter={ButtonGroup}
                    options={statuses.map((st) => {
                        return {
                            value: st,
                            disabled: disabledStatuses.includes(st),
                            label: (
                                <div className="select-reservation-status__option">
                                    {getIconForStatus(st)}
                                    <Label type="info" value={t(`reservations.status.${st}`)} />
                                </div>
                            ),
                        };
                    })}
                    className="select-reservation-status"
                    fullWidth
                />
            )}

            {Boolean(areaTimes.length && selectedTimeSlots.length && canUseTablePlans) && (
                <FormField
                    name="tableIds"
                    type="array"
                    accepter={SelectField}
                    label={t('reservations.view.fields.tables')}
                    options={tableOptions}
                    icon={<TableIcon />}
                    multiple
                />
            )}
            {canUseReservationColors && (
                <FormField
                    name="color"
                    type="array"
                    accepter={SelectField}
                    label={t('reservations.view.fields.color.title')}
                    options={colorOptions.map(({ color, label }) => ({
                        value: color,
                        label: <ColorOptionLabel color={color} text={label} />,
                    }))}
                    icon={<ColorLensOutlined />}
                />
            )}
            {!props.isWalkin && (
                <FormTextField
                    name="comment"
                    label={t('reservations.view.fields.comment')}
                    multiline
                    icon={<TextBlockIcon />}
                />
            )}
            {!props.isWalkin && (
                <UserFeature filter={'reservationDocument'}>
                    <ReservationFileUpload />
                </UserFeature>
            )}
            {!hideSendConfirmationMailSwitch && (
                <FormField
                    accepter={Switch}
                    className="reservation-view-switch"
                    label={t('guest.details.view.fields.sendEmailConfirmation')}
                    name="sendConfirmationMail"
                    type="boolean"
                    injectErrorMessage
                />
            )}
            {props.reservation &&
                hideSendConfirmationMailSwitch &&
                (Boolean(props.reservation.confirmationMailDate) ||
                    (props.reservation.auditInfo &&
                        props.reservation.reservationType === ReservationType.WIDGET &&
                        props.reservation.auditInfo.createdAt)) && (
                    <Label
                        type={'text'}
                        value={t('guest.details.view.fields.confirmationEmailSent', {
                            date: DateTime.fromISO(
                                props.reservation.confirmationMailDate || props.reservation.auditInfo?.createdAt || '',
                            ).toFormat(DATE_FORMAT),
                            time: DateTime.fromISO(
                                props.reservation.confirmationMailDate || props.reservation.auditInfo?.createdAt || '',
                            ).toFormat(TIME_FORMAT),
                        })}
                    />
                )}
        </>
    );
}

export default ReservationFormFields;

interface IColorOptionLabelProps {
    text: string;
    color: string;
}

const ColorOptionLabel = (props: IColorOptionLabelProps) => {
    return (
        <div className={'reservation-details-color-option-label'}>
            <div className={'color-placeholder'} style={{ backgroundColor: props.color }} />
            <Label type={'text'} value={props.text} />
        </div>
    );
};
