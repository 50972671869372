import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, IRestaurant, Label } from '@localina/core';
import WidgetIntegration from '../../IntegrationViews/Integrations';

interface IProps {
    restaurant: IRestaurant;
    id: string;
    onClose: () => void;
}

export const Done: React.FC<IProps> = (props) => {
    const { t } = useTranslation();

    return (
        <>
            <Label type="title" value={t('wizard.titles.done')} variant="h5" />
            <Label type="text" value={t('wizard.messages.done')} />
            <WidgetIntegration restaurant={props.restaurant} id={props.id} />
            <div className="grow" />
            <div className="buttons">
                <Button label={t('wizard.buttons.close')} onClick={props.onClose} />
            </div>
        </>
    );
};
