import React, { useEffect, useMemo } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGetRestaurants } from '../../api/queries/restaurants';

const RestaurantRoutes = () => {
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const restaurantId = params.restaurantId || '';
    const restaurantsQuery = useGetRestaurants();

    const currentRestaurant = useMemo(
        () => restaurantsQuery.data?.restaurants.find((restaurant) => restaurant.id === restaurantId),
        [restaurantsQuery.data, restaurantId],
    );

    const showChildren = Boolean(currentRestaurant);

    useEffect(() => {
        if (restaurantsQuery.data && !currentRestaurant) {
            const pathname = location.pathname.replace(restaurantId, restaurantsQuery.data.restaurants[0].id);
            navigate({ pathname }, { replace: true });
        }
    }, [currentRestaurant, restaurantsQuery.data]);

    return <>{showChildren && <Outlet />}</>;
};

export default RestaurantRoutes;
