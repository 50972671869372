import { ILocalizedError, IStaffTag, TCreateStaffTag } from '@localina/core';
import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { LocalinaApiContext } from '../../../index';
import { useRestaurantId } from '../../utils/RestaurantUtils';
import { queryKeys } from './query-keys';
import { useRestaurant } from './restaurants';

function useStaffTags() {
    return useRestaurant({
        select: (data) => ({
            staffTags: data?.configuration.staffTags || [],
            staffTagMandatory: data?.configuration.staffTagMandatory,
            staffTagEnabled: data?.configuration.staffTagEnabled,
        }),
    });
}

const useCreateStaffTagMutation = (options?: UseMutationOptions<string, ILocalizedError, TCreateStaffTag>) => {
    const queryClient = useQueryClient();
    const restaurantId = useRestaurantId();

    return useMutation({
        mutationFn: createStaffTagMutationFn(restaurantId),
        onSuccess: () => queryClient.invalidateQueries(queryKeys.restaurants.all),
        ...options,
    });
};
const useUpdateStaffTagMutation = (options?: UseMutationOptions<string, ILocalizedError, IStaffTag>) => {
    const queryClient = useQueryClient();
    const restaurantId = useRestaurantId();

    return useMutation({
        mutationFn: updateStaffTagMutationFn(restaurantId),
        onSuccess: () => queryClient.invalidateQueries(queryKeys.restaurants.all),
        ...options,
    });
};
const useDeleteStaffTagMutation = (options?: UseMutationOptions<string, ILocalizedError, string>) => {
    const queryClient = useQueryClient();
    const restaurantId = useRestaurantId();

    return useMutation({
        mutationFn: deleteStaffTagMutationFn(restaurantId),
        onSuccess: () => queryClient.invalidateQueries(queryKeys.restaurants.all),
        ...options,
    });
};

const createStaffTagMutationFn = (restaurantId: string) => (staffTag: TCreateStaffTag) =>
    LocalinaApiContext.serviceApi.createStaffTag(restaurantId, staffTag);
const updateStaffTagMutationFn = (restaurantId: string) => (staffTag: IStaffTag) =>
    LocalinaApiContext.serviceApi.updateStaffTag(restaurantId, staffTag);
const deleteStaffTagMutationFn = (restaurantId: string) => (staffTagId: string) =>
    LocalinaApiContext.serviceApi.deleteStaffTag(restaurantId, staffTagId);

export { useStaffTags, useCreateStaffTagMutation, useUpdateStaffTagMutation, useDeleteStaffTagMutation };
