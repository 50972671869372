import { UserRole } from '@localina/core';
import { IAuthUser } from '../interfaces';

const hasAdminRole = (user: IAuthUser) => user.roles.includes(UserRole.ADMIN);
const hasSupportRole = (user: IAuthUser) => user.roles.includes(UserRole.SUPPORT);

const canViewRestaurants = (user: IAuthUser) => hasAdminRole(user) || hasSupportRole(user);
const canViewUsers = (user: IAuthUser) => hasAdminRole(user) || hasSupportRole(user);

export const RoleUtils = {
    canViewRestaurants,
    canViewUsers,
    hasSupportRole,
};
