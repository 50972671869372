import { Label } from '@localina/core';
import { CloseIcon } from '@localina/icons';
import { Box, Modal, Paper } from '@mui/material';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';

interface IProps {
    welcomeMessageNumber: number;
}

const welcomeModalLocalStorageName = (messageNumber: number) => `isWelcomed${messageNumber}`;
export const WelcomeModal: React.FC<IProps> = ({ welcomeMessageNumber }) => {
    const { t } = useTranslation();
    const [isWelcomeVisible, setWelcomeVisible] = React.useState(false);

    const handleWelcomeModalClose = () => {
        setWelcomeVisible(false);
        localStorage.setItem(welcomeModalLocalStorageName(welcomeMessageNumber), 'true');
    };

    React.useEffect(() => {
        // // use this if you want to display welcome modal only on specific date/dates
        // const now = DateTime.now();
        // // check if it is 26.09.2023.
        // if (now.get('year') == 2023 && now.get('month') == 9 && now.get('day') == 26) {
        //     setWelcomeVisible(!localStorage.getItem(welcomeModalLocalStorageName(welcomeMessageNumber)));
        // }

        setWelcomeVisible(!localStorage.getItem(welcomeModalLocalStorageName(welcomeMessageNumber)));

        // clear all previous welcome modal variables from localstorage
        for (let i = 0; i < welcomeMessageNumber; i++) {
            if (localStorage.getItem(welcomeModalLocalStorageName(i))) {
                localStorage.removeItem(welcomeModalLocalStorageName(i));
            }
        }
    }, []);

    return (
        <Modal open={isWelcomeVisible} className="welcome-modal" onClose={handleWelcomeModalClose}>
            <Paper className="welcome-modal__container">
                <div
                    className="welcome-modal__background"
                    style={{ backgroundImage: `url("${process.env.PUBLIC_URL}/images/logo.png")` }}
                >
                    <CloseIcon className="welcome-modal__close-icon" onClick={handleWelcomeModalClose} />
                    <Box>
                        <div className="welcome-modal__title">
                            <Label type="title" variant="h6" value={t('welcomeModal.title')} />
                        </div>

                        <div className="welcome-modal__text-block">
                            <Label type="text" variant="body1" value={t('welcomeModal.paragraph-1.text')} />
                        </div>

                        <div className="welcome-modal__text-block">
                            <Label
                                type="text"
                                extraClassName={'bold'}
                                variant="body1"
                                value={t('welcomeModal.paragraph-2.text')}
                            />

                            <ul className="welcome-modal__list">
                                {[...Array(3)].map((e, i) => (
                                    <li key={`list-item-${i}`}>
                                        <Label
                                            type="text"
                                            variant="body1"
                                            value={
                                                <Trans
                                                    i18nKey={`welcomeModal.paragraph-2.list.line-${i + 1}`}
                                                    components={{
                                                        highlight: <span className={'welcome-modal__highlight'} />,
                                                    }}
                                                />
                                            }
                                        />
                                    </li>
                                ))}
                            </ul>
                        </div>

                        {/*<div className="welcome-modal__text-block">*/}
                        {/*    <Label*/}
                        {/*        type="text"*/}
                        {/*        extraClassName={'bold'}*/}
                        {/*        variant="body1"*/}
                        {/*        value={t('welcomeModal.paragraph-3.text')}*/}
                        {/*    />*/}

                        {/*    <ul className="welcome-modal__list">*/}
                        {/*        {[...Array(1)].map((e, i) => (*/}
                        {/*            <li key={`list-item-${i}`}>*/}
                        {/*                <Label*/}
                        {/*                    type="text"*/}
                        {/*                    variant="body1"*/}
                        {/*                    value={*/}
                        {/*                        <Trans*/}
                        {/*                            i18nKey={`welcomeModal.paragraph-3.list.line-${i + 1}`}*/}
                        {/*                            components={{*/}
                        {/*                                highlight: <span className={'welcome-modal__highlight'} />,*/}
                        {/*                            }}*/}
                        {/*                        />*/}
                        {/*                    }*/}
                        {/*                />*/}
                        {/*            </li>*/}
                        {/*        ))}*/}
                        {/*    </ul>*/}
                        {/*</div>*/}

                        {/*<div className="welcome-modal__text-block">*/}
                        {/*    <Label*/}
                        {/*        type="text"*/}
                        {/*        extraClassName={'bold'}*/}
                        {/*        variant="body1"*/}
                        {/*        value={t('welcomeModal.paragraph-4.text')}*/}
                        {/*    />*/}

                        {/*    <ul className="welcome-modal__list">*/}
                        {/*        {[...Array(3)].map((e, i) => (*/}
                        {/*            <li key={`list-item-${i}`}>*/}
                        {/*                <Label*/}
                        {/*                    type="text"*/}
                        {/*                    variant="body1"*/}
                        {/*                    value={*/}
                        {/*                        <Trans*/}
                        {/*                            i18nKey={`welcomeModal.paragraph-4.list.line-${i + 1}`}*/}
                        {/*                            components={{*/}
                        {/*                                highlight: <span className={'welcome-modal__highlight'} />,*/}
                        {/*                            }}*/}
                        {/*                        />*/}
                        {/*                    }*/}
                        {/*                />*/}
                        {/*            </li>*/}
                        {/*        ))}*/}
                        {/*    </ul>*/}
                        {/*</div>*/}

                        <div className="welcome-modal__text-block">
                            <Label
                                type="text"
                                variant="body1"
                                value={
                                    <Trans i18nKey={'welcomeModal.paragraph-5.text'} components={{ anchor: <a /> }} />
                                }
                            />
                        </div>
                        <div className="welcome-modal__signature-text">
                            <Label type="text" variant="body1" value={t(`welcomeModal.signature`)} align="right" />
                        </div>
                    </Box>
                </div>
            </Paper>
        </Modal>
    );
};
