var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ChevronLeftIcon, ChevronRightIcon } from '@localina/icons';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DATE_FORMAT, DAY_DATE_FORMAT } from '../../config';
import { InputFieldUtils, StringUtils } from '../../utils';
import { Button } from '../Button';
import { InputField } from '../InputField';
export var DateField = function (props) {
    var _a;
    var _b = useTranslation('core'), i18n = _b.i18n, t = _b.t;
    var ref = React.createRef();
    var _c = React.useState(null), date = _c[0], setDate = _c[1];
    var _d = React.useState((_a = props.isOpen) !== null && _a !== void 0 ? _a : false), isOpen = _d[0], setOpen = _d[1];
    var todayDate = DateTime.local().toFormat(DATE_FORMAT);
    React.useEffect(function () {
        var _a;
        if (props.value) {
            var value = DateTime.fromFormat((_a = props.value) !== null && _a !== void 0 ? _a : '', DATE_FORMAT);
            if (value.isValid) {
                setDate(value);
            }
        }
        else {
            setDate(null);
        }
    }, [props.value]);
    React.useEffect(function () {
        if (props.isOpen !== undefined) {
            setOpen(props.isOpen);
        }
    }, [props.isOpen]);
    var handleOpen = function () {
        setOpen(true);
    };
    var handleDisableDate = function (value) {
        if (!value || !props.shouldDisableDate) {
            return false;
        }
        return props.shouldDisableDate(value);
    };
    var handleChange = function (val) {
        var value = val ? val : null;
        if (value) {
            setDate(value);
            handleClose();
            if (props.onChange) {
                props.onChange((value === null || value === void 0 ? void 0 : value.isValid) ? value.toFormat(DATE_FORMAT) : '');
            }
        }
    };
    var handleClose = function () {
        setOpen(false);
        if (props.onClose) {
            props.onClose();
        }
    };
    var handleChevronDateChange = function (direction) { return function () {
        if (props.value && props.onChange) {
            var newValue = DateTime.fromFormat(props.value, DATE_FORMAT)[direction]({ days: 1 })
                .toFormat(DATE_FORMAT);
            props.onChange(newValue);
        }
    }; };
    var onTodayClick = function () {
        if (props.onChange) {
            props.onChange(todayDate);
        }
    };
    var getPickersDayClassNames = function (_a) {
        var day = _a.day;
        var disableDate = props.shouldDisableDate && props.shouldDisableDate(day);
        var highlightDate = props.shouldHighlightDate && props.shouldHighlightDate(day);
        var shouldSelectDate = !date && props.focusDate && props.focusDate.equals(day);
        return StringUtils.combineStrings([
            !disableDate && highlightDate ? (typeof highlightDate === 'string' ? highlightDate : 'highlight') : '',
            !disableDate && shouldSelectDate && 'Mui-selected',
        ]);
    };
    return (_jsxs(React.Fragment, { children: [props.withChevrons && _jsx(ChevronLeftIcon, { onClick: handleChevronDateChange('minus') }), _jsx(InputField, __assign({ name: "localina-datefield", inputRef: ref, icon: props.icon, helperText: props.helperText, onEnter: props.onEnter }, { children: _jsx(LocalizationProvider, __assign({ dateAdapter: AdapterLuxon, adapterLocale: i18n.language }, { children: _jsx(MobileDatePicker, { inputRef: ref, open: isOpen, format: DAY_DATE_FORMAT, label: props.label, value: date, minDate: props.minDate, maxDate: props.maxDate, disabled: props.disabled, shouldDisableDate: handleDisableDate, onAccept: handleChange, slotProps: {
                            textField: {
                                inputProps: {
                                    placeholder: '',
                                },
                                variant: 'outlined',
                                autoComplete: InputFieldUtils.getAutoCompleteForField(props.name, props.autoComplete),
                                required: props.required,
                                fullWidth: true,
                                placeholder: '',
                            },
                            day: function (ownerState) { return ({
                                className: getPickersDayClassNames(ownerState),
                                onDaySelect: handleChange,
                            }); },
                            toolbar: {
                                hidden: false,
                            },
                            dialog: { className: 'date-picker-dialog' },
                        }, onOpen: handleOpen, onClose: handleClose }) })) })), props.withChevrons && _jsx(ChevronRightIcon, { onClick: handleChevronDateChange('plus') }), props.withTodayButton && (_jsx(Button, { label: t('dateField.buttons.today'), onClick: onTodayClick, disabled: todayDate === props.value }))] }));
};
export default DateField;
