import { Checkbox, IReservation, ReservationStatus, SERVER_DATE_FORMAT, Spinner, StringUtils } from '@localina/core';
import { CancelIcon, DisabledIcon, DoneIcon, QuestionIcon, RingBellIcon, UnpublishedIcon } from '@localina/icons';
import { DateTime } from 'luxon';
import React from 'react';
import { useUpdateReservationStatus } from '../../api/queries/reservations';

export const getIconForStatus = (status: ReservationStatus, isWalkin = false) => {
    if (isWalkin) {
        return <DoneIcon />;
    }
    switch (status) {
        case ReservationStatus.APPROVAL_PENDING:
            return <QuestionIcon />;
        case ReservationStatus.APPROVED:
            return <RingBellIcon />;
        case ReservationStatus.FULFILLED:
            return <DoneIcon />;
        case ReservationStatus.NO_SHOW:
            return <UnpublishedIcon />;
        case ReservationStatus.GUEST_CANCELLED:
            return <CancelIcon />;
        case ReservationStatus.RESTAURANT_CANCELLED:
            return <DisabledIcon />;
        default:
            return <></>;
    }
};

interface IReservationStatusProps {
    reservation: IReservation;
    className?: string;
}

const ReservationStatusActionIcon = ({ reservation, className }: IReservationStatusProps) => {
    const updateReservationStatusMutation = useUpdateReservationStatus();

    const setReservationStatusFulfilled = () => {
        updateReservationStatusMutation.mutate({
            reservationDate: DateTime.fromISO(reservation.reservationDateTime).toFormat(SERVER_DATE_FORMAT),
            status: ReservationStatus.FULFILLED,
            reservationId: reservation.id,
        });
    };

    return (
        <>
            <div
                className={StringUtils.combineStrings(['reservation__status', className])}
                onClick={(e) => {
                    if (reservation.status === ReservationStatus.APPROVED) {
                        e.stopPropagation();
                    }
                }}
            >
                {reservation.status === ReservationStatus.APPROVED ? (
                    <Checkbox
                        uncheckedIcon={<RingBellIcon size="big" />}
                        checkedIcon={<DoneIcon size="big" />}
                        onChange={setReservationStatusFulfilled}
                    />
                ) : (
                    getIconForStatus(reservation.status, !reservation.guestInfo)
                )}
            </div>
            {updateReservationStatusMutation.isLoading && <Spinner />}
        </>
    );
};

export default ReservationStatusActionIcon;
