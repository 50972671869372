import { IAccountList, ILocalizedError } from '@localina/core';
import { useInfiniteQuery, useMutation, UseMutationOptions, useQuery } from '@tanstack/react-query';
import { UseInfiniteQueryOptions } from '@tanstack/react-query/src/types';
import { GenericAbortSignal } from 'axios';
import { useState } from 'react';
import { LocalinaApiContext } from '../../../index';
import { IUpdateRestaurantSubscriptionRequest } from '../../containers/Support/Accounts/SupportAccounts';
import { ICreateAuthUser } from '../../interfaces';
import { queryKeys } from './query-keys';

interface ISupportAccountsFilters {
    query?: string;
}

function useSupportAccounts(
    filter: ISupportAccountsFilters = { query: '' },
    options?: UseInfiniteQueryOptions<IAccountList, ILocalizedError, IAccountList, IAccountList, TQueryKey>,
) {
    return useInfiniteQuery({
        queryFn: ({
            pageParam = { page: 0, limit: 20 },
            signal,
        }: {
            pageParam?: TPageParam;
            signal?: GenericAbortSignal;
        }) => {
            return LocalinaApiContext.supportApi.getAccountList(pageParam.page, pageParam.limit, filter.query, signal);
        },
        queryKey: queryKeys.support.accounts(filter.query),
        staleTime: Infinity,
        ...options,
        select: (data) => ({
            ...data,
            pages: data.pages.map((page) => ({
                ...page,
                items: page.items.map((item) => ({
                    ...item,
                    restaurants: item.restaurants.sort((r1, r2) =>
                        r1.name.toLowerCase().localeCompare(r2.name.toLowerCase()),
                    ),
                })),
            })),
        }),
        getNextPageParam: (lastPage) => {
            return lastPage.pages !== 0 && lastPage.currentPage < lastPage.pages - 1
                ? { page: lastPage.currentPage + 1, limit: lastPage.limit }
                : undefined;
        },
        getPreviousPageParam: (firstPage) => {
            return firstPage.pages !== 0 && firstPage.currentPage > 0
                ? { page: firstPage.currentPage - 1, limit: firstPage.limit }
                : undefined;
        },
    });
}

const useUpdateRestaurantSubscription = (
    options?: UseMutationOptions<void, ILocalizedError, IUpdateRestaurantSubscriptionRequest>,
) => {
    return useMutation(LocalinaApiContext.supportApi.updateRestaurantAccountPlan, options);
};

const useGrantAccountAccess = (options?: UseMutationOptions<void, ILocalizedError, string>) => {
    return useMutation((accountId) => LocalinaApiContext.supportApi.grantAccountAccess(accountId), {
        ...options,
        onSuccess: () => {
            return LocalinaApiContext.authApi.getAccessToken(true);
        },
    });
};
const useRevokeAccountAccess = (options?: UseMutationOptions<void, ILocalizedError>) => {
    return useMutation(LocalinaApiContext.supportApi.revokeAccountAccess, {
        ...options,
        onSuccess: () => {
            return LocalinaApiContext.authApi.getAccessToken(true);
        },
    });
};
const useRegisterAccountFromSupportUser = (options?: UseMutationOptions<string, ILocalizedError, ICreateAuthUser>) => {
    const [accountId, setAccountId] = useState('');

    const accountQuery = useQuery({
        queryKey: queryKeys.accounts.single(accountId),
        queryFn: () => {
            return LocalinaApiContext.supportApi.getAccount(accountId);
        },
        enabled: Boolean(accountId),
    });
    const createAccountMutation = useMutation(
        (user) => LocalinaApiContext.authApi.registerAccountFromSupportUser(user),
        {
            ...options,
            onSuccess: setAccountId,
        },
    );

    return {
        mutate: createAccountMutation.mutate,
        data: accountQuery.data,
        isLoading: createAccountMutation.isLoading || accountQuery.isInitialLoading,
        isError: createAccountMutation.isError || accountQuery.isError,
        isSuccess: createAccountMutation.isSuccess && accountQuery.isSuccess,
    };
};

type TPageParam = {
    page: number;
    limit: number;
};

type TQueryKey = ReturnType<typeof queryKeys.support.accounts>;

export {
    useSupportAccounts,
    useUpdateRestaurantSubscription,
    useGrantAccountAccess,
    useRevokeAccountAccess,
    useRegisterAccountFromSupportUser,
};
