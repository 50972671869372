import { Spinner } from '@localina/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useLogoutMutation } from '../../../api/queries/auth';
import { useRevokeAccountAccess } from '../../../api/queries/supportAccounts';
import { Path } from '../../../enums';
import { IAuthUser } from '../../../interfaces';
import { RoleUtils, SupportUtils } from '../../../utils';
import { Category, ICategoryItem } from './Category';

interface IProps {
    authUser: IAuthUser;
}

export const Account: React.FC<IProps> = (props) => {
    const navigate = useNavigate();
    const { i18n, t } = useTranslation();

    const revokeAccountAccessMutation = useRevokeAccountAccess();

    const handleHelpCenter = () => {
        window.open(
            `https://cc.localsearch.ch/s/help?language=${
                i18n.language === 'en' ? 'en_US' : i18n.language
            }&topic=Product_related_questions_NV&subtopic=MyLOCALINA_Free_reservations_system_for_restaurants_NV`,
            '_blank',
        );
    };

    const logoutMutation = useLogoutMutation();

    const handleLogout = () => {
        logoutMutation.mutate();
    };

    const handleRevoke = () => {
        revokeAccountAccessMutation.mutate(undefined, {
            onSuccess: () => {
                navigate(Path.SUPPORT_ACCOUNTS);
                SupportUtils.resetSession();
                window.location.reload();
            },
        });
    };

    const categoryItems: ICategoryItem[] = [
        RoleUtils.canViewUsers(props.authUser) && {
            title: t('common.menu.categories.account.menu.users'),
            url: Path.ACCOUNT_USERS,
        },
        RoleUtils.canViewRestaurants(props.authUser) && {
            title: t('common.menu.categories.account.menu.restaurants'),
            url: Path.ACCOUNT_RESTAURANTS,
        },
        {
            title: t('common.menu.categories.account.menu.help'),
            onClick: handleHelpCenter,
        },
        {
            title: t('common.menu.categories.account.menu.logout'),
            onClick: handleLogout,
        },
        RoleUtils.hasSupportRole(props.authUser) && {
            title: t('common.menu.categories.account.menu.revoke'),
            onClick: handleRevoke,
        },
    ].filter((it) => !!it) as ICategoryItem[];

    return (
        <>
            <Category name="account" title={t('common.menu.categories.account.title')} items={categoryItems} />
            {revokeAccountAccessMutation.isLoading && <Spinner />}
        </>
    );
};

export default Account;
