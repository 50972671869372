import { FabButton, IRestaurant, IUpdateRestaurant, List, StringUtils } from '@localina/core';
import { AddIcon } from '@localina/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGetAuthUser } from '../../api/queries/auth';
import { useGetRestaurants, useUpdateSpecificRestaurantMutation } from '../../api/queries/restaurants';
import { Page, RestaurantListItem, RestaurantView } from '../../components';
import { RestaurantWizard } from '../../components/RestaurantWizard';

function prepareRestaurantObject(restaurant: IRestaurant): Partial<IUpdateRestaurant> {
    return {
        info: {
            ...restaurant.info,
            phoneNumber: StringUtils.removeSpace(restaurant.info.phoneNumber),
        },
        receiveReservationsEmails: Boolean(restaurant.configuration.receiveReservationsEmails),
        platformIds: restaurant.configuration.platforms,
        cancelReservationNotice: restaurant.configuration.cancelReservationNotice,
    };
}

const Restaurants: React.FC = () => {
    const { t } = useTranslation();

    const [restaurant, setRestaurant] = React.useState<IRestaurant | undefined>(undefined);
    const [isWizardVisible, setWizardVisible] = React.useState(false);

    const authUserQuery = useGetAuthUser();
    const restaurantsQuery = useGetRestaurants();
    const updateRestaurantMutation = useUpdateSpecificRestaurantMutation();

    const handleItemClick = (item: IRestaurant) => {
        setRestaurant(item);
    };

    const handleClose = () => {
        setRestaurant(undefined);
    };

    const handleSave = (value: IRestaurant) => {
        updateRestaurantMutation.mutate(
            {
                restaurantId: value.id,
                restaurant: prepareRestaurantObject(value),
            },
            {
                onSuccess: () => {
                    setRestaurant(undefined);
                },
            },
        );
    };

    const handleWizardShow = () => {
        setWizardVisible(true);
    };

    const handleWizardClose = () => {
        setWizardVisible(false);
        void restaurantsQuery.refetch();
    };

    const isSupportUser = authUserQuery.isSuccess && authUserQuery.data.hasSupportRole;

    return (
        <Page
            name="restaurants"
            title={t('restaurants.title')}
            isLoading={restaurantsQuery.isLoading || updateRestaurantMutation.isLoading}
        >
            <List>
                {restaurantsQuery.data?.restaurants.map((item) => (
                    <RestaurantListItem key={item.id} restaurant={item} onClick={handleItemClick} />
                ))}
            </List>
            <RestaurantView
                restaurant={restaurant}
                hasError={updateRestaurantMutation.isError}
                onClose={handleClose}
                onSave={handleSave}
            />
            {isSupportUser && <FabButton icon={<AddIcon />} onClick={handleWizardShow} />}
            {isWizardVisible && <RestaurantWizard onClose={handleWizardClose} />}
        </Page>
    );
};

export default Restaurants;
