import { GuestStatus, IReservation, Label, ReservationUtils, StringUtils, TIME_FORMAT } from '@localina/core';
import { ClockIcon, PeopleIcon } from '@localina/icons';
import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHaveAccountFeatures } from '../../api/queries/account';
import { GuestStatusIcon } from '../../components/GuestStatusIcon';
import { ReservationGuestAdditionalInfo } from '../../components/ReservationGuestAdditionalInfo';
import ReservationOriginIcon from '../../components/ReservationOriginIcon/ReservationOriginIcon';
import ReservationStatusActionIcon from '../../components/ReservationStatus/ReservationStatusActionIcon';
import { ReservationTableNumbers } from '../../components/ReservationTableNumbers';
import { GuestUtils } from '../../utils';

interface IProps {
    reservation: IReservation;
    tableNumbers: string[];
    areaCode: string;
    isVirtualArea: boolean;
}

const elements = document.getElementsByClassName('localina-page');
export const ReservationItem: React.FC<IProps> = ({ reservation, tableNumbers, areaCode, isVirtualArea }) => {
    const { t } = useTranslation();
    const className = StringUtils.combineStrings([
        'reservation-list-shiftgroup__reservation-item',
        ReservationUtils.isReservationCancelled(reservation) &&
            'reservation-list-shiftgroup__reservation-item__cancelled',
    ]);
    const [canUseColorIndicator] = useHaveAccountFeatures(['reservationColor']);

    return (
        <div className={className}>
            {Boolean(canUseColorIndicator && reservation.color) && (
                <div
                    className={'reservation-list-shiftgroup__reservation-item__color-indicator'}
                    style={{ backgroundColor: reservation.color! }}
                />
            )}
            <div className="align-space-between">
                <div className="info-row">
                    <div className="icon-group">
                        <ClockIcon />
                        <Label
                            type="text"
                            value={DateTime.fromISO(reservation.reservationDateTime).toFormat(TIME_FORMAT)}
                        />
                    </div>
                    <div className="icon-group">
                        <PeopleIcon />
                        <Label type="text" value={reservation.participants} />
                    </div>
                    <div className="icon-group">
                        <ReservationTableNumbers
                            tableNumbers={tableNumbers}
                            tooltipContainer={elements.length === 1 ? elements[0] : undefined}
                        />
                    </div>
                    <ReservationOriginIcon reservation={reservation} />
                </div>
                <div className="info-wrapper">
                    <div className="guest-info-container">
                        <div className="additional-info">
                            {reservation.guestInfo && (
                                <ReservationGuestAdditionalInfo
                                    reservation={reservation}
                                    tooltipContainer={elements.length === 1 ? elements[0] : undefined}
                                />
                            )}
                        </div>
                        <Label
                            extraClassName={StringUtils.combineStrings([
                                'guest-info',
                                reservation.guestInfo?.guestStatus && 'bold',
                                reservation.guestInfo?.guestStatus === GuestStatus.BLACKLIST && 'error',
                            ])}
                            type="info"
                            value={GuestUtils.formatGuestName(
                                reservation.guestInfo,
                                t('reservations.view.fields.walkin'),
                            )}
                        />
                        {Boolean(reservation.guestInfo) && (
                            <GuestStatusIcon guestStatus={reservation.guestInfo?.guestStatus} />
                        )}
                    </div>
                    {reservation.areaIds.length > 0 && (
                        <Label error={isVirtualArea} extraClassName="area-code" type="info" value={areaCode} />
                    )}
                </div>
            </div>
            <ReservationStatusActionIcon reservation={reservation} />
        </div>
    );
};
