import {
    createEmptyMessage,
    createEmptyMessageDisplayText,
    IMessage,
    IMessageDisplayText,
    MessageType,
    useConfirmContext,
} from '@localina/core';
import { EmailIcon, TextAfterIcon, TextBeforeIcon } from '@localina/icons';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate } from 'react-router-dom';
import { useGetAccountSubscription } from '../../api/queries/account';
import { useCreateMessage, useDeleteMessage, useGetMessage, useUpdateMessage } from '../../api/queries/messages';
import { Page } from '../../components';
import { Path } from '../../enums';
import { PathUtils } from '../../utils';
import { MessageDisplayTextFragment, MessageFragment, validateMessage, validateMessageDisplayText } from './Fragments';

interface IPathParams {
    restaurantId: string;
    messageId: MessageType | string;
}

const Message: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useMatch(Path.RESTAURANT_SETTINGS_MESSAGE)?.params as IPathParams;
    const { restaurantId, messageId } = params;

    const messageTypes: string[] = [
        MessageType.WELCOME,
        MessageType.GOODBYE,
        MessageType.EMAIL,
        MessageType.CONFIRMATION_MAIL,
    ];
    const isEditing = !messageTypes.includes(messageId);
    const subscription = useGetAccountSubscription(restaurantId);

    const messageQuery = useGetMessage(messageId, {
        enabled: isEditing,
    });
    const createMessageMutation = useCreateMessage();
    const updateMessageMutation = useUpdateMessage();
    const deleteMessageMutation = useDeleteMessage();

    const { snackbar, confirm } = useConfirmContext();

    const [message, setMessage] = React.useState<IMessage | undefined>(undefined);
    const [messageDisplayText, setMessageDisplayText] = React.useState<IMessageDisplayText | undefined>(undefined);
    const [isFormValid, setFormValid] = React.useState(false);

    const handleBack = () => {
        if (messageDisplayText) {
            setMessageDisplayText(undefined);
        } else {
            navigate(PathUtils.generateUrl(Path.RESTAURANT_SETTINGS_MESSAGES, { restaurantId }));
        }
    };

    React.useEffect(() => {
        if (messageQuery.isError) {
            if (messageQuery.error?.message) {
                snackbar({ msg: messageQuery.error.message, severity: 'error' });
            }
            handleBack();
        }
    }, [messageQuery.isError]);

    React.useEffect(() => {
        if (!isEditing) {
            setMessage(createEmptyMessage(messageId as MessageType));
        }
    }, [isEditing]);

    React.useEffect(() => {
        if (isEditing) {
            setMessage(messageQuery.data);
        }
    }, [messageQuery.data]);

    const onSaveSuccess = () => {
        snackbar({ msg: t('message.saved'), severity: 'success' });
        handleBack();
    };
    const onSaveError = () => {
        snackbar({ msg: t(`message.errors.save`), severity: 'error' });
    };
    const onDeleteError = () => {
        snackbar({ msg: t(`message.errors.delete`), severity: 'error' });
    };

    React.useEffect(() => {
        if (message) {
            if (message.link && message.link.length > 4 && !message.link.startsWith('http')) {
                setMessage({ ...message, link: 'http://' + message.link });
            }
            setFormValid(validateMessage(message));
        }
    }, [message]);

    React.useEffect(() => {
        if (messageDisplayText) {
            setFormValid(validateMessageDisplayText(messageDisplayText, message?.type));
        }
    }, [messageDisplayText]);

    const handleSave = () => {
        if (message) {
            if (messageDisplayText) {
                setMessage({
                    ...message,
                    displayTexts: message.displayTexts.some((it) => it.language === messageDisplayText.language)
                        ? message.displayTexts.map((it) =>
                              it.language === messageDisplayText.language ? messageDisplayText : it,
                          )
                        : [...message.displayTexts, messageDisplayText],
                });
                handleBack();
            } else {
                if (isEditing) {
                    updateMessageMutation.mutate(message, {
                        onSuccess: onSaveSuccess,
                        onError: onSaveError,
                    });
                } else {
                    createMessageMutation.mutate(message, {
                        onSuccess: onSaveSuccess,
                        onError: onSaveError,
                    });
                }
            }
        }
    };

    const handleDelete = async () => {
        if (
            (isEditing && !messageDisplayText) ||
            (messageDisplayText && message?.displayTexts.some((it) => it.language === messageDisplayText.language))
        ) {
            if (
                (await confirm({
                    msg: t('message.dialog.message', {
                        name: messageDisplayText?.language ?? message?.name ?? '',
                    }),
                    title: t('message.dialog.title'),
                })) === 'yes'
            ) {
                confirmDelete();
            }
        }
        return;
    };

    const confirmDelete = () => {
        if (message) {
            if (messageDisplayText) {
                setMessage({
                    ...message,
                    displayTexts: message.displayTexts.filter((it) => it.language !== messageDisplayText.language),
                });
                handleBack();
            } else {
                deleteMessageMutation.mutate(message, {
                    onError: onDeleteError,
                    onSuccess: handleBack,
                });
            }
        }
    };

    const getTitle = () => {
        if (!message) {
            return '';
        }

        let icon;
        if (!messageDisplayText) {
            switch (message.type) {
                case MessageType.WELCOME:
                    icon = <TextBeforeIcon />;
                    break;
                case MessageType.GOODBYE:
                    icon = <TextAfterIcon />;
                    break;
                case MessageType.CONFIRMATION_MAIL:
                    icon = <EmailIcon />;
                    break;
                case MessageType.EMAIL:
                    icon = <EmailIcon />;
            }
        }

        return {
            icon,
            value: t(`message.titles.${messageDisplayText ? 'text' : message.type}`),
            onBack: handleBack,
            tooltip: !messageDisplayText ? t(`message.infoText.${message.type}`) : undefined,
        };
    };

    const handleCreateDisplayText = (language: string) => {
        setMessageDisplayText(createEmptyMessageDisplayText(language));
    };

    useEffect(() => {
        if (subscription !== 'standard' && !isEditing && messageId === MessageType.CONFIRMATION_MAIL) {
            navigate(PathUtils.generateUrl(Path.RESTAURANT_SETTINGS_MESSAGES, { restaurantId }));
        }
    }, [subscription, isEditing, messageId]);

    const isLoading =
        messageQuery.isInitialLoading ||
        createMessageMutation.isLoading ||
        updateMessageMutation.isLoading ||
        deleteMessageMutation.isLoading;

    return (
        <Page
            name="message"
            title={getTitle()}
            actions={{
                save: {
                    disabled: !isFormValid,
                    label: messageDisplayText && t('common.buttons.ok'),
                    onClick: handleSave,
                },
                onCancel: handleBack,
                onDelete: handleDelete,
            }}
            isLoading={isLoading}
        >
            <div id="message">
                {message &&
                    (messageDisplayText ? (
                        <MessageDisplayTextFragment
                            message={message}
                            messageDisplayText={messageDisplayText}
                            onChange={setMessageDisplayText}
                        />
                    ) : (
                        <MessageFragment
                            message={message}
                            onChange={setMessage}
                            onCreateDisplayText={handleCreateDisplayText}
                            onEditDisplayText={setMessageDisplayText}
                        />
                    ))}
            </div>
        </Page>
    );
};

export default Message;
