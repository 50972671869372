import { IconButton, IRestaurant, Label, LocalinaLogo, Page, StringUtils, useConfirmContext } from '@localina/core';
import { MenuIcon, NotificationIcon, NotificationsAvailableIcon } from '@localina/icons';
import { Badge } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate } from 'react-router-dom';
import { useGetAuthUser } from '../../api/queries/auth';
import { useGetRestaurants, usePreservedRestaurant } from '../../api/queries/restaurants';
import { useReservationFormContext } from '../../contexts/ReservationsContext';
import { Path } from '../../enums';
import { PathUtils, useForcingVisualViewportRepositioning, useIsPublicPath } from '../../utils';
import { useActivateLocalChBanner } from '../../utils/PlatformUtils';
import { AppMenu } from '../AppMenu';
import { AuthenticationLayer } from '../AuthenticationLayer';
import { RestaurantWizard } from '../RestaurantWizard';
import { UserFeature } from '../UserFeature';
import { WelcomeModal } from '../WelcomeModal';

type IProps = { children?: React.ReactNode };

const AppContainer: React.FC<IProps> = (props) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const isPublicPath = useIsPublicPath();

    const [isMenuOpen, setMenuOpen] = React.useState(true);

    const [isWizardVisible, setWizardVisible] = React.useState(false);

    const { unreadReservationsCount, openNotifications } = useReservationFormContext();

    const { confirm } = useConfirmContext();

    const authUser = useGetAuthUser({ enabled: false });
    const restaurantsQuery = useGetRestaurants({ enabled: false });
    const restaurantQuery = usePreservedRestaurant(false);

    const isReservationPage = Boolean(useMatch({ path: Path.RESTAURANT_RESERVATIONS }));

    const askToContinueInWizard = async () => {
        if (
            !isWizardVisible &&
            (await confirm({
                title: t('wizard.continueSetupModal.title'),
                msg: t('wizard.continueSetupModal.message'),
                confirmLabel: t('wizard.continueSetupModal.openWizardActionLabel'),
                cancelLabel: t('wizard.continueSetupModal.cancelLabel'),
            })) === 'yes'
        ) {
            setWizardVisible(true);
        }
    };

    React.useEffect(() => {
        if (!isWizardVisible && restaurantsQuery.data?.restaurants) {
            if (restaurantsQuery.data.restaurants.length === 1) {
                if (!restaurantsQuery.data.restaurants[0].info.name) {
                    setWizardVisible(true);
                    return;
                }
            }
            const shiftsAreMissingFromConfiguration = (configuration: IRestaurant['configuration']) =>
                configuration.virtualAreas.flatMap((va) => va.areas).length === 1 &&
                configuration.shifts.length === 0 &&
                configuration.closingDays.length === 0;

            if (restaurantQuery.data && shiftsAreMissingFromConfiguration(restaurantQuery.data.configuration)) {
                void askToContinueInWizard();
            }
        }
    }, [restaurantsQuery.data?.restaurants, restaurantQuery.data?.info.name]);

    const toggleMenu = () => {
        setMenuOpen((prevState) => !prevState);
    };

    const handleLogo = () => {
        if (authUser.isSuccess && restaurantQuery.isSuccess && restaurantQuery.data?.id) {
            navigate(PathUtils.generateUrl(Path.RESTAURANT_RESERVATIONS, { restaurantId: restaurantQuery.data?.id }));
        }
    };

    const handleWizardClose = async () => {
        setWizardVisible(false);
        await restaurantsQuery.refetch();
    };

    useForcingVisualViewportRepositioning();

    const [banner, isLoadingLocalChActivation] = useActivateLocalChBanner();

    return (
        <Page
            name="app-container"
            title=""
            leftBarButton={
                authUser.isSuccess && (
                    <div className="menu">
                        {<MenuIcon onClick={toggleMenu} />}
                        {restaurantQuery.isSuccess && (
                            <Label type="text" value={restaurantQuery.data?.info.name ?? ''} />
                        )}
                    </div>
                )
            }
            isLoading={isLoadingLocalChActivation}
            banner={banner}
            rightBarButton={
                <>
                    <LocalinaLogo onClick={handleLogo} />
                    <UserFeature filter={'notificationButton'}>
                        <div className="notifications-button-wrapper">
                            <Badge badgeContent={unreadReservationsCount} className={'notifications-badge'} max={99}>
                                <IconButton
                                    icon={
                                        unreadReservationsCount > 0 ? (
                                            <NotificationsAvailableIcon
                                                className="notifications-available"
                                                size="big"
                                            />
                                        ) : (
                                            <NotificationIcon size="big" />
                                        )
                                    }
                                    onClick={openNotifications}
                                />
                            </Badge>
                        </div>
                    </UserFeature>
                </>
            }
        >
            <div
                className="app-container__background"
                style={{ backgroundImage: `url("${process.env.PUBLIC_URL}/images/logo.png")` }}
            >
                <div className="app-container__background__overlay" />
            </div>
            <div className="app-container__body">
                <AuthenticationLayer>
                    {authUser.isSuccess &&
                        authUser.data.user &&
                        authUser.data.isLoggedIn &&
                        restaurantsQuery.data?.restaurants.length != 0 && (
                            <>
                                {(authUser.data.hasSupportRole || restaurantQuery.isSuccess) && !isPublicPath && (
                                    <AppMenu
                                        isOpen={isMenuOpen}
                                        restaurant={restaurantQuery.data}
                                        restaurants={restaurantsQuery.data?.restaurants || []}
                                        authUser={authUser.data.user}
                                        setMenuOpen={setMenuOpen}
                                    />
                                )}
                                {restaurantQuery.isSuccess && (
                                    <>
                                        {isWizardVisible && (
                                            <RestaurantWizard
                                                restaurant={restaurantQuery.data}
                                                onClose={handleWizardClose}
                                            />
                                        )}
                                        <WelcomeModal welcomeMessageNumber={12} />
                                    </>
                                )}
                            </>
                        )}
                    <div
                        className={StringUtils.combineStrings([
                            'app-container__body__content',
                            isReservationPage && 'no-background-color',
                        ])}
                    >
                        {props.children}
                    </div>
                </AuthenticationLayer>
            </div>
        </Page>
    );
};

export default AppContainer;
