import {
    Button,
    ButtonGroup,
    DATE_FORMAT,
    DateField,
    DateTimeUtils,
    IconButton,
    IReportEntry,
    IRestaurantArea,
    IRestaurantShift,
    IRestaurantVirtualArea,
    Label,
    SelectField,
    SERVER_DATE_FORMAT,
    ShiftUtils,
    SpeedDial,
    Switch,
    TLanguageKey,
} from '@localina/core';
import { CancelIcon, DownloadIcon, ImportExportVerticalIcon } from '@localina/icons';
import { BarChartOutlined, PictureAsPdf, TableView } from '@mui/icons-material';
import { useQueryClient } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Margin, usePDF } from 'react-to-pdf';
import { queryKeys } from '../../api/queries/query-keys';
import {
    IGetReportExportFilters,
    IGetReportFilters,
    useRestaurantReport,
    useRestaurantReportExport,
} from '../../api/queries/reports';
import { useAreas, useVirtualAreas } from '../../api/queries/restaurantAreas';
import { useRestaurant } from '../../api/queries/restaurants';
import { Page } from '../../components';
import { ExportFormat } from '../../enums';
import { downloadExportFile } from '../../utils';
import { Charts } from './Charts';

const defaultReportFilters: IGetReportFilters = {
    areaId: '',
    shiftIds: [],
    dateFrom: '',
    dateTo: '',
    creationTime: false,
};

const generateChartsFileName = (fromDate: DateTime, toDate: DateTime) =>
    fromDate.equals(toDate)
        ? `${fromDate.toFormat(DATE_FORMAT)} reservations charts`
        : `${fromDate.toFormat(DATE_FORMAT)} - ${toDate.toFormat(DATE_FORMAT)} reservations charts`;

const Reports: React.FC = () => {
    const { i18n, t } = useTranslation();
    const [selectedShifts, setSelectedShifts] = React.useState<string[]>([]);
    const [fromDate, setFromDate] = React.useState<DateTime<true | false>>(DateTime.now().minus({ days: 7 }));
    const [toDate, setToDate] = React.useState<DateTime<true | false>>(DateTime.now().plus({ days: 7 }));

    const [invalidIsVisible, setInvalidIsVisible] = useState(true);
    const [validSegmentsAreVisible, setValidSegmentsAreVisible] = useState(false);
    const [shiftOptions, setShiftOptions] = React.useState<{ label: string; value: string }[]>();
    const [selectedArea, setSelectedArea] = React.useState<string>('');
    const [graphicData, setGraphicData] = React.useState<IReportEntry[]>();
    const [validForm, setValidForm] = React.useState(false);

    const { toPDF, targetRef: chartsRef } = usePDF({
        method: 'save',
        filename: generateChartsFileName(fromDate, toDate),
        page: { margin: Margin.MEDIUM },
    });

    const virtualAreasQuery = useVirtualAreas();
    const areasQuery = useAreas();
    const queryClient = useQueryClient();
    const restaurantQuery = useRestaurant();
    const [reportFilters, setReportFilters] = React.useState<IGetReportFilters>(defaultReportFilters);

    const reportQuery = useRestaurantReport(reportFilters, {
        enabled: Boolean(reportFilters.dateFrom && reportFilters.dateTo),
    });
    const [exportFilters, setExportFilters] = React.useState<IGetReportExportFilters>({
        ...defaultReportFilters,
        format: ExportFormat.CSV,
    });
    const reportExportQuery = useRestaurantReportExport(exportFilters, {
        enabled: Boolean(exportFilters.dateTo && exportFilters.dateTo),
    });

    const areaList = React.useMemo(() => {
        const all = { label: 'All', value: '' };
        if (virtualAreasQuery.isSuccess && areasQuery.isSuccess) {
            const isAreaRelevant = (area: IRestaurantArea) =>
                area.areaShifts.some(({ shiftId }) => selectedShifts.includes(shiftId));
            const isVirtualAreaRelevant = (virtualArea: IRestaurantVirtualArea) =>
                virtualArea.areas.some(isAreaRelevant);

            const virtualAreas = virtualAreasQuery.data.virtualAreas
                .filter((virtualArea) => isVirtualAreaRelevant(virtualArea))
                .map((va) => ({ label: va.name, value: va.id }));

            const areas = areasQuery.data.areas
                .filter((area) => isAreaRelevant(area))
                .map((va) => ({ label: va.name, value: va.id }));

            return [all, ...virtualAreas, ...areas];
        } else {
            return [all];
        }
    }, [virtualAreasQuery.data, areasQuery.data, selectedShifts]);

    const dateCriteriaOptions = [
        {
            label: t('reports.time.reservation'),
            value: 'reservation',
        },
        {
            label: t('reports.time.creation'),
            value: 'creation',
        },
    ];

    const [selectedDateCriteria, setSelectedDateCriteria] = useState(dateCriteriaOptions[0].value);
    const isBasedOnCreationDate = selectedDateCriteria === 'creation';

    React.useEffect(() => {
        if (reportExportQuery.isSuccess && reportExportQuery.data && restaurantQuery.data?.id) {
            const generateReportName = () => {
                if (fromDate.equals(toDate)) {
                    return fromDate.toFormat(DATE_FORMAT) + ' reservation report';
                }
                return fromDate.toFormat(DATE_FORMAT) + ' - ' + toDate.toFormat(DATE_FORMAT) + ' reservation report';
            };

            downloadExportFile(reportExportQuery.data, {
                name: generateReportName(),
                type: exportFilters.format,
            });
            void queryClient.removeQueries(
                queryKeys.restaurants.single.reports.single.export(restaurantQuery.data.id, exportFilters),
            );

            setExportFilters({
                ...defaultReportFilters,
                format: ExportFormat.CSV,
            });
        }
    }, [reportExportQuery.data]);

    React.useEffect(() => {
        setValidForm(selectedShifts.length > 0 && fromDate.isValid && toDate.isValid && fromDate <= toDate);
        if (areaList.every((area) => area.value !== selectedArea)) {
            setSelectedArea('');
        }
    }, [selectedShifts, fromDate, toDate]);

    React.useEffect(() => {
        if (restaurantQuery.data) {
            const isShiftRelevant = (shift: IRestaurantShift) =>
                shift.shiftType === 'recurrent' ||
                (shift.shiftType === 'soh' &&
                    (DateTimeUtils.isDateInRangeOfDates(shift.fromDate ?? shift.dates[0], fromDate, toDate) ||
                        DateTimeUtils.isDateInRangeOfDates(
                            shift.toDate ?? shift.dates[shift.dates.length - 1],
                            fromDate,
                            toDate,
                        )));

            const getLabelForShift = (shift: IRestaurantShift) =>
                `${shift.name[i18n.language as TLanguageKey]} - ${ShiftUtils.getSubtitleForShift(
                    shift,
                    i18n.language,
                )}`;

            const mapShift = (shift: IRestaurantShift) => ({
                label: getLabelForShift(shift),
                value: shift.id,
            });

            setShiftOptions(restaurantQuery.data.configuration.shifts.filter(isShiftRelevant).map(mapShift));
        }
    }, [restaurantQuery.data, fromDate, toDate]);

    React.useEffect(() => {
        if (reportQuery.isSuccess) {
            setGraphicData(reportQuery.data);
        }
    }, [reportQuery.data]);

    const isEmptyData = useMemo(
        () =>
            !graphicData?.some((reportEntry) =>
                Boolean(
                    reportEntry.countManual ||
                        reportEntry.countOnline ||
                        reportEntry.countWalkins ||
                        reportEntry.countNoShow,
                ),
            ),
        [graphicData],
    );

    const handleDateChange = (field: string) => (value: string) => {
        if (field == 'fromDate') {
            setFromDate(DateTime.fromFormat(value, DATE_FORMAT));
        }
        if (field == 'toDate') {
            setToDate(DateTime.fromFormat(value, DATE_FORMAT));
        }
    };

    const search = () => {
        setReportFilters({
            areaId: selectedArea,
            shiftIds: selectedShifts,
            dateFrom: fromDate.toFormat(SERVER_DATE_FORMAT),
            dateTo: toDate.toFormat(SERVER_DATE_FORMAT),
            creationTime: isBasedOnCreationDate,
        });
    };

    const handleExportRequested = (format: ExportFormat) => {
        setExportFilters({
            ...reportFilters,
            format,
        });
    };

    const generateCSV = () => {
        handleExportRequested(ExportFormat.CSV);
    };

    const generatePDF = () => {
        handleExportRequested(ExportFormat.PDF);
    };

    const resetData = () => {
        setSelectedShifts([]);
        setFromDate(DateTime.now().minus({ days: 7 }));
        setToDate(DateTime.now().plus({ days: 7 }));
        setSelectedArea('');
        setGraphicData(undefined);
        setReportFilters(defaultReportFilters);
    };

    const loadedInitially = useRef(false);

    useEffect(() => {
        if (shiftOptions?.length && !loadedInitially.current) {
            setReportFilters({
                areaId: '',
                shiftIds: shiftOptions?.map((shift) => shift.value) || [],
                dateFrom: DateTime.now().minus({ months: 1 }).toFormat(SERVER_DATE_FORMAT),
                dateTo: DateTime.now().toFormat(SERVER_DATE_FORMAT),
                creationTime: isBasedOnCreationDate,
            });
            setSelectedShifts(shiftOptions?.map((shift) => shift.value) || []);
            setFromDate(DateTime.now().minus({ months: 1 }));
            setToDate(DateTime.now());
            setSelectedArea('');
            loadedInitially.current = true;
        }
    }, [shiftOptions]);

    return (
        <Page
            name="reports"
            title={t('reports.title')}
            isLoading={reportQuery.isInitialLoading || reportExportQuery.isInitialLoading}
        >
            <div className="reports-container">
                <div className="row">
                    <DateField
                        name="dateFrom"
                        label={t('availability.specialOpeningHourView.fields.dateFrom')}
                        value={fromDate.toFormat(DATE_FORMAT)}
                        onChange={handleDateChange('fromDate')}
                        required
                    />
                    <DateField
                        name="dateTo"
                        label={t('availability.specialOpeningHourView.fields.dateTo')}
                        value={toDate.toFormat(DATE_FORMAT)}
                        onChange={handleDateChange('toDate')}
                        required
                    />
                </div>
                <SelectField
                    value={selectedShifts}
                    label={t('reports.shifts.title')}
                    options={shiftOptions ?? []}
                    onChange={setSelectedShifts}
                    SelectProps={{
                        renderValue: (val) => {
                            const value = val as string[];
                            if (value.length === 1) {
                                const label = shiftOptions?.find((so) => so.value === value[0])?.label || '';
                                return <Label type={'text'} value={label} />;
                            } else if (value.length === shiftOptions?.length) {
                                return <Label type={'text'} value={t('reports.shifts.allShiftsSelected')} />;
                            } else {
                                return (
                                    <Label
                                        type={'text'}
                                        value={t('reports.shifts.numberOfSelectedShifts', { count: value.length })}
                                    />
                                );
                            }
                        },
                    }}
                    multiple
                    required
                />
                <ButtonGroup value={selectedArea} options={areaList} onChange={setSelectedArea} />
                <ButtonGroup
                    value={selectedDateCriteria}
                    options={dateCriteriaOptions}
                    onChange={setSelectedDateCriteria}
                />
                <div className={'controller-wrapper'}>
                    <div className={'switches'}>
                        <Switch
                            label={t('reports.displayNoShow')}
                            checked={invalidIsVisible}
                            onChange={setInvalidIsVisible}
                        />
                        <Switch
                            label={t('reports.splitInSegments')}
                            checked={validSegmentsAreVisible}
                            onChange={setValidSegmentsAreVisible}
                        />
                    </div>
                </div>
                <div className="buttons">
                    <div className="table-plan-creation-details-trash-icon">
                        <IconButton icon={<CancelIcon size="big" />} onClick={resetData} />
                    </div>
                    <Button disabled={!validForm} label={t('reports.search')} onClick={search} />
                </div>
                {graphicData && isEmptyData && <Label type={'text'} value={t('reports.noDataToDisplay')} />}
                {graphicData && !isEmptyData && (
                    <Charts
                        graphicData={graphicData}
                        invalidIsVisible={invalidIsVisible}
                        validSegmentsAreVisible={validSegmentsAreVisible}
                        ref={chartsRef}
                    />
                )}
            </div>
            <SpeedDial
                icon={<DownloadIcon />}
                openIcon={<ImportExportVerticalIcon />}
                label="export-fab-button"
                actions={[
                    {
                        label: t('reports.actions.downloadPdf'),
                        icon: <PictureAsPdf />,
                        onClick: generatePDF,
                    },
                    {
                        label: t('reports.actions.downloadCsv'),
                        icon: <TableView />,
                        onClick: generateCSV,
                    },
                    {
                        label: t('reports.actions.downloadCharts'),
                        icon: <BarChartOutlined />,
                        onClick: toPDF,
                        disabled: !graphicData || isEmptyData,
                    },
                ]}
            />
        </Page>
    );
};

export default Reports;
