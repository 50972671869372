import { IReservation, List, useConfirmContext } from '@localina/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHaveAccountFeatures } from '../../api/queries/account';
import { Drawer } from '../Drawer';
import { ReservationListItem } from '../ListItem';

export interface INotificationsViewProps {
    visible: boolean;
    reservations: IReservation[];
    hasError: boolean;
}

interface IProps extends INotificationsViewProps {
    onClose: () => void;
    onEdit: (reservation: IReservation) => void;
    onRead: (reservation: IReservation[]) => void; //mark the given reservation(s) as read
}

export const NotificationsView: React.FC<IProps> = (props) => {
    const { t } = useTranslation();
    const { confirm } = useConfirmContext();
    const handleClose = () => {
        props.onClose();
    };

    const [canMarkRead] = useHaveAccountFeatures(['markRead'], undefined, true);

    const handleReadAll = async () => {
        if (
            (await confirm({
                msg: t('notifications.view.confirmDialog.message'),
                title: t('notifications.view.confirmDialog.title'),
                cancelLabel: t('common.buttons.cancel'),
                confirmLabel: t('common.buttons.ok'),
            })) === 'yes'
        ) {
            props.onRead(props.reservations);
        }
    };

    const handleEditReservation = (reservation: IReservation) => {
        props.onEdit(reservation);
    };

    return (
        <Drawer
            className="notifications-view"
            open={props.visible}
            title={t('notifications.view.title')}
            error={{
                isVisible: props.hasError,
                message: t('notifications.view.error'),
            }}
            onClose={handleClose}
            extraButtons={[
                {
                    label: t('notifications.view.buttons.readAll'),
                    onClick: handleReadAll,
                    disabled: !props.reservations || props.reservations.length === 0 || !canMarkRead,
                },
            ]}
        >
            <List>
                {props.reservations.map((reservation) => (
                    <ReservationListItem
                        key={reservation.id}
                        reservation={reservation}
                        onClick={handleEditReservation}
                    />
                ))}
            </List>
        </Drawer>
    );
};
