var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Alert } from '@mui/material';
import { StringUtils } from '../../utils';
import { Container } from '../Container';
import { Label } from '../Label';
import { Spinner } from '../Spinner';
import { Stepper } from '../Stepper';
export var Page = function (props) { return (_jsxs(Container, __assign({ className: "localina-page" }, { children: [props.title !== undefined && (_jsx("header", __assign({ className: StringUtils.combineStrings(['localina-page__header', props.step !== undefined && 'stepper']) }, { children: _jsxs("div", __assign({ className: "localina-page__header__content" }, { children: [_jsxs("div", __assign({ className: "localina-page__header__title-bar" }, { children: [props.leftBarButton && _jsx("div", __assign({ className: "bar-button" }, { children: props.leftBarButton })), _jsx("div", { className: "grow" }), typeof props.title === 'string' ? (_jsx(Label, { type: "title", value: props.title, variant: "h5", align: "center" })) : (props.title), _jsx("div", { className: "grow" }), props.rightBarButton && _jsx("div", __assign({ className: "bar-button" }, { children: props.rightBarButton }))] })), props.step !== undefined && _jsx(Stepper, { activeStep: props.step })] })) }))), props.banner !== undefined && props.banner.open && (_jsx(Alert, __assign({ severity: props.banner.severity, onClose: props.banner.onClose, className: 'localina-page__banner' }, { children: props.banner.text }))), props.isLoading && _jsx(Spinner, {}), _jsxs("main", { children: [_jsx("div", __assign({ className: StringUtils.combineStrings([
                        'localina-page__body',
                        props.footer !== undefined && 'has-footer',
                        props.name,
                    ]) }, { children: _jsx("div", __assign({ className: "localina-page__body__content" }, { children: props.children })) })), props.footer && (_jsx("footer", __assign({ className: "localina-page__footer" }, { children: _jsx("div", __assign({ className: "localina-page__footer__content" }, { children: typeof props.footer === 'string' ? (_jsx(Label, { type: "text", value: props.footer, align: "center" })) : (props.footer) })) })))] })] }))); };
