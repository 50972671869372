var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Step, StepLabel, Stepper as MuiStepper, Typography } from '@mui/material';
import { StringUtils } from '../../utils';
import { FabButton } from '../FabButton';
var StepIcon = function (_a) {
    var active = _a.active, completed = _a.completed, icon = _a.icon;
    return (_jsx(FabButton, { className: StringUtils.combineStrings([!active && 'inactive', completed && 'completed']), icon: _jsx(Typography, __assign({ className: "label" }, { children: icon })), disabled: true, onClick: function () { return ({}); } }));
};
export var Stepper = function (props) { return (_jsx(MuiStepper, __assign({ className: "localina-stepper", activeStep: props.activeStep - 1 }, { children: [1, 2, 3, 4].map(function (key) { return (_jsx(Step, { children: _jsx(StepLabel, { StepIconComponent: StepIcon }) }, key)); }) }))); };
