var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { CloseIcon, SearchIcon } from '@localina/icons';
import { InputAdornment, TextField as MuiTextField } from '@mui/material';
import { IconButton } from '../IconButton';
import { InputField } from '../InputField';
export var SearchField = function (props) {
    var ref = React.createRef();
    var handleSearch = function () {
        props.onSearch(props.value);
    };
    var debouncedSearch = useDebouncedCallback(handleSearch, 300);
    var handleChange = function (event) {
        props.onChange(event.target.value);
        debouncedSearch();
    };
    var handleClear = function () {
        props.onChange('');
        props.onSearch('');
    };
    return (_jsx(InputField, __assign({ inputRef: ref, name: "localina-textfield localina-searchfield", onEnter: handleSearch }, { children: _jsx(MuiTextField, { ref: ref, variant: "outlined", type: "text", label: props.label, value: props.value, fullWidth: true, InputProps: {
                endAdornment: (_jsxs(InputAdornment, __assign({ position: "end" }, { children: [props.value.length !== 0 && _jsx(IconButton, { icon: _jsx(CloseIcon, {}), onClick: handleClear }), _jsx(IconButton, { icon: _jsx(SearchIcon, {}), onClick: handleSearch })] }))),
            }, onChange: handleChange }) })));
};
