import { Button, IRestaurant, Label, Spinner, TextField } from '@localina/core';
import { useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { queryKeys } from '../../../api/queries/query-keys';
import { useSaveAreaMutationWithRestaurantId } from '../../../api/queries/restaurantAreas';

interface IProps {
    restaurant: IRestaurant;
    onNext: () => void;
    onBack: () => void;
}

export const Configuration: React.FC<IProps> = (props) => {
    const { t } = useTranslation();
    const [capacity, setCapacity] = useState<number | undefined>(undefined);

    const updateAreaMutation = useSaveAreaMutationWithRestaurantId(props.restaurant.id);
    const queryClient = useQueryClient();

    const defaultAreaInfo = useMemo(() => {
        const defaultArea = props.restaurant.configuration.virtualAreas
            .flatMap((va) => va.areas)
            .find((a) => a.id === props.restaurant.configuration.defaultAreaId);
        if (defaultArea) {
            const virtualArea = props.restaurant.configuration.virtualAreas.find((va) =>
                va.areas.some((a) => a.id === defaultArea.id),
            );
            if (virtualArea) {
                return {
                    id: defaultArea.id,
                    name: defaultArea.name,
                    code: defaultArea.code,
                    capacity: defaultArea.capacity,
                    virtualAreaId: virtualArea.id,
                };
            }
        }
    }, [props.restaurant.configuration.virtualAreas, props.restaurant.configuration.defaultAreaId]);

    useEffect(() => {
        if (defaultAreaInfo) {
            setCapacity(defaultAreaInfo.capacity);
        }
    }, [defaultAreaInfo]);

    const handleNext = () => {
        if (defaultAreaInfo) {
            updateAreaMutation.mutate(
                {
                    ...defaultAreaInfo,
                    capacity: capacity || defaultAreaInfo.capacity,
                },
                {
                    onSuccess: async () => {
                        props.onNext();
                        return queryClient.invalidateQueries(queryKeys.restaurants.all);
                    },
                },
            );
        }
    };

    return (
        <>
            <Label type="title" value={t('wizard.titles.configuration')} variant="h5" />
            <TextField
                value={`${capacity || ''}`}
                label={t('restaurant.fields.totalCapacity')}
                name="capacity"
                onChange={(val) => {
                    setCapacity(Number(val));
                }}
            />
            <div className="grow" />
            <div className="buttons">
                <Button label={t('wizard.buttons.back')} secondary={true} onClick={props.onBack} />
                <Button
                    label={t('wizard.buttons.next')}
                    disabled={capacity === undefined || capacity <= 0}
                    onClick={handleNext}
                />
            </div>
            {updateAreaMutation.isLoading && <Spinner />}
        </>
    );
};
