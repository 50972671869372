import {
    DATE_FORMAT,
    DateTimeUtils,
    Label,
    RESERVATION_CHANGE_TABLE_DROP_TYPE,
    ReservationUtils,
    RestaurantUtils,
    SERVER_DATE_FORMAT,
    Spinner,
    useConfirmContext,
    useUrlParams,
} from '@localina/core';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useReservations, useUpdateReservationTables, useUpdateWalkinTables } from '../../api/queries/reservations';
import { useAreas, useVirtualAreas } from '../../api/queries/restaurantAreas';
import { useRestaurant } from '../../api/queries/restaurants';
import TablePlanAssignReservationsCanvas from '../../components/TablePlan/Canvas/TablePlanAssignReservationCanvas';
import { TablePlanAssignReservationsController } from '../../components/TablePlan/Controllers/TablePlanAssignReservationsController';
import TablePlanReservationsSwitchButton from '../../components/TablePlanReservationsSwitchButton/TablePlanReservationsSwitchButton';
import { useReservationFormContext } from '../../contexts/ReservationsContext';
import { Path, ReservationDetailsType } from '../../enums';
import { PathUtils } from '../../utils';
import { useReservationFiltersFromUrlParams, useTablePlansOfShift } from '../../utils/ReservationsUtils';
import CollapsibleTablePlansOverview from './CollapsibleTablePlansOverview';

const TablePlanAssignReservations: React.FC = () => {
    const [zoomCanvas, setZoomCanvas] = useState(1);
    const [selectedTablePlanId, setSelectedTablePlanId] = useState('');
    const { t } = useTranslation();
    const { confirm, snackbar } = useConfirmContext();
    const [selectedTime, setSelectedTime] = useState(DateTime.now().hour * 60 + DateTime.now().minute);

    const [params] = useUrlParams();
    const dateParam = params.get('date');

    const { openReservationForm } = useReservationFormContext();

    useEffect(() => {
        const today = DateTime.now();
        let paramsDateParsed = dateParam ? DateTime.fromFormat(dateParam, SERVER_DATE_FORMAT) : today;
        if (!paramsDateParsed.isValid) {
            paramsDateParsed = today;
        }
        if (!(paramsDateParsed === today || today.startOf('day').equals(paramsDateParsed.startOf('day')))) {
            setSelectedTime(0);
        } else {
            setSelectedTime(today.hour * 60 + today.minute);
            const intervalId = setInterval(() => {
                const now = DateTime.now();
                setSelectedTime(now.hour * 60 + now.minute);
            }, 1000);

            return () => clearInterval(intervalId);
        }
    }, [dateParam]);

    const [shiftFromFilter, areaFromFilter] = useReservationFiltersFromUrlParams();

    const shiftTablePlans = useTablePlansOfShift(shiftFromFilter);

    const restaurantQuery = useRestaurant();
    const virtualAreasQuery = useVirtualAreas();
    const virtualAreasIds = virtualAreasQuery.data?.virtualAreas.map((va) => va.id) || [];
    const areasQuery = useAreas();
    const reservationsQuery = useReservations(dateParam || '', {
        enabled: DateTime.fromFormat(dateParam || '', SERVER_DATE_FORMAT).isValid,
    });

    const updateReservationTablesMutation = useUpdateReservationTables();
    const updateWalkinTablesMutation = useUpdateWalkinTables();

    const dateTime = dateParam ? DateTime.fromFormat(dateParam, SERVER_DATE_FORMAT) : DateTime.now();

    const checkShiftForDateTime = (dt: DateTime) => {
        if (dt < DateTime.now().startOf('day')) {
            return false;
        }
        const targetShift = RestaurantUtils.getShiftsForDate(dt, restaurantQuery.data?.configuration).find((shift) => {
            return DateTimeUtils.isTimeInRange(dt, shift.shift.from, shift.shift.to);
        });
        const shiftTablePlan = shiftTablePlans.find((s) => s.shiftId === targetShift?.id);
        return shiftTablePlan?.tablePlans.some((tp) => tp.id === selectedTablePlanId) || false;
    };
    const areaOfSelectedTablePlan = areasQuery.data?.areas.find((area) =>
        area.tablePlans.some((tp) => tp.id === selectedTablePlanId),
    );

    const tablePlan = areaOfSelectedTablePlan?.tablePlans.find((tp) => tp.id === selectedTablePlanId);
    const shiftIdOfTimeSlider = RestaurantUtils.getShiftsForDate(dateTime, restaurantQuery.data?.configuration).find(
        (shift) => DateTimeUtils.isTimeInRange(dateTime, shift.shift.from, shift.shift.to),
    )?.id;

    const handleDrop = async (reservationId: string, tableId: string, itemType: string, previousTableId?: string) => {
        if (!restaurantQuery.data) {
            return;
        }
        const reservation = reservationsQuery.data?.reservations.find((r) => r.id === reservationId);
        if (!reservation) {
            return;
        }
        if (
            !ReservationUtils.areReservationAndTableInSameShift(
                reservation,
                tableId,
                restaurantQuery.data.configuration,
            )
        ) {
            snackbar({
                severity: 'error',
                msg: t('reservations.table.invalidShift'),
                autoHideDuration: 2000,
            });
            return;
        }
        const reservationTableIds = reservation.tableIds || [];
        if (reservationTableIds.includes(tableId)) {
            return;
        }
        const area = RestaurantUtils.getTableArea(restaurantQuery.data.configuration, tableId);
        if (!area) {
            return;
        }
        let tableIds = [...reservationTableIds, tableId];
        if (itemType === RESERVATION_CHANGE_TABLE_DROP_TYPE && previousTableId) {
            tableIds = tableIds.filter((table) => table !== previousTableId);
        }
        let mode = 'merge' as 'merge' | 'change';

        // if reservation has virtual area assigned
        if (reservation.areaIds.some((reservationAreaId) => virtualAreasIds.includes(reservationAreaId))) {
            // if the table is in area that is a sub-area of the virtual area
            if (
                ReservationUtils.isTableAreaSubareaOfReservationVirtualArea(
                    reservation,
                    tableId,
                    restaurantQuery.data.configuration,
                )
            ) {
                tableIds = tableIds.filter((tId) => {
                    return area.id === RestaurantUtils.getTableArea(restaurantQuery.data?.configuration, tId)?.id;
                });
                mode = 'change';
            } else {
                const confirmValue = await confirm({
                    msg: t('reservations.table.changeVirtualAreaDialog'),
                    confirmLabel: t('reservations.table.changeAreas'),
                    cancelLabel: t('buttons.cancel', { ns: 'core' }),
                });
                if (confirmValue === 'cancel' || confirmValue === 'no') {
                    return;
                }
                if (confirmValue === 'yes') {
                    tableIds = tableIds.filter((tId) => {
                        return area.id === RestaurantUtils.getTableArea(restaurantQuery.data?.configuration, tId)?.id;
                    });
                    mode = 'change';
                }
            }
        } else if (
            !ReservationUtils.areReservationAndTableInSameArea(
                reservation,
                tableId,
                restaurantQuery.data.configuration,
            ) &&
            itemType !== RESERVATION_CHANGE_TABLE_DROP_TYPE
        ) {
            const confirmValue = await confirm({
                msg: t('reservations.table.changeAreaDialog'),
                confirmLabel: t('reservations.table.changeAreas'),
                cancelLabel: t('reservations.table.mergeAreas'),
            });
            if (confirmValue === 'cancel') {
                return;
            }
            if (confirmValue === 'yes') {
                tableIds = tableIds.filter((tId) => {
                    return area.id === RestaurantUtils.getTableArea(restaurantQuery.data?.configuration, tId)?.id;
                });
                mode = 'change';
            }
        } else if (itemType === RESERVATION_CHANGE_TABLE_DROP_TYPE) {
            mode = 'change';
        }

        const mutation = reservation?.guestInfo ? updateReservationTablesMutation : updateWalkinTablesMutation;

        mutation.mutate(
            {
                reservationId,
                tableIds,
                mode,
            },
            {
                onSuccess: () => {
                    const message =
                        mode === 'change'
                            ? t('reservations.table.successMessages.changedTable')
                            : t('reservations.table.successMessages.addedTable');

                    snackbar({
                        severity: 'success',
                        msg: message,
                        autoHideDuration: 3000,
                    });
                    return reservationsQuery.refetch();
                },
            },
        );
    };

    useEffect(() => {
        if (shiftTablePlans.length === 0) {
            setSelectedTablePlanId('');
        } else if (shiftTablePlans.length === 1) {
            if (!selectedTablePlanId || !shiftTablePlans[0].tablePlans.find((tp) => tp.id === selectedTablePlanId)) {
                const areaShiftTablePlan = shiftTablePlans[0].tablePlans.find(
                    (tp) => tp.areaId === areaFromFilter?.area.id,
                );
                if (areaShiftTablePlan && selectedTablePlanId !== areaShiftTablePlan.id) {
                    setSelectedTablePlanId(areaShiftTablePlan.id);
                } else {
                    const firstTablePlanId = shiftTablePlans[0].tablePlans[0]?.id;
                    if (firstTablePlanId) {
                        setSelectedTablePlanId(firstTablePlanId);
                    }
                }
            }
            return;
        } else if (!selectedTablePlanId) {
            const defaultAreaId = restaurantQuery.data?.configuration.defaultAreaId;
            const defaultAreaTablePlanId = shiftTablePlans
                .flatMap((s) => s.tablePlans)
                .find((tp) => tp.areaId === defaultAreaId)?.id;

            if (defaultAreaTablePlanId) {
                setSelectedTablePlanId(defaultAreaTablePlanId);
            } else {
                const firstTablePlanId = shiftTablePlans[0].tablePlans[0]?.id;
                if (firstTablePlanId) {
                    setSelectedTablePlanId(firstTablePlanId);
                }
            }
        }
    }, [shiftTablePlans]);

    useEffect(() => {
        if (shiftTablePlans.length === 0) {
            setSelectedTablePlanId('');
        } else if (shiftTablePlans.length === 1) {
            const areaShiftTablePlan = shiftTablePlans[0].tablePlans.find(
                (tp) => tp.areaId === areaFromFilter?.area.id,
            );
            if (areaShiftTablePlan && selectedTablePlanId !== areaShiftTablePlan.id) {
                setSelectedTablePlanId(areaShiftTablePlan.id);
            }
        }
    }, [areaFromFilter?.area.id]);

    useEffect(() => {
        if (shiftTablePlans.length) {
            const availableTablePlans = shiftTablePlans.flatMap((s) => s.tablePlans);
            const selectedTablePlan = selectedTablePlanId
                ? availableTablePlans.find((tp) => tp.id === selectedTablePlanId)
                : undefined;

            if (!selectedTablePlan) {
                const defaultAreaId = restaurantQuery.data?.configuration.defaultAreaId;
                const defaultAreaTablePlanId = availableTablePlans.find((tp) => tp.areaId === defaultAreaId)?.id;

                if (defaultAreaTablePlanId) {
                    setSelectedTablePlanId(defaultAreaTablePlanId);
                } else {
                    const firstTablePlanId = shiftTablePlans[0].tablePlans[0]?.id;
                    if (firstTablePlanId) {
                        setSelectedTablePlanId(firstTablePlanId);
                    }
                }
            }
        } else if (selectedTablePlanId) {
            setSelectedTablePlanId('');
        }
    }, [selectedTablePlanId]);

    useEffect(() => {
        const now = DateTime.now();
        let paramsDateParsed = dateParam ? DateTime.fromFormat(dateParam, SERVER_DATE_FORMAT) : now;
        if (!paramsDateParsed.isValid) {
            paramsDateParsed = now;
        }
        if (!(paramsDateParsed === now || now.startOf('day').equals(paramsDateParsed.startOf('day')))) {
            setSelectedTime(0);
        }
    }, [dateParam]);

    const isLoading = updateReservationTablesMutation.isLoading || updateWalkinTablesMutation.isLoading;

    return (
        <div className="table-plan-assign-reservations">
            <TablePlanReservationsSwitchButton switchTo={'reservations'} />
            <TablePlanAssignReservationsController
                shiftTablePlans={shiftTablePlans}
                onTimeChange={setSelectedTime}
                selectedTime={selectedTime}
                zoomValue={zoomCanvas}
                onZoomChange={setZoomCanvas}
                selectedTablePlanId={selectedTablePlanId}
                onTablePlanChange={setSelectedTablePlanId}
                selectedShift={shiftFromFilter}
            />
            {shiftTablePlans.length === 0 && (
                <div className="table-plan-assign-reservations-empty">
                    <Label
                        type="title"
                        value={
                            <Trans i18nKey="reservations.table.empty">
                                There is no table plan
                                <Link
                                    className="mini-link"
                                    to={PathUtils.generateUrl(Path.RESTAURANT_SETTINGS_TABLE_PLAN, {
                                        restaurantId: restaurantQuery.data?.id,
                                    })}
                                >
                                    created/assigned
                                </Link>
                                for this area.
                            </Trans>
                        }
                    />
                </div>
            )}

            <TablePlanAssignReservationsCanvas
                onZoomChange={setZoomCanvas}
                tablePlan={tablePlan}
                onReserveTable={(tId, walk) => {
                    const selectedTable = areaOfSelectedTablePlan?.tablePlans
                        .flatMap((tp) => tp.tables)
                        .find((table) => table.id === tId);

                    const defaultState = {
                        participants: selectedTable ? `${selectedTable.numberOfSeats}` : '4',
                        tableIds: selectedTable ? [selectedTable.id] : [],
                        date: [dateTime.toFormat(DATE_FORMAT)],
                        areaTimes:
                            areaOfSelectedTablePlan && shiftIdOfTimeSlider
                                ? [{ shiftId: shiftIdOfTimeSlider, areaId: areaOfSelectedTablePlan.id }]
                                : [],
                    };

                    openReservationForm(
                        undefined,
                        walk ? ReservationDetailsType.WALKIN : ReservationDetailsType.CREATE,
                        defaultState,
                    );
                }}
                walkinEnabled={checkShiftForDateTime(DateTime.now())}
                reservationEnabled
                selectedTime={selectedTime}
                selectedWorkingHours={shiftFromFilter}
                onDrop={handleDrop}
                zoomCanvas={zoomCanvas}
            />
            <CollapsibleTablePlansOverview currentTime={selectedTime} setSelectedTablePlanId={setSelectedTablePlanId} />
            {isLoading && <Spinner />}
        </div>
    );
};

export default TablePlanAssignReservations;
