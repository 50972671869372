var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { DateTime } from 'luxon';
import { TIME_FORMAT } from '../config';
import { ReservationStatus } from '../enums';
import { DateTimeUtils } from './DateTimeUtils';
import { RestaurantUtils } from './RestaurantUtils';
import { StringUtils } from './StringUtils';
import { PlatformName } from '../enums/Platform';
var isPast = function (reservation) {
    return DateTimeUtils.isDateInPast(reservation.reservationDateTime);
};
var sumTotalParticipantsOfReservations = function (reservations) {
    return reservations.reduce(function (acc, reservation) { return acc + ReservationUtils.getReservationParticipants(reservation); }, 0);
};
var groupReservationsForDateTime = function (reservations) {
    return Object.values(reservations.reduce(function (acc, reservation) {
        var _a;
        var group = acc[reservation.reservationDateTime] || {};
        return __assign(__assign({}, acc), (_a = {}, _a[reservation.reservationDateTime] = {
            datetime: reservation.reservationDateTime,
            reservations: __spreadArray(__spreadArray([], (group.reservations || []), true), [reservation], false),
        }, _a));
    }, {})).sort(function (a, b) {
        return DateTime.fromISO(a.datetime, { zone: 'utc' }) < DateTime.fromISO(b.datetime, { zone: 'utc' }) ? -1 : 1;
    });
};
var getReservationParticipants = function (reservation) {
    return isReservationInDeadStatus(reservation) ? 0 : reservation.participants;
};
var isReservationInDeadStatus = function (reservation) {
    return DEAD_RESERVATION_STATUS_LIST.findIndex(function (value) { return value === reservation.status; }) > -1;
};
var DEAD_RESERVATION_STATUS_LIST = [
    ReservationStatus.NO_SHOW,
    ReservationStatus.RESTAURANT_CANCELLED,
    ReservationStatus.GUEST_CANCELLED,
    ReservationStatus.DECLINED,
];
var isReservationCancelled = function (reservation) {
    return isReservationStatusCancelled(reservation.status);
};
var isReservationStatusCancelled = function (reservationStatus) {
    return CANCELLED_RESERVATION_STATUS_LIST.includes(reservationStatus);
};
var CANCELLED_RESERVATION_STATUS_LIST = [
    ReservationStatus.RESTAURANT_CANCELLED,
    ReservationStatus.GUEST_CANCELLED,
];
var compareReservationsByTime = function (order) { return function (r1, r2) {
    return ((order === 'asc' ? 1 : -1) *
        (new Date(r1.reservationDateTime).getTime() - new Date(r2.reservationDateTime).getTime()));
}; };
var areReservationAndTableInSameArea = function (reservation, tableId, configuration) {
    var area = configuration === null || configuration === void 0 ? void 0 : configuration.virtualAreas.flatMap(function (v) { return v.areas; }).find(function (area) {
        return area.tablePlans.flatMap(function (tp) { return tp.tables; }).find(function (t) { return t.id === tableId; });
    });
    if (area === null || area === void 0 ? void 0 : area.id) {
        if (reservation.areaIds.includes(area.id)) {
            return true;
        }
        else {
            // check if table area is a sub-area of reservation's current virtual area
            var virtualArea = configuration === null || configuration === void 0 ? void 0 : configuration.virtualAreas.find(function (va) { return va.areas.findIndex(function (a) { return a.id === area.id; }) !== -1; });
            if (virtualArea) {
                return reservation.areaIds.includes(virtualArea.id);
            }
        }
    }
    return false;
};
var isTableAreaSubareaOfReservationVirtualArea = function (reservation, tableId, configuration) {
    var tableArea = RestaurantUtils.getTableArea(configuration, tableId);
    if (tableArea === null || tableArea === void 0 ? void 0 : tableArea.id) {
        var virtualArea = configuration === null || configuration === void 0 ? void 0 : configuration.virtualAreas.find(function (va) { return va.areas.findIndex(function (a) { return a.id === tableArea.id; }) !== -1; });
        if (virtualArea) {
            return reservation.areaIds.includes(virtualArea.id);
        }
    }
    return false;
};
var areReservationAndTableInSameShift = function (reservation, tableId, configuration) {
    var _a;
    var areas = (configuration === null || configuration === void 0 ? void 0 : configuration.virtualAreas.flatMap(function (v) { return v.areas; })) || [];
    var tablePlanId = (_a = areas.flatMap(function (a) { return a.tablePlans; }).find(function (tp) { return tp.tables.find(function (t) { return t.id === tableId; }); })) === null || _a === void 0 ? void 0 : _a.id;
    return (areas
        .flatMap(function (a) { return a.areaShifts; })
        .find(function (as) {
        return as.tablePlanId === tablePlanId && as.shiftId === reservation.shiftId;
    }) !== undefined);
};
var getTimeSlotForReservationTime = function (slots, reservationTime) {
    var reservationTimeParsed = DateTime.fromFormat(reservationTime, TIME_FORMAT);
    return slots.reduce(function (acc, ts) {
        if (!acc) {
            return ts;
        }
        var accTsParsed = DateTime.fromFormat(acc.timeSlot, TIME_FORMAT);
        var tsPars = DateTime.fromFormat(ts.timeSlot, TIME_FORMAT);
        if (accTsParsed > tsPars || tsPars > reservationTimeParsed) {
            return acc;
        }
        return ts;
    }, undefined);
};
var filterReservationsOnReservationList = function (reservations, search, walkinTranslation, configuration) {
    if (search) {
        var tableNames_1 = (configuration === null || configuration === void 0 ? void 0 : configuration.virtualAreas.flatMap(function (v) { return v.areas; }).flatMap(function (area) { return area.tablePlans; }).flatMap(function (tp) { return tp.tables; }).reduce(function (acc, table) {
            var _a;
            return (__assign(__assign({}, acc), (_a = {}, _a[table.id] = table.name, _a)));
        }, {})) || {};
        var query_1 = search === null || search === void 0 ? void 0 : search.toLowerCase();
        return reservations.filter(function (res) {
            var _a;
            return (res.guestInfo
                ? StringUtils.combineStrings([res.guestInfo.lastName, res.guestInfo.firstName])
                    .toLowerCase()
                    .includes(query_1) ||
                    StringUtils.combineStrings([res.guestInfo.firstName, res.guestInfo.lastName])
                        .toLowerCase()
                        .includes(query_1) ||
                    ((_a = res.guestInfo.company) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(query_1))
                : walkinTranslation.toLowerCase().includes(query_1)) ||
                (res.tableIds && res.tableIds.some(function (tableId) { var _a; return ((_a = tableNames_1[tableId]) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === query_1; }));
        });
    }
    else {
        return reservations;
    }
};
var filterReservationsOnReservationsView = function (reservations, search, walkinTranslation) {
    if (search) {
        var query_2 = search === null || search === void 0 ? void 0 : search.toLowerCase();
        var phoneNumberQuery_1 = (query_2 === null || query_2 === void 0 ? void 0 : query_2.startsWith('0')) ? query_2 === null || query_2 === void 0 ? void 0 : query_2.replace('0', '') : query_2;
        return reservations.filter(function (res) {
            var _a, _b;
            return res.guestInfo
                ? StringUtils.combineStrings([res.guestInfo.lastName, res.guestInfo.firstName])
                    .toLowerCase()
                    .includes(query_2) ||
                    StringUtils.combineStrings([res.guestInfo.firstName, res.guestInfo.lastName])
                        .toLowerCase()
                        .includes(query_2) ||
                    ((_a = res.guestInfo.company) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(query_2)) ||
                    ((_b = res.guestInfo.phoneNumbers) === null || _b === void 0 ? void 0 : _b.some(function (phoneNumber) { return phoneNumber.includes(phoneNumberQuery_1); }))
                : walkinTranslation.toLowerCase().includes(query_2);
        });
    }
    else {
        return reservations;
    }
};
var getPlatformName = function (reservation) {
    switch (reservation.platformName) {
        case PlatformName.GOOGLE:
            return 'Google';
        case PlatformName.LOCALCH:
            return 'local.ch';
        default:
            return 'MyLOCALINA';
    }
};
var localCHUrl = 'https://www.local.ch';
export var ReservationUtils = {
    isPast: isPast,
    getTimeSlotForReservationTime: getTimeSlotForReservationTime,
    compareReservationsByTime: compareReservationsByTime,
    groupReservationsForDateTime: groupReservationsForDateTime,
    getReservationParticipants: getReservationParticipants,
    isReservationCancelled: isReservationCancelled,
    isReservationStatusCancelled: isReservationStatusCancelled,
    areReservationAndTableInSameArea: areReservationAndTableInSameArea,
    areReservationAndTableInSameShift: areReservationAndTableInSameShift,
    sumTotalParticipantsOfReservations: sumTotalParticipantsOfReservations,
    isTableAreaSubareaOfReservationVirtualArea: isTableAreaSubareaOfReservationVirtualArea,
    filterReservationsOnReservationList: filterReservationsOnReservationList,
    filterReservationsOnReservationsView: filterReservationsOnReservationsView,
    getPlatformName: getPlatformName,
    localCHUrl: localCHUrl,
};
