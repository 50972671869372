import React from 'react';
import { useTranslation } from 'react-i18next';
import { IRestaurant } from '@localina/core';
import { Drawer } from '../Drawer';
import { RestaurantLogoIntegration } from './Integrations';

interface IProps {
    isOpen: boolean;
    restaurant: IRestaurant;
    hasError: boolean;
    onClose: () => void;
    onSave: (logo?: File) => void;
}

export const RestaurantLogoIntegrationView: React.FC<IProps> = (props) => {
    const { t } = useTranslation();

    const [logo, setLogo] = React.useState<File | undefined>(undefined);
    const [actionRequested, setActionRequested] = React.useState(false);

    const handleSave = () => {
        setActionRequested(true);
        props.onSave(logo);
    };

    return (
        <Drawer
            className="restaurantlogo-view"
            open={props.isOpen}
            title={t('integrations.items.logo.title')}
            disabled={logo?.size === 0}
            error={{
                isVisible: actionRequested && props.hasError,
                message: t('integrations.items.logo.error'),
            }}
            onClose={props.onClose}
            onSave={handleSave}
        >
            <RestaurantLogoIntegration restaurant={props.restaurant} onChange={setLogo} />
        </Drawer>
    );
};
