import { List, ListItem } from '@localina/core';
import { RestaurantLogoIcon, RestaurantMenuIcon } from '@localina/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDeleteRestaurantLogoMutation, useSaveRestaurantLogoMutation } from '../../api/queries/restaurantLogo';
import { useRestaurant } from '../../api/queries/restaurants';
import { Page, RestaurantLogoIntegrationView, WidgetIntegrationView } from '../../components';

enum IntegrationItem {
    WIDGET = 'widget',
    LOGO = 'logo',
    // QRCODE = 'qrcode',
}

const Integrations: React.FC = () => {
    const { t } = useTranslation();

    const [openDrawer, setOpenDrawer] = React.useState('');

    const restaurantQuery = useRestaurant();
    const saveRestaurantLogoMutation = useSaveRestaurantLogoMutation();
    const deleteRestaurantLogoMutation = useDeleteRestaurantLogoMutation();

    const handleItemClick = (item: IntegrationItem) => () => {
        setOpenDrawer(item);
    };

    const handleCloseDrawer = () => {
        setOpenDrawer('');
    };

    const handleSaveLogo = (logo?: File) => {
        if (restaurantQuery.data) {
            if (logo) {
                saveRestaurantLogoMutation.mutate({ logo }, { onSuccess: handleCloseDrawer });
            } else {
                deleteRestaurantLogoMutation.mutate(undefined, { onSuccess: handleCloseDrawer });
            }
        }
    };

    const getIconForIntegrationItem = (item: IntegrationItem) => {
        switch (item) {
            case IntegrationItem.WIDGET:
                return <RestaurantMenuIcon />;
            case IntegrationItem.LOGO:
                return <RestaurantLogoIcon />;
            //case IntegrationItem.QRCODE:
            //    return <RestaurantQrCodeIcon />;
        }
    };

    return (
        <Page name="integrations" title={t('integrations.title')} isLoading={restaurantQuery.isInitialLoading}>
            <List>
                {[
                    IntegrationItem.WIDGET,
                    IntegrationItem.LOGO,
                    //IntegrationItem.QRCODE,
                ].map((item) => (
                    <ListItem
                        key={item}
                        leftIcon={getIconForIntegrationItem(item)}
                        title={t(`integrations.items.${item}.title`)}
                        subtitle={t(`integrations.items.${item}.subtitle`)}
                        onClick={handleItemClick(item)}
                    />
                ))}
            </List>
            {restaurantQuery.data && (
                <>
                    <WidgetIntegrationView
                        isOpen={openDrawer === IntegrationItem.WIDGET}
                        onClose={handleCloseDrawer}
                        restaurant={restaurantQuery.data}
                    />
                    <RestaurantLogoIntegrationView
                        isOpen={openDrawer === IntegrationItem.LOGO}
                        onClose={handleCloseDrawer}
                        restaurant={restaurantQuery.data}
                        hasError={saveRestaurantLogoMutation.isError || deleteRestaurantLogoMutation.isError} // TODO mutation.isError
                        onSave={handleSaveLogo}
                    />
                </>
            )}
        </Page>
    );
};

export default Integrations;
