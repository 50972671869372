import { ILocalizedError, IPlatform } from '@localina/core';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { LocalinaApiContext } from '../../../index';
import { queryKeys } from './query-keys';

const useActivePlatforms = (options?: UseQueryOptions<IPlatform[], ILocalizedError>) => {
    return useQuery({
        queryFn: LocalinaApiContext.serviceApi.getActivePlatforms,
        queryKey: queryKeys.platforms.active(),
        ...options,
    });
};

export { useActivePlatforms };
