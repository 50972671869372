import { DATE_FORMAT, IReservation, ListItem, ReservationStatus, StringUtils, TIME_FORMAT } from '@localina/core';
import { FoodBankIcon } from '@localina/icons';
import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { GuestUtils } from '../../utils';

interface IProps {
    reservation: IReservation;
    onClick: (reservation: IReservation) => void;
}

export const ReservationListItem: React.FC<IProps> = (props) => {
    const { t } = useTranslation();

    const { guestInfo } = props.reservation;
    const title = GuestUtils.formatGuestName(guestInfo, t('reservations.view.fields.walkin'));
    const datetime = DateTime.fromISO(props.reservation.reservationDateTime).toLocal();
    const subtitle = StringUtils.combineStrings(
        [
            StringUtils.combineStrings([datetime.toFormat(DATE_FORMAT), datetime.toFormat(TIME_FORMAT)]),
            `${t('notifications.view.fields.participants', props.reservation)}`,
        ],
        ', ',
    );

    const handleClick = () => {
        props.onClick(props.reservation);
    };

    const isReservationUpdated = (): boolean => {
        if (props.reservation.auditInfo) {
            const created = DateTime.fromISO(props.reservation.auditInfo.createdAt);
            const updated = DateTime.fromISO(props.reservation.auditInfo.updatedAt);
            return updated.diff(created, 'seconds').seconds >= 2;
        }
        return false;
    };

    const isReservationCancelledByGuest: boolean = props.reservation.status === ReservationStatus.GUEST_CANCELLED;

    const className = StringUtils.combineStrings([
        isReservationUpdated() && 'notification-updated-item',
        isReservationCancelledByGuest && 'guest-cancelled',
    ]);

    return (
        <ListItem
            classNames={className}
            title={title}
            subtitle={subtitle}
            leftIcon={<FoodBankIcon />}
            onClick={handleClick}
        />
    );
};
