var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { InfoIcon } from '@localina/icons';
import { FormControlLabel, Switch as MuiSwitch } from '@mui/material';
import { StringUtils } from '../../utils';
import { Label } from '../Label';
import { Tooltip } from '../Tooltip';
export var Switch = function (props) {
    var handleChange = function (event, checked) {
        if (props.onChange) {
            props.onChange(checked);
        }
    };
    var hasIcon = props.icon && typeof props.icon !== 'boolean';
    var useIcon = props.icon && typeof props.icon === 'boolean';
    var hasLabel = props.label !== undefined;
    var classNames = StringUtils.combineStrings([
        'localina-switch',
        hasLabel && 'has-label',
        !!hasIcon && 'has-icon',
        !!useIcon && 'use-icon',
    ]);
    var control = (_jsx(MuiSwitch, { checked: Boolean(props.checked || props.value), disabled: props.disabled, disableRipple: true, onChange: handleChange, onBlur: props.onBlur }));
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: classNames }, { children: [props.tooltip && (_jsx(Tooltip, __assign({ content: props.tooltip, disablePortal: true }, { children: _jsx(InfoIcon, {}) }))), props.icon, props.label ? (_jsx(FormControlLabel, { control: control, disabled: props.disabled, label: props.label, labelPlacement: "start" })) : (control)] })), Boolean(props.helperText) && (_jsx(_Fragment, { children: typeof props.helperText === 'string' ? (_jsx("div", __assign({ className: "helper-text" }, { children: _jsx(Label, { type: "info", value: props.helperText, error: props.error }) }))) : (props.helperText) }))] }));
};
