import { IAccount, IFeature } from '@localina/core';

const getFeatures = (account?: IAccount, restaurantId?: string) =>
    account?.restaurants.find((restaurant) => restaurant.id === restaurantId)?.features || [];

const getSubscription = (account?: IAccount, restaurantId?: string) =>
    account?.restaurants.find((restaurant) => restaurant.id === restaurantId)?.subscription || 'free';

const hasFeature = (feature: IFeature, account?: IAccount, restaurantId?: string) => {
    const features = getFeatures(account, restaurantId);
    return features.includes(feature);
};

export const AccountUtils = {
    getFeatures,
    hasFeature,
    getSubscription,
};
