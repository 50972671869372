var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { DateTime } from 'luxon';
import { ReservationStatus, ReservationType } from '../enums';
export var createEmptyMessage = function (type) { return ({
    id: '',
    name: '',
    type: type,
    displayTexts: [],
}); };
export var createEmptyMessageDisplayText = function (language) { return ({
    language: language,
    text: '',
}); };
export var createEmptyReservation = function (datetime) { return ({
    id: '',
    reservationDateTime: datetime || '',
    confirmedAt: '',
    participants: 0,
    tableIds: [],
    reservationType: ReservationType.MANUAL,
    contactTracingSubmitted: false,
    status: ReservationStatus.APPROVAL_PENDING,
    comment: '',
    guestInfo: {
        id: '',
        firstName: '',
        lastName: '',
        email: '',
        phoneNumbers: [],
        salutation: undefined,
        allergies: '',
        restaurantComment: '',
        subscribeToNewsletter: false,
        company: '',
        guestStatus: null,
    },
    read: false,
    areaIds: [],
    documents: [],
    shiftId: '',
    staffTagId: null,
    occupancyTime: 0,
}); };
export var createEmptyWalkinReservation = function () {
    return __assign(__assign({}, createEmptyReservation(DateTime.utc().toISO())), { guestInfo: undefined, status: ReservationStatus.FULFILLED });
};
export var createEmptyShift = function (type) {
    if (type === 'recurrent') {
        return {
            id: '',
            occurrence: [],
            name: {
                en: '',
                de: '',
                it: '',
                fr: '',
            },
            from: '',
            to: '',
            shiftType: 'recurrent',
            expectedOccupancyTime: 5400,
            minReservationNotice: 3600,
            maxPersonsPerReservation: 10,
            slotInterval: 900,
            enabled: true,
            shiftAreas: [],
            blockedSlots: [],
            restrictReservationsWhenActive: false,
        };
    }
    return {
        id: '',
        fromDate: '',
        toDate: '',
        name: {
            en: '',
            de: '',
            it: '',
            fr: '',
        },
        from: '',
        to: '',
        shiftType: 'soh',
        expectedOccupancyTime: 5400,
        minReservationNotice: 3600,
        maxPersonsPerReservation: 10,
        slotInterval: 900,
        enabled: true,
        shiftAreas: [],
        blockedSlots: [],
        restrictReservationsWhenActive: false,
        dates: [],
    };
};
export var createEmptyClosingDay = function () { return ({
    id: '',
    name: '',
    from: '',
    to: '',
    isRecurrent: false,
    areas: [],
}); };
export var createEmptyRestaurant = function () { return ({
    id: '',
    info: {
        city: '',
        email: '',
        name: '',
        phoneNumber: '',
        postalCode: '',
        street: '',
        streetNumber: '',
        language: 'de',
    },
    configuration: {
        autoApproveEnabled: true,
        closingDays: [],
        defaultAreaId: '',
        platforms: [],
        shifts: [],
        cancelReservationNotice: 24,
        tracingEnabled: true,
        virtualAreas: [],
        receiveReservationsEmails: false,
        orderings: {},
        staffTags: [],
        staffTagMandatory: false,
        staffTagEnabled: false,
    },
}); };
export var createEmptySpecialOpeningHour = function () { return ({
    id: '',
    name: {
        en: '',
        de: '',
        it: '',
        fr: '',
    },
    fromDate: '',
    toDate: '',
    from: '',
    to: '',
    expectedOccupancyTime: 5400,
    minReservationNotice: 3600,
    slotInterval: 900,
    enabled: true,
    pacing: 10,
    shiftType: 'soh',
    maxPersonsPerReservation: 10,
    shiftAreas: [],
    blockedSlots: [],
    restrictReservationsWhenActive: false,
    dates: [],
}); };
export var createEmptyUser = function () { return ({
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    rolesMapping: [],
}); };
export var createEmptyUserRoleMapping = function (restaurantId) { return ({
    restaurantId: restaurantId,
    roles: [],
}); };
export var createEmptySupportUser = function () { return ({
    id: '',
    firstName: '',
    lastName: '',
    email: '',
}); };
