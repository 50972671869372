import {
    ButtonGroup,
    DATE_FORMAT,
    DateField,
    ISpeedDialActionProps,
    RESERVATION_LIST_FILTERS_COLLAPSE_STATE,
    ReservationUtils,
    RestaurantUtils,
    SERVER_DATE_FORMAT,
    SpeedDial,
    StringUtils,
    Switch,
    TextField,
} from '@localina/core';
import { BookNewIcon, WalkinIcon } from '@localina/icons';
import { Portal } from '@mui/base';
import { Chip, Collapse } from '@mui/material';
import { DateTime } from 'luxon';
import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import SwipeableViews from 'react-swipeable-views';
import { useRestaurant } from '../../api/queries/restaurants';
import { Page } from '../../components';
import CollapseButton from '../../components/CollapseButton/CollapseButton';
import { useLocalStorageCollapseState } from '../../components/CollapseButton/localStorageCollapseState';
import { ReservationsInfoMessages } from '../../components/ReservationsInfoMessages';
import { filterReservations, useReservationActions, useReservationsList } from '../../utils/ReservationsUtils';
import { ReservationGroup } from './ReservationGroup';

const ReservationList: React.FC = () => {
    const { t } = useTranslation();

    const restaurantQuery = useRestaurant();
    const [filtersCollapsed, setFiltersCollapsed] = useLocalStorageCollapseState(
        RESERVATION_LIST_FILTERS_COLLAPSE_STATE,
    );
    const { openCreateReservationModal, openEditReservationModal, openWalkinReservationModal } =
        useReservationActions();

    const { reservationsQuery, filters, setFilters, filterOptions, onBlurSearchField, dateTime, swipeIndex } =
        useReservationsList(true);

    const reservationsData = useMemo(() => {
        if (reservationsQuery.data) {
            return filterReservations(
                reservationsQuery.data.reservations,
                restaurantQuery.data?.configuration,
                {
                    shiftId: filters.shiftId || '',
                    showCancelled: filters.cancelled === 'true',
                    searchQuery: filters.search || '',
                    areaId: filters.areaId || '',
                },
                t('reservations.view.fields.walkin'),
            );
        } else {
            return undefined;
        }
    }, [
        reservationsQuery.data,
        filters.cancelled,
        filters.areaId,
        filters.search,
        filters.shiftId,
        restaurantQuery.data?.configuration,
    ]);

    const createActions: ISpeedDialActionProps[] = [
        {
            label: t('reservations.actions.createReservation'),
            icon: <BookNewIcon />,
            onClick: openCreateReservationModal,
        },
        {
            label: t('reservations.actions.createWalkin'),
            icon: <WalkinIcon />,
            onClick: openWalkinReservationModal,
        },
    ];

    const localinaPageRef = useRef<undefined | Element>(document.getElementsByClassName('localina-page')?.[0]);

    const isLoading = reservationsQuery.isInitialLoading;

    return (
        <Page name="reservation-list" isLoading={isLoading}>
            <div className="reservation-list-header">
                <div className="reservation-list-header__content">
                    <div className="reservation-list-date">
                        <DateField
                            name="date"
                            label={t('reservations.fields.date')}
                            value={dateTime.toFormat(DATE_FORMAT)}
                            // todo: check the following and see if can be improved
                            shouldHighlightDate={(calendarDate: DateTime) =>
                                RestaurantUtils.isClosedAndHasNoShiftsOnDate(
                                    calendarDate,
                                    restaurantQuery.data?.configuration,
                                )
                                    ? 'restaurant-closed-day'
                                    : false
                            }
                            onChange={(value) => {
                                setFilters({
                                    date: DateTime.fromFormat(value, DATE_FORMAT).toFormat(SERVER_DATE_FORMAT),
                                });
                            }}
                            withChevrons
                            withTodayButton
                        />
                    </div>
                </div>
                <Collapse in={!filtersCollapsed}>
                    {Boolean(filterOptions.shifts.length > 1) && (
                        <div className="button-group-style">
                            <ButtonGroup
                                value={filters.shiftId || filterOptions.constants.defaultShift.value}
                                options={filterOptions.shifts}
                                onChange={(shiftId) => {
                                    setFilters({ shiftId });
                                }}
                            />
                        </div>
                    )}
                    {Boolean(filterOptions.areas.length > 1) && (
                        <div className="button-group-style">
                            <ButtonGroup
                                value={filters.areaId || filterOptions.constants.defaultArea.value}
                                options={filterOptions.areas}
                                onChange={(areaId) => {
                                    setFilters({ areaId });
                                }}
                            />
                        </div>
                    )}
                    {Boolean(filterOptions.areas.length > 1 || filterOptions.shifts.length > 1) && (
                        <>
                            <div className="reservation-list-header__text-filter">
                                <TextField
                                    name={'reservations-filter'}
                                    label={t('reservations.searchReservations')}
                                    value={filters.search || ''}
                                    onChange={(search) => {
                                        setFilters({ search });
                                    }}
                                    allowClear
                                    onBlur={onBlurSearchField}
                                />
                            </div>
                            <div className="reservation-list-header__switch">
                                <Switch
                                    label={t('reservations.showCancelled')}
                                    onChange={(cancelled) => {
                                        setFilters({ cancelled: cancelled ? 'true' : 'false' });
                                    }}
                                    checked={filters.cancelled === 'true'}
                                />
                            </div>
                        </>
                    )}
                </Collapse>
                <CollapseButton collapsed={filtersCollapsed} setCollapsed={setFiltersCollapsed} />
            </div>
            <div className="reservation-list-body">
                <SwipeableViews
                    disabled
                    className="swiper"
                    disableLazyLoading
                    index={swipeIndex}
                    onChangeIndex={(index) => {
                        const areaId = filterOptions.areas[index]?.value;
                        if (restaurantQuery.data) {
                            setFilters({ areaId });
                        }
                    }}
                >
                    {filterOptions.areas.map((areaTab) => {
                        return (
                            <div key={areaTab.value} className="swiper-content">
                                {restaurantQuery.data && reservationsQuery.isSuccess && (
                                    <ReservationsInfoMessages
                                        dateFormatted={dateTime.toFormat(DATE_FORMAT)}
                                        configuration={restaurantQuery.data.configuration}
                                        noReservations={reservationsQuery.data.reservations.every(
                                            ReservationUtils.isReservationCancelled,
                                        )}
                                        noFilteredReservations={!reservationsData?.length}
                                        className={'empty-case'}
                                        clearFilters={() => {
                                            setFilters({
                                                areaId: '',
                                                shiftId: '',
                                                search: '',
                                            });
                                        }}
                                    />
                                )}
                                {Boolean(reservationsData?.some((res) => res.unassigned)) && (
                                    <Chip
                                        className="unassigned-reservations-info"
                                        label={t('reservations.fields.unassignedReservationsInfoMessage')}
                                        variant="outlined"
                                    />
                                )}
                                <ReservationGroup
                                    onReservationClick={openEditReservationModal}
                                    reservations={reservationsData || []}
                                    restaurant={restaurantQuery.data}
                                />
                            </div>
                        );
                    })}
                </SwipeableViews>
            </div>
            <Portal disablePortal container={localinaPageRef.current}>
                <div className={StringUtils.combineStrings(['left-side-reservation-footer'])}>
                    <SpeedDial label="create" actions={createActions} />
                </div>
            </Portal>
        </Page>
    );
};

export default ReservationList;
