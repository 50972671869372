import {
    DATE_FORMAT,
    DateTimeUtils,
    IReservation,
    IReservationWithUnassignedStatus,
    IRestaurantArea,
    IRestaurantConfiguration,
    IRestaurantShift,
    ReservationUtils,
    RestaurantUtils,
    SERVER_DATE_FORMAT,
    SERVER_DATE_TIME_FORMAT,
    TLanguageKey,
    useConfirmContext,
    useUrlParams,
} from '@localina/core';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useHaveAccountFeatures } from '../api/queries/account';
import { useReservationsWithPrefetchingAdjacentDates } from '../api/queries/reservations';
import { useAreas, useVirtualAreas } from '../api/queries/restaurantAreas';
import { useRestaurant } from '../api/queries/restaurants';
import { useReservationFormContext } from '../contexts/ReservationsContext';
import { ReservationDetailsType } from '../enums';
import { IRestaurantSelectedArea } from '../interfaces/store/restaurant/IRestaurantSelectedArea';
import {
    IRestaurantShiftTablePlansPreview,
    ITablePlanPreview,
} from '../interfaces/store/restaurant/IRestaurantShiftTablePlansPreview';
import { IReservationForm } from '../schemas/interfaces';
import { sortItemsBySortedIdsArray } from './SortableItemsUtils';

interface IAreaFilterTab {
    value: string;
    label: string;
}

const DEFAULT_RESERVATION_AREA_FILTER_TAB_ID = 'all';
const DEFAULT_RESERVATION_SHIFT_FILTER_TAB_ID = 'all';
const UNASSIGNED_RESERVATION_SHIFT_FILTER_TAB_ID = 'unassigned';
export const useReservationsAreaFilterTabs = (
    date: string,
    selectedShiftId?: string,
): [IAreaFilterTab[], IAreaFilterTab] => {
    const { t } = useTranslation();
    const defaultReservationAreaFilterTab = {
        label: t('reservations.fields.allAreas'),
        value: DEFAULT_RESERVATION_AREA_FILTER_TAB_ID,
    };

    const restaurantQuery = useRestaurant();

    const shiftsMapping = restaurantQuery.data
        ? RestaurantUtils.getShiftsForDate(DateTime.fromFormat(date, DATE_FORMAT), restaurantQuery.data.configuration)
        : [];

    if (!restaurantQuery.data || !shiftsMapping.length) {
        return [[defaultReservationAreaFilterTab], defaultReservationAreaFilterTab];
    }

    const shiftAreas = selectedShiftId
        ? shiftsMapping.find(({ id }) => id === selectedShiftId)?.areas || []
        : shiftsMapping.flatMap(({ areas }) => areas);

    const shiftAreasIds = shiftAreas.map(({ id }) => id);

    const virtualAreas = (restaurantQuery.data.configuration?.virtualAreas || [])
        .filter(({ areas }) =>
            areas.some(
                (area) =>
                    shiftAreasIds.includes(area.id) &&
                    (selectedShiftId
                        ? area.areaShifts.some(
                              ({ shiftId, directlyBookable }) => selectedShiftId === shiftId && !directlyBookable,
                          )
                        : area.areaShifts.some(({ directlyBookable }) => !directlyBookable)),
            ),
        )
        .map(({ name: label, id: value }) => ({ label, value }));

    const areas =
        restaurantQuery.data.configuration?.virtualAreas
            ?.flatMap(({ areas: vaAreas }) => vaAreas)
            .filter(({ id }) => shiftAreasIds.includes(id))
            .map(({ name: label, id: value }) => ({ label, value })) || [];

    sortItemsBySortedIdsArray(areas, restaurantQuery.data?.configuration.orderings.areas || [], 'value');

    const reservationsAreaFilterTabs = [defaultReservationAreaFilterTab, ...areas, ...virtualAreas];

    return [reservationsAreaFilterTabs, defaultReservationAreaFilterTab];
};

export const useReservationFiltersFromUrlParams = () => {
    const [params] = useUrlParams();
    const shiftIdParam = params.get('shiftId');
    const areaIdParam = params.get('areaId');

    const restaurantQuery = useRestaurant();
    const virtualAreasQuery = useVirtualAreas();

    let selectedArea: IRestaurantSelectedArea | undefined;
    const selectedVirtualArea = virtualAreasQuery.data?.virtualAreas.find(({ id }) => id === areaIdParam);
    if (selectedVirtualArea) {
        selectedArea = { area: selectedVirtualArea, type: 'virtual' };
    } else {
        const containingVirtualArea = virtualAreasQuery.data?.virtualAreas.find(({ areas }) =>
            areas.some(({ id }) => id === areaIdParam),
        );
        if (containingVirtualArea) {
            const area = containingVirtualArea.areas.find(({ id }) => id === areaIdParam);
            if (area) {
                selectedArea = { area, type: 'area' };
            }
        }
    }

    const selectedShift = restaurantQuery.data?.configuration.shifts.find(({ id }) => id === shiftIdParam);

    return [selectedShift, selectedArea] as const;
};

export const useTablePlansOfShift = (shift?: IRestaurantShift): IRestaurantShiftTablePlansPreview[] => {
    const restaurantQuery = useRestaurant();
    const [params] = useUrlParams();
    const areasQuery = useAreas();

    const dateParam = params.get('date');

    const date = useMemo(() => {
        return dateParam && DateTime.fromFormat(dateParam, SERVER_DATE_FORMAT).isValid
            ? DateTime.fromFormat(dateParam, SERVER_DATE_FORMAT)
            : DateTime.now();
    }, [dateParam]);

    const areas = areasQuery.data?.areas;

    return useMemo(() => {
        if (!restaurantQuery.data) {
            return [];
        }
        if (areas && shift) {
            return [
                {
                    shiftId: shift.id,
                    shiftName: shift.name,
                    tablePlans: filterTablePlansForShift(shift.id, areas),
                },
            ].filter((tbo) => Boolean(tbo.tablePlans.length));
        } else {
            const shiftOptions = RestaurantUtils.getShiftsForDate(date, restaurantQuery.data.configuration);

            const tablePlanOptions = shiftOptions.map((shiftOption) => ({
                shiftId: shiftOption.id,
                shiftName: shiftOption.shift.name,
                tablePlans: filterTablePlansForShift(shiftOption.id, areas || []),
            }));

            return tablePlanOptions.filter((tbo) => Boolean(tbo.tablePlans.length));
        }
    }, [areas, shift, date, restaurantQuery.data]);
};

const filterTablePlansForShift = (shiftId: string, areas: IRestaurantArea[]) =>
    areas.reduce((acc, area) => {
        const tablePlanId = area.areaShifts.find((as) => as.shiftId === shiftId)?.tablePlanId || '';
        if (tablePlanId) {
            const tablePlan = area.tablePlans.find((tp) => tp.id === tablePlanId);
            if (tablePlan) {
                acc.push({
                    id: tablePlan.id,
                    areaId: area.id,
                    name: tablePlan.name,
                    areaName: area.name,
                });
            }
        }
        return acc;
    }, [] as ITablePlanPreview[]);

interface IReservationsFilters {
    shiftId: string;
    areaId?: string;
    searchQuery?: string;
    showCancelled?: boolean;
}

const filterReservations = (
    reservations: IReservation[],
    configuration?: IRestaurantConfiguration,
    filters?: IReservationsFilters,
    walkinTranslation = 'walk-in',
): IReservationWithUnassignedStatus[] => {
    if (!filters || !configuration) {
        return reservations;
    } else {
        const areaId = filters.areaId || DEFAULT_RESERVATION_AREA_FILTER_TAB_ID;
        const shiftId = filters.shiftId || DEFAULT_RESERVATION_SHIFT_FILTER_TAB_ID;
        const showCancelled = filters.showCancelled ?? true;
        const filteredByCancelledStatus = reservations.filter(
            (reservation) => showCancelled || !ReservationUtils.isReservationCancelled(reservation),
        );
        const filteredBySearchQuery = ReservationUtils.filterReservationsOnReservationsView(
            filteredByCancelledStatus,
            filters.searchQuery || '',
            walkinTranslation,
        ).sort((a, b) =>
            DateTime.fromISO(a.reservationDateTime, { zone: 'utc' }) <
            DateTime.fromISO(b.reservationDateTime, { zone: 'utc' })
                ? -1
                : 1,
        );

        const filteredByArea = filteredBySearchQuery.filter(
            (reservation) => areaId === DEFAULT_RESERVATION_AREA_FILTER_TAB_ID || reservation.areaIds.includes(areaId),
        );

        const datesWithAvailableShiftsMap = new Map<string, string[]>();
        const shiftsWithAvailableAreasMap = new Map<string, string[]>();
        let dateTime, dateServerFormatted, shift;

        filteredByArea.forEach((res) => {
            dateTime = DateTime.fromISO(res.reservationDateTime).startOf('day');
            dateServerFormatted = dateTime.toFormat(SERVER_DATE_FORMAT);

            if (!datesWithAvailableShiftsMap.has(dateServerFormatted)) {
                datesWithAvailableShiftsMap.set(
                    dateServerFormatted,
                    RestaurantUtils.getShiftsForDate(dateTime, configuration).map((sh) => sh.id),
                );
            }
            if (!shiftsWithAvailableAreasMap.has(res.shiftId)) {
                shift = configuration.shifts.find((s) => s.id === res.shiftId);

                shiftsWithAvailableAreasMap.set(
                    res.shiftId,
                    shift
                        ? [
                              ...RestaurantUtils.getAreaShiftList(shift, configuration).map(
                                  (shiftArea) => shiftArea.areaId,
                              ),
                              ...RestaurantUtils.getVirtualAreaShiftList(shift, configuration).map(
                                  (shiftArea) => shiftArea.areaId,
                              ),
                          ]
                        : [],
                );
            }
        });

        const areaClosingDays = new Map<string, string[]>();
        configuration.virtualAreas.forEach(({ areas }) =>
            areas.forEach((area) => {
                areaClosingDays.set(area.id, area.areaClosingDays);
            }),
        );

        let validReservationShiftOnDate: boolean,
            areaIdsOfSelectedShift: string[] | undefined,
            validShiftAreaCombination: boolean,
            reservationDateTime: DateTime,
            closingDay,
            closingDayFrom,
            closingDayTo;

        const valid: IReservationWithUnassignedStatus[] = [];
        const invalid: IReservationWithUnassignedStatus[] = [];

        filteredByArea.forEach((reservation) => {
            reservationDateTime = DateTime.fromISO(reservation.reservationDateTime);
            dateServerFormatted = reservationDateTime.toFormat(SERVER_DATE_FORMAT);
            // check if reservation shift is available on the date
            validReservationShiftOnDate = Boolean(
                datesWithAvailableShiftsMap.get(dateServerFormatted)?.includes(reservation.shiftId),
            );
            if (validReservationShiftOnDate) {
                areaIdsOfSelectedShift = shiftsWithAvailableAreasMap.get(reservation.shiftId);
                validShiftAreaCombination = reservation.areaIds.every(
                    (id) =>
                        // check if reservation area is enabled in shift
                        areaIdsOfSelectedShift?.includes(id) &&
                        // check if reservation area is not closed with closing day in that time
                        Boolean(
                            (areaClosingDays.get(id) || []).every((closingDayId) => {
                                closingDay = configuration.closingDays.find((cd) => cd.id === closingDayId);
                                if (closingDay) {
                                    closingDayFrom = DateTime.fromFormat(
                                        `${closingDay.from} ${closingDay.fromTime || '00:00'}`,
                                        SERVER_DATE_TIME_FORMAT,
                                    );
                                    closingDayTo = DateTime.fromFormat(
                                        `${closingDay.to} ${closingDay.toTime || '23:59'}`,
                                        SERVER_DATE_TIME_FORMAT,
                                    );
                                    return !DateTimeUtils.isDateTimeInRangeOfDateTimes(
                                        reservationDateTime,
                                        closingDayFrom,
                                        closingDayTo,
                                    );
                                } else {
                                    // if cannot find closing day with that ID ->
                                    // probably didn't properly removed in area.areaClosingDays array and can be ignored
                                    return true;
                                }
                            }),
                        ),
                );
                if (validShiftAreaCombination) {
                    valid.push({ ...reservation, unassigned: false });
                } else {
                    invalid.push({ ...reservation, unassigned: true });
                }
            } else {
                invalid.push({ ...reservation, unassigned: true });
            }
        });

        if (shiftId === UNASSIGNED_RESERVATION_SHIFT_FILTER_TAB_ID) {
            return invalid;
        } else if (shiftId === DEFAULT_RESERVATION_SHIFT_FILTER_TAB_ID) {
            return [...invalid, ...valid];
        } else {
            return valid.filter((reservation) => reservation.shiftId === shiftId);
        }
    }
};
const useFilterReservations = (filters?: IReservationsFilters) => {
    const { t } = useTranslation();
    const restaurantQuery = useRestaurant();
    return useCallback(
        (reservations: IReservation[]) =>
            filterReservations(
                reservations,
                restaurantQuery.data?.configuration,
                filters,
                t('reservations.view.fields.walkin'),
            ),
        [filters?.showCancelled, filters?.searchQuery, filters?.shiftId, filters?.areaId],
    );
};

const POLLING_INTERVAL_IN_MS = 15 * 1000;

interface ILabelAndValue {
    label: string;
    value: string;
}

interface ICommonProperties {
    rawReservations?: IReservation[];
    reservationsQuery: ReturnType<typeof useReservationsWithPrefetchingAdjacentDates>;
    filters: IReservationUrlParams;
    setFilters: (newFilters: Partial<IReservationUrlParams>) => void;
    filterOptions: {
        shifts: ILabelAndValue[];
        areas: ILabelAndValue[];
        constants: {
            defaultShift: ILabelAndValue;
            unassignedShift: ILabelAndValue;
            defaultArea: ILabelAndValue;
        };
    };
    onBlurSearchField: () => void;
    dateTime: DateTime;
    swipeIndex: number;
    noReservations: boolean;
}

interface IReservationUrlParams {
    shiftId: string;
    areaId: string;
    date: string;
    search: string;
    cancelled: 'true' | 'false';
}

type Nullable<T> = {
    [P in keyof T]: T[P] | null;
};

function useReservationsList(withTablePlan = false): ICommonProperties {
    const { t, i18n } = useTranslation();
    const [params, setParams] = useSearchParams();

    const restaurantQuery = useRestaurant();
    const getFiltersFromUrlParams = (): IReservationUrlParams => ({
        shiftId: params.get('shiftId') || '',
        areaId: params.get('areaId') || '',
        date: params.get('date') || '',
        search: params.get('search') || '',
        cancelled: params.get('cancelled')?.toLowerCase() === 'true' ? 'true' : 'false',
    });

    const filters = useMemo(getFiltersFromUrlParams, [params]);

    const currentDate = useMemo(
        () =>
            filters.date && DateTime.fromFormat(filters.date, SERVER_DATE_FORMAT).isValid
                ? DateTime.fromFormat(filters.date, SERVER_DATE_FORMAT)
                : DateTime.local(),
        [filters.date],
    );

    const dateFormatted = currentDate.toFormat(DATE_FORMAT);
    const dateServerFormatted = currentDate.toFormat(SERVER_DATE_FORMAT);

    const defaultReservationShiftFilterTab = {
        label: t('reservations.fields.time'),
        value: DEFAULT_RESERVATION_SHIFT_FILTER_TAB_ID,
    };

    const unassignedShiftTab = {
        label: t('reservations.fields.unassignedReservations'),
        value: UNASSIGNED_RESERVATION_SHIFT_FILTER_TAB_ID,
        className: 'unassigned-shifts',
    };

    const [areaTabs, defaultReservationAreaFilterTab] = useReservationsAreaFilterTabs(
        dateFormatted,
        filters.shiftId && filters.shiftId !== defaultReservationShiftFilterTab.value ? filters.shiftId : undefined,
    );

    const reservationsQuery = useReservationsWithPrefetchingAdjacentDates(dateServerFormatted, {
        refetchInterval: POLLING_INTERVAL_IN_MS,
    });

    let shiftsMapping = restaurantQuery.data
        ? RestaurantUtils.getShiftsForDate(
              DateTime.fromFormat(dateFormatted, DATE_FORMAT),
              restaurantQuery.data?.configuration,
          )
        : [];

    const displayUnassignedShift = useMemo(() => {
        return Boolean(
            filterReservations(
                reservationsQuery.data?.reservations || [],
                restaurantQuery.data?.configuration,
                { shiftId: unassignedShiftTab.value },
                t('reservations.view.fields.walkin'),
            ).length,
        );
    }, [restaurantQuery.data?.configuration, reservationsQuery.data]);

    const setFilters = (newFilters: Partial<IReservationUrlParams>) => {
        setParams((prevState) => ({
            ...Object.fromEntries(prevState),
            ...newFilters,
        }));
    };

    const [canAccessAreas] = useHaveAccountFeatures(['areas']);
    //was not implemented in container with tableplan
    if (!withTablePlan && !canAccessAreas) {
        const defaultArea = restaurantQuery.data
            ? RestaurantUtils.getDefaultArea(restaurantQuery.data?.configuration)
            : undefined;
        if (defaultArea) {
            shiftsMapping = shiftsMapping.filter((tab) => {
                return defaultArea.areaShifts.find((shift) => shift.shiftId === tab.shift.id) !== undefined;
            });
        }
    }

    const shiftTabs = [
        defaultReservationShiftFilterTab,
        ...shiftsMapping.map((sh) => ({
            label: sh.shift.name[i18n.language as TLanguageKey],
            value: sh.id,
        })),
    ];
    if (displayUnassignedShift) {
        shiftTabs.splice(1, 0, unassignedShiftTab);
    }
    const swipeIndex = Math.max(
        0,
        withTablePlan
            ? areaTabs.findIndex(({ value }) => value === filters.areaId)
            : shiftTabs.findIndex(({ value }) => value === filters.shiftId),
    );

    useEffect(() => {
        setFilters(
            withTablePlan
                ? { areaId: defaultReservationAreaFilterTab.value }
                : { shiftId: defaultReservationShiftFilterTab.value },
        );
    }, [filters.date]);

    const previousSearch = useRef(filters.search);
    const onBlurSearchField = () => {
        previousSearch.current = filters.search;
    };

    const noReservations = Boolean(
        reservationsQuery.data?.reservations?.every(ReservationUtils.isReservationCancelled),
    );

    useEffect(() => {
        const sanitizeUrlParams = (newParams: Nullable<Partial<IReservationUrlParams>>): IReservationUrlParams => ({
            date:
                newParams.date && DateTime.fromFormat(newParams.date, SERVER_DATE_FORMAT).isValid
                    ? newParams.date
                    : DateTime.local().toFormat(SERVER_DATE_FORMAT),
            areaId:
                !newParams.areaId || areaTabs.every(({ value }) => value !== newParams.areaId)
                    ? defaultReservationAreaFilterTab.value
                    : newParams.areaId,
            shiftId:
                !newParams.shiftId || shiftTabs.every(({ value }) => value !== newParams.shiftId)
                    ? defaultReservationShiftFilterTab.value
                    : newParams.shiftId,
            search: typeof newParams.search === 'string' ? newParams.search : '',
            cancelled: newParams.cancelled === 'true' ? 'true' : 'false',
        });

        setParams(
            (prevParams) => sanitizeUrlParams(Object.fromEntries(prevParams)) as unknown as Record<string, string>,
        );
    }, [JSON.stringify(filters)]);

    useEffect(() => {
        // reset shift and area filters if search field is being used
        // but do not reset it if search field previously had any value
        if (filters.search && !previousSearch.current) {
            setFilters({
                shiftId: defaultReservationShiftFilterTab.value,
                areaId: defaultReservationAreaFilterTab.value,
            });
        }
        if (previousSearch.current) {
            previousSearch.current = filters.search;
        }
    }, [filters.search]);

    return {
        rawReservations: reservationsQuery.data?.reservations,
        reservationsQuery: reservationsQuery,
        filters,
        setFilters,
        onBlurSearchField,
        filterOptions: {
            shifts: shiftTabs,
            areas: areaTabs,
            constants: {
                defaultShift: defaultReservationShiftFilterTab,
                unassignedShift: unassignedShiftTab,
                defaultArea: defaultReservationAreaFilterTab,
            },
        },
        dateTime: currentDate,
        swipeIndex,
        noReservations,
    };
}

interface IUseReservationActionsReturn {
    openCreateReservationModal: (defaultValues?: Partial<IReservationForm>) => void;
    openEditReservationModal: (reservation: IReservation) => void;
    openWalkinReservationModal: () => void;
}

const useReservationActions = (): IUseReservationActionsReturn => {
    const { t } = useTranslation();
    const [walkinDisabled, setWalkinDisabled] = React.useState(false);
    const restaurantQuery = useRestaurant();
    const { openReservationForm } = useReservationFormContext();
    const { alert } = useConfirmContext();

    const openCreateReservationModal = (defaultValues?: Partial<IReservationForm>) => {
        openReservationForm(undefined, ReservationDetailsType.CREATE, defaultValues);
    };
    const openEditReservationModal = (value: IReservation) => {
        openReservationForm(value, value.guestInfo ? ReservationDetailsType.EDIT : ReservationDetailsType.WALKIN);
    };
    const openWalkinReservationModal = () => {
        if (walkinDisabled) {
            alert({
                title: t('reservations.view.walkin.notpossible.title'),
                msg: t('reservations.view.walkin.notpossible.message'),
            });
        } else {
            openReservationForm(undefined, ReservationDetailsType.WALKIN);
        }
    };

    useEffect(() => {
        if (restaurantQuery.data) {
            setWalkinDisabled(
                !RestaurantUtils.hasShiftForDate(DateTime.local(), restaurantQuery.data.configuration, true),
            );
        }
    }, [restaurantQuery.data]);

    return {
        openCreateReservationModal,
        openWalkinReservationModal,
        openEditReservationModal,
    };
};

export type { IReservationsFilters };
export {
    filterReservations,
    useFilterReservations,
    useReservationsList,
    useReservationActions,
    DEFAULT_RESERVATION_AREA_FILTER_TAB_ID,
    DEFAULT_RESERVATION_SHIFT_FILTER_TAB_ID,
    UNASSIGNED_RESERVATION_SHIFT_FILTER_TAB_ID,
};
