import {
    ButtonGroup,
    DATE_FORMAT,
    DateField,
    IReservation,
    IReservationsShiftGroupWithGuests,
    IRestaurantConfiguration,
    ISpeedDialActionProps,
    Label,
    RESERVATIONS_FILTERS_COLLAPSE_STATE,
    ReservationUtils,
    RestaurantUtils,
    SERVER_DATE_FORMAT,
    SpeedDial,
    Switch,
    Tabs,
    TextField,
    TIME_FORMAT,
} from '@localina/core';
import { BookNewIcon, WalkinIcon } from '@localina/icons';
import { Chip, Collapse } from '@mui/material';
import { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import SwipeableViews from 'react-swipeable-views';
import { useRestaurant } from '../../api/queries/restaurants';
import { Page, ReservationsShiftGroup } from '../../components';
import CollapseButton from '../../components/CollapseButton/CollapseButton';
import { useLocalStorageCollapseState } from '../../components/CollapseButton/localStorageCollapseState';
import ExportReservationsButton from '../../components/ExportReservationsButton/ExportReservationsButton';
import { ReservationsInfoMessages } from '../../components/ReservationsInfoMessages';
import RestaurantDailyNote from '../../components/RestaurantDailyNote/RestaurantDailyNote';
import TablePlanReservationsSwitchButton from '../../components/TablePlanReservationsSwitchButton/TablePlanReservationsSwitchButton';
import { UserFeature } from '../../components/UserFeature';
import { IReservationForm } from '../../schemas/interfaces';
import {
    filterReservations,
    UNASSIGNED_RESERVATION_SHIFT_FILTER_TAB_ID,
    useReservationActions,
    useReservationsList,
} from '../../utils/ReservationsUtils';

const Reservations: React.FC = () => {
    const { t } = useTranslation();
    const restaurantQuery = useRestaurant();

    const { openCreateReservationModal, openEditReservationModal, openWalkinReservationModal } =
        useReservationActions();
    const {
        reservationsQuery,
        filters,
        setFilters,
        filterOptions,
        onBlurSearchField,
        dateTime,
        swipeIndex,
        noReservations,
    } = useReservationsList(false);

    const [filtersCollapsed, setFiltersCollapsed] = useLocalStorageCollapseState(RESERVATIONS_FILTERS_COLLAPSE_STATE);

    const restaurantConfiguration = restaurantQuery.data?.configuration;
    const shiftsMapping = RestaurantUtils.getShiftsForDate(dateTime, restaurantConfiguration);

    const reservationsData = useMemo(() => {
        if (reservationsQuery.data?.reservations) {
            const resData = shiftsMapping.reduce((acc, shift) => {
                const reservations = filterReservations(
                    reservationsQuery.data.reservations,
                    restaurantConfiguration,
                    {
                        shiftId: shift.id,
                        showCancelled: filters.cancelled === 'true',
                        searchQuery: filters.search || '',
                        areaId: filters.areaId || '',
                    },
                    t('reservations.view.fields.walkin'),
                );

                if (reservations.length) {
                    acc[shift.id] = {
                        reservations,
                        guests: ReservationUtils.sumTotalParticipantsOfReservations(reservations),
                        shift: shift.shift,
                    };
                }
                return acc;
            }, {} as IReservationsShiftGroupWithGuests);

            const unassignedReservations = filterReservations(
                reservationsQuery.data.reservations,
                restaurantConfiguration,
                {
                    shiftId: filterOptions.constants.unassignedShift.value,
                    showCancelled: filters.cancelled === 'true',
                    searchQuery: filters.search || '',
                    areaId: filters.areaId || '',
                },
                t('reservations.view.fields.walkin'),
            );

            if (unassignedReservations.length) {
                resData[filterOptions.constants.unassignedShift.value] = {
                    reservations: unassignedReservations,
                    guests: ReservationUtils.sumTotalParticipantsOfReservations(unassignedReservations),
                };
            }

            return resData;
        } else {
            return undefined;
        }
    }, [
        restaurantConfiguration,
        reservationsQuery.data?.reservations,
        filters.cancelled,
        filters.areaId,
        filters.search,
        filters.shiftId,
    ]);

    const createActions: ISpeedDialActionProps[] = [
        {
            label: t('reservations.actions.createReservation'),
            icon: <BookNewIcon />,
            onClick: openCreateReservationModal,
        },
        {
            label: t('reservations.actions.createWalkin'),
            icon: <WalkinIcon />,
            onClick: openWalkinReservationModal,
        },
    ];

    const totalNumberOfGuests = useMemo(() => {
        let result = 0;
        if (reservationsData) {
            if (filters.shiftId && filterOptions.constants.defaultShift.value !== filters.shiftId) {
                result = reservationsData[filters.shiftId]?.guests || 0;
            } else {
                result = Object.values(reservationsData).reduce((acc, { guests }) => acc + guests, 0);
            }
        }
        return result;
    }, [filters.areaId, filters.shiftId, reservationsData]);

    const onSwipeableViewChange = (value: string | number) => {
        if (restaurantConfiguration) {
            if (typeof value === 'string') {
                setFilters({ shiftId: value });
            } else {
                setFilters({ shiftId: filterOptions.shifts[value].value });
            }
        }
    };

    return (
        <Page name="reservations" isLoading={reservationsQuery.isInitialLoading}>
            <UserFeature filter="tablePlans">
                <TablePlanReservationsSwitchButton switchTo={'tableplan'} />
            </UserFeature>
            <div className="reservations-header">
                <div className="reservations-header__content">
                    <div className="daily-note-wrapper">
                        {Boolean(dateTime) && <RestaurantDailyNote date={dateTime.toFormat(SERVER_DATE_FORMAT)} />}
                    </div>
                    <div className={'reservations-date-notifications-wrapper'}>
                        <div className="reservations-date">
                            <DateField
                                name="date"
                                label={t('reservations.fields.date')}
                                value={dateTime.toFormat(DATE_FORMAT)}
                                shouldHighlightDate={(calendarDate: DateTime) =>
                                    RestaurantUtils.isClosedAndHasNoShiftsOnDate(calendarDate, restaurantConfiguration)
                                        ? 'restaurant-closed-day'
                                        : false
                                }
                                onChange={(value) => {
                                    setFilters({
                                        date: DateTime.fromFormat(value, DATE_FORMAT).toFormat(SERVER_DATE_FORMAT),
                                    });
                                }}
                                withChevrons
                                withTodayButton
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="reservations-body">
                <div className="reservations-collapsible-filters">
                    <Collapse
                        in={
                            !filtersCollapsed &&
                            Boolean(filterOptions.areas.length > 1 || filterOptions.shifts.length > 1)
                        }
                    >
                        {Boolean(filterOptions.shifts.length > 1) && (
                            <Tabs
                                active={filters.shiftId || filterOptions.constants.defaultShift.value}
                                tabs={filterOptions.shifts}
                                onChange={onSwipeableViewChange}
                            />
                        )}
                        {Boolean(filterOptions.areas.length > 1) && (
                            <UserFeature filter="areas">
                                <div className="button-group-style">
                                    <ButtonGroup
                                        value={filters.areaId || filterOptions.constants.defaultArea.value}
                                        options={filterOptions.areas}
                                        onChange={(areaId) => {
                                            setFilters({ areaId });
                                        }}
                                    />
                                </div>
                            </UserFeature>
                        )}
                        <div className="reservations-body__bottom-toolbar">
                            {Boolean(filterOptions.areas.length > 1 || filterOptions.shifts.length > 1) && (
                                <div className="reservations-body__bottom-toolbar__text-filter">
                                    <TextField
                                        name={'reservations-filter'}
                                        label={t('reservations.searchReservations')}
                                        value={filters.search || ''}
                                        onChange={(search) => {
                                            setFilters({ search });
                                        }}
                                        allowClear
                                        onBlur={onBlurSearchField}
                                    />
                                </div>
                            )}
                            {Boolean(filterOptions.areas.length > 1 || filterOptions.shifts.length > 1) && (
                                <div className="reservations-body__bottom-toolbar__switch">
                                    <Switch
                                        label={t('reservations.showCancelled')}
                                        onChange={(cancelled) => {
                                            setFilters({ cancelled: cancelled ? 'true' : 'false' });
                                        }}
                                        checked={filters.cancelled === 'true'}
                                    />
                                    <div className="reservations-body__bottom-toolbar__button">
                                        <ExportReservationsButton
                                            dateTime={dateTime}
                                            filters={{
                                                shiftId: filters.shiftId || filterOptions.constants.defaultShift.value,
                                                areaId: filters.areaId || filterOptions.constants.defaultArea.value,
                                                searchQuery: filters.search || '',
                                                showCancelled: filters.cancelled === 'true',
                                            }}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </Collapse>
                    {Boolean(filterOptions.areas.length > 1 || filterOptions.shifts.length > 1) && (
                        <CollapseButton collapsed={filtersCollapsed} setCollapsed={setFiltersCollapsed} />
                    )}
                </div>

                {restaurantConfiguration && (
                    <>
                        <SwipeableViews
                            className="swipeable-views"
                            index={swipeIndex}
                            onChangeIndex={onSwipeableViewChange}
                            disableLazyLoading
                        >
                            {filterOptions.shifts.map((shiftTab) => {
                                return (
                                    <div key={shiftTab.value} className="reservations-body__content">
                                        <ReservationsInfoMessages
                                            dateFormatted={dateTime.toFormat(DATE_FORMAT)}
                                            configuration={restaurantConfiguration}
                                            noReservations={noReservations}
                                            noFilteredReservations={!totalNumberOfGuests}
                                            className={'emptyShiftWrapper'}
                                            clearFilters={() => {
                                                setFilters({
                                                    shiftId: '',
                                                    areaId: '',
                                                    search: '',
                                                });
                                            }}
                                        />
                                        {Boolean(shiftTab.value === filterOptions.constants.defaultShift.value) && (
                                            <AllDayTab
                                                reservationsData={reservationsData}
                                                restaurantConfiguration={restaurantConfiguration}
                                                openEditReservationModal={openEditReservationModal}
                                                openCreateReservationModal={openCreateReservationModal}
                                            />
                                        )}
                                        {Boolean(
                                            filterOptions.constants.defaultShift.value !== shiftTab.value &&
                                                Boolean(reservationsData?.[shiftTab.value]),
                                        ) && (
                                            <>
                                                {shiftTab.value === filterOptions.constants.unassignedShift.value && (
                                                    <UnassignedReservationsInfo />
                                                )}
                                                <ReservationsShiftGroup
                                                    {...reservationsData![shiftTab.value]}
                                                    restaurantConfiguration={restaurantConfiguration}
                                                    onCreate={openCreateReservationModal}
                                                    onEdit={openEditReservationModal}
                                                    unassignedShift={
                                                        shiftTab.value === filterOptions.constants.unassignedShift.value
                                                    }
                                                />
                                            </>
                                        )}
                                    </div>
                                );
                            })}
                        </SwipeableViews>
                    </>
                )}
            </div>
            <div className="reservations-footer">
                <Label type="info" value={t('reservations.group.participants')} />
                <Label type="info" value={totalNumberOfGuests} />
            </div>
            <SpeedDial label="create" actions={createActions} />
        </Page>
    );
};

interface IAllDayTabProps {
    restaurantConfiguration?: IRestaurantConfiguration;
    reservationsData?: IReservationsShiftGroupWithGuests;
    openCreateReservationModal: (defaultFormValues?: Partial<IReservationForm>) => void;
    openEditReservationModal: (reservation: IReservation) => void;
}

const AllDayTab = (props: IAllDayTabProps) => {
    const sortedShiftIds = sortShiftIdsInAllShiftsTab(props.reservationsData);
    return (
        <>
            {Boolean(sortedShiftIds.length && sortedShiftIds.includes(UNASSIGNED_RESERVATION_SHIFT_FILTER_TAB_ID)) && (
                <UnassignedReservationsInfo />
            )}
            {sortedShiftIds.map((key) => (
                <ReservationsShiftGroup
                    key={key}
                    {...props.reservationsData![key]}
                    restaurantConfiguration={props.restaurantConfiguration!}
                    onCreate={props.openCreateReservationModal}
                    onEdit={props.openEditReservationModal}
                    unassignedShift={key === UNASSIGNED_RESERVATION_SHIFT_FILTER_TAB_ID}
                />
            ))}
        </>
    );
};

const sortShiftIdsInAllShiftsTab = (reservationsData?: IReservationsShiftGroupWithGuests) => {
    return reservationsData
        ? Object.keys(reservationsData)
              .slice()
              .sort((a, b) => {
                  // put reservations of unassigned shifts on top of the list first, then sort by time
                  const unassignedA = a === UNASSIGNED_RESERVATION_SHIFT_FILTER_TAB_ID;
                  const unassignedB = b === UNASSIGNED_RESERVATION_SHIFT_FILTER_TAB_ID;
                  if (unassignedA && !unassignedB) {
                      return -1;
                  }
                  if (unassignedB && !unassignedA) {
                      return 1;
                  }
                  if (!reservationsData[a].shift?.from) {
                      return 1;
                  }
                  if (!reservationsData[b].shift?.from) {
                      return -1;
                  }
                  return DateTime.fromFormat(reservationsData[a].shift!.from, TIME_FORMAT).diff(
                      DateTime.fromFormat(reservationsData[b].shift!.from, TIME_FORMAT),
                  ).milliseconds;
              })
        : [];
};

const UnassignedReservationsInfo = () => {
    const { t } = useTranslation();
    return (
        <Chip
            className="unassigned-reservations-info"
            label={t('reservations.fields.unassignedReservationsInfoMessage')}
            variant="outlined"
        />
    );
};

export default Reservations;
