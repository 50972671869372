import { IMessage, IMessageDisplayText, Label, MessageType, TextField } from '@localina/core';
import { ArticleIcon } from '@localina/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmationMailTemplate from './ConfirmationMailTemplate/ConfirmationMailTemplate';

interface IProps {
    message: IMessage;
    messageDisplayText: IMessageDisplayText;
    onChange: (messageDisplayText: IMessageDisplayText) => void;
}

const CONFIRMATION_MAIL_TEXT_CHAR_LIMIT = 600;
const CONFIRMATION_MAIL_LINK_NAME_CHAR_LIMIT = 50;

export const MessageDisplayText: React.FC<IProps> = (props) => {
    const { t } = useTranslation();

    const handleMessageDisplayTextChange = (field: string) => (value: string) => {
        props.onChange({
            ...props.messageDisplayText,
            [field]: value,
        });
    };

    const isConfirmationMail = props.message.type === MessageType.CONFIRMATION_MAIL;

    return (
        <div className="form">
            <TextField
                name="language"
                label={t('message.fields.language')}
                icon={true}
                value={props.messageDisplayText.language.toUpperCase()}
                disabled={true}
            />
            {props.message.link && props.message.link !== '' && (
                <TextField
                    name="link"
                    label={t('message.fields.linkName')}
                    icon={<ArticleIcon />}
                    value={props.messageDisplayText.link ?? ''}
                    onChange={handleMessageDisplayTextChange('link')}
                    helperText={
                        isConfirmationMail &&
                        CONFIRMATION_MAIL_LINK_NAME_CHAR_LIMIT < (props.messageDisplayText.link?.length || 0) ? (
                            <Label
                                type={'info'}
                                value={t('validation.string_too_long', {
                                    ns: 'core',
                                    max: CONFIRMATION_MAIL_LINK_NAME_CHAR_LIMIT,
                                })}
                                error
                            />
                        ) : (
                            t('message.helperTexts.linkName')
                        )
                    }
                />
            )}
            <TextField
                name="text"
                label={t('message.fields.text')}
                icon={<ArticleIcon />}
                value={props.messageDisplayText.text}
                required={true}
                multiline={true}
                minRows={8}
                onChange={handleMessageDisplayTextChange('text')}
                helperText={
                    isConfirmationMail && CONFIRMATION_MAIL_TEXT_CHAR_LIMIT < props.messageDisplayText.text.length ? (
                        <Label
                            type={'info'}
                            value={t('validation.string_too_long', {
                                ns: 'core',
                                max: CONFIRMATION_MAIL_TEXT_CHAR_LIMIT,
                            })}
                            error
                        />
                    ) : undefined
                }
            />
            {isConfirmationMail && (
                <>
                    <Label
                        type="title"
                        value={t('message.confirmationMailPreview.preview')}
                        extraClassName={'preview-confirmation-mail'}
                    />
                    <ConfirmationMailTemplate
                        lng={props.messageDisplayText.language}
                        text={props.messageDisplayText.text}
                        link={props.messageDisplayText.link || props.message.link}
                    />
                </>
            )}
        </div>
    );
};

export const validateMessageDisplayText = (messageDisplayText: IMessageDisplayText, messageType?: MessageType) => {
    const hasText = messageDisplayText.text !== '';
    const textLengthIsValid =
        messageType === MessageType.CONFIRMATION_MAIL
            ? messageDisplayText.text.length <= CONFIRMATION_MAIL_TEXT_CHAR_LIMIT
            : true;
    const linkLengthIsValid =
        messageType === MessageType.CONFIRMATION_MAIL
            ? (messageDisplayText.link?.length || 0) <= CONFIRMATION_MAIL_LINK_NAME_CHAR_LIMIT
            : true;

    return hasText && textLengthIsValid && linkLengthIsValid;
};
