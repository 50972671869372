import { ButtonGroup, IAccountRestaurant, IButtonGroupOption, Label } from '@localina/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getRestaurantPlanIcon, getRestaurantTestIcon } from '../../utils';
import ButtonGroupContentWithIcon from '../ButtonGroupContentWithIcon/ButtonGroupContentWithIcon';
import { Drawer } from '../index';
import DeleteDatabaseSection from './DeleteDatabaseSection';

interface IProps {
    isOpen: boolean;
    onClose: () => void;
    onSave: (plan: string, restaurantStatus: string) => void;
    restaurant?: IAccountRestaurant;
}

const EditSingleRestaurantPlanDrawer: React.FC<IProps> = (props) => {
    const { t } = useTranslation();
    const [planSelected, setPlanSelected] = useState<string>(props.restaurant?.subscription || '');
    const [restaurantStatus, setRestaurantStatus] = useState<string>(props.restaurant?.restaurantStatus || 'active');

    const planOptions: IButtonGroupOption[] = [
        {
            label: <ButtonGroupContentWithIcon icon={getRestaurantPlanIcon('free')} label="Free" />,
            value: 'free',
        },
        {
            label: <ButtonGroupContentWithIcon icon={getRestaurantPlanIcon('standard')} label="Standard" />,
            value: 'standard',
        },
    ];

    const testOptions: IButtonGroupOption[] = [
        {
            label: <ButtonGroupContentWithIcon icon={getRestaurantTestIcon('active')} label="Active" />,
            value: 'active',
        },
        {
            label: <ButtonGroupContentWithIcon icon={getRestaurantTestIcon('inactive')} label="Inactive" />,
            value: 'inactive',
        },
        {
            label: <ButtonGroupContentWithIcon icon={getRestaurantTestIcon('test')} label="Test" />,
            value: 'test',
        },
    ];

    const isFormValid =
        (planSelected !== props.restaurant?.subscription || restaurantStatus !== props.restaurant?.restaurantStatus) &&
        planSelected !== '';

    useEffect(() => {
        if (props.restaurant) {
            if (props.restaurant.subscription) {
                setPlanSelected(props.restaurant.subscription);
            }
            if (props.restaurant.restaurantStatus) {
                setRestaurantStatus(props.restaurant.restaurantStatus);
            }
        }
    }, [props.restaurant]);

    return (
        <Drawer
            className="edit-restaurant-plan-drawer"
            open={props.isOpen}
            title={t('supportAccounts.listOfRestaurants.editRestaurant.title')}
            disabled={!isFormValid}
            onClose={props.onClose}
            onSave={() => {
                props.onSave(planSelected, restaurantStatus);
            }}
        >
            <div className="edit-single-account-restaurant">
                <Label
                    type={'title'}
                    value={t('supportAccounts.listOfRestaurants.editRestaurant.labels.restaurant')}
                    variant="h5"
                />
                <div className="name-title">
                    <Label
                        type={'label'}
                        value={t('supportAccounts.listOfRestaurants.editRestaurant.labels.restaurantNaming')}
                    />
                </div>
                <div className="restaurant-name">
                    <Label type={'title'} value={props.restaurant?.name} variant="h6" />
                </div>
                <div className="version-title">
                    <Label
                        type={'title'}
                        value={t('supportAccounts.listOfRestaurants.editRestaurant.labels.subscription')}
                        variant="h5"
                    />
                </div>
                <ButtonGroup
                    value={planSelected}
                    options={planOptions}
                    onChange={(value: string) => {
                        setPlanSelected(value);
                    }}
                />
                <div className="version-title">
                    <Label
                        type={'title'}
                        value={t('supportAccounts.listOfRestaurants.editRestaurant.labels.test')}
                        variant="h5"
                    />
                </div>
                <ButtonGroup
                    value={restaurantStatus}
                    options={testOptions}
                    onChange={(value: string) => setRestaurantStatus(value)}
                />
                <DeleteDatabaseSection restaurant={props.restaurant} />
            </div>
        </Drawer>
    );
};

export default EditSingleRestaurantPlanDrawer;
