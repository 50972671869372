import { IAccount, IAccountList, IRestaurants, ISupportUser, IUser, Rest } from '@localina/core';
import { GenericAbortSignal } from 'axios';
import { IUpdateRestaurantSubscriptionRequest } from '../../containers/Support/Accounts/SupportAccounts';
import { ILocalinaSupportApi } from '../../interfaces';
import { SupportApiConstants } from '../../utils';

export class LocalinaSupportApi implements ILocalinaSupportApi {
    async getAccountList(
        page: number,
        limit: number,
        query?: string,
        signal?: GenericAbortSignal,
    ): Promise<IAccountList> {
        return Rest.getRequest({ url: SupportApiConstants.getAccountList(page, limit, query).toString(), signal });
    }

    async getAccount(accountId: string): Promise<IAccount> {
        return Rest.getRequest(SupportApiConstants.getAccount(accountId).toString());
    }

    async getAccountRestaurants(accountId: string): Promise<IRestaurants> {
        return Rest.getRequest(SupportApiConstants.getAccountRestaurants(accountId).toString());
    }

    async getAccountUsers(accountId: string): Promise<IUser[]> {
        return Rest.getRequest(SupportApiConstants.getAccountUsers(accountId).toString());
    }

    async getUsers(): Promise<ISupportUser[]> {
        return Rest.getRequest(SupportApiConstants.getUsers().toString());
    }

    async getUser(userId: string): Promise<ISupportUser> {
        return Rest.getRequest(SupportApiConstants.getUser(userId).toString());
    }

    async createUser(user: ISupportUser): Promise<ISupportUser> {
        return Rest.postRequest({
            url: SupportApiConstants.getUsers().toString(),
            payload: user,
        });
    }

    async updateUser(user: ISupportUser): Promise<void> {
        return Rest.putRequest({
            url: SupportApiConstants.getUser(user.id).toString(),
            payload: user,
        });
    }

    async deleteUser(user: ISupportUser): Promise<void> {
        return Rest.deleteRequest(SupportApiConstants.getUser(user.id).toString());
    }

    async deleteRestaurantGuestsAndReservations(restaurantId: string): Promise<void> {
        return Rest.deleteRequest(SupportApiConstants.deleteRestaurantGuests(restaurantId).toString());
    }

    async deleteRestaurantReservations(restaurantId: string): Promise<void> {
        return Rest.deleteRequest(SupportApiConstants.deleteRestaurantReservations(restaurantId).toString());
    }

    async updateRestaurantAccountPlan(
        updateRestaurantSubscriptionRequest: IUpdateRestaurantSubscriptionRequest,
    ): Promise<void> {
        return Rest.putRequest({
            url: SupportApiConstants.getUpdateRestaurantSubscription(
                updateRestaurantSubscriptionRequest.restaurantId,
            ).toString(),
            payload: {
                subscription: updateRestaurantSubscriptionRequest.subscription,
                restaurantStatus: updateRestaurantSubscriptionRequest.restaurantStatus,
            },
        });
    }

    async grantAccountAccess(accountId: string): Promise<void> {
        return Rest.postRequest({
            url: SupportApiConstants.grantAccess(accountId).toString(),
            payload: {},
        });
    }

    revokeAccountAccess(): Promise<void> {
        return Rest.postRequest({
            url: SupportApiConstants.revokeAccess().toString(),
            payload: {},
        });
    }
}
