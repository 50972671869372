import {
    DATE_FORMAT,
    DateField,
    DateTimeUtils,
    IMessage,
    IMessageDisplayText,
    ISpeedDialActionProps,
    Label,
    List,
    MessageType,
    SERVER_DATE_FORMAT,
    SpeedDial,
    StringUtils,
    Switch,
    TextField,
    ValidationUtils,
} from '@localina/core';
import { ArticleIcon, LanguageIcon } from '@localina/icons';
import { Grid } from '@mui/material';
import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MessageDisplayTextListItem } from '../../../components';

interface IProps {
    message: IMessage;
    onChange: (message: IMessage) => void;
    onCreateDisplayText: (language: string) => void;
    onEditDisplayText: (messageDisplayText: IMessageDisplayText) => void;
}

export const Message: React.FC<IProps> = (props) => {
    const { t } = useTranslation();

    const [isDefaultMessage, setIsDefaultMessage] = React.useState(true);

    React.useEffect(() => {
        setIsDefaultMessage(
            !DateTime.fromFormat(props.message.from ?? '', SERVER_DATE_FORMAT).isValid &&
                !DateTime.fromFormat(props.message.to ?? '', SERVER_DATE_FORMAT).isValid,
        );
    }, []);

    const handleMessageChange = (field: string) => (value: string) => {
        props.onChange({
            ...props.message,
            [field]: value,
        });
    };

    const handleSwitchChange = (value: boolean) => {
        setIsDefaultMessage(value);
        props.onChange({
            ...props.message,
            from: value ? undefined : '',
            to: value ? undefined : '',
        });
    };

    const handleDateChange = (field: string) => (value: string) => {
        const date = DateTime.fromFormat(value, DATE_FORMAT);
        props.onChange({
            ...props.message,
            [field]: date.isValid ? date.toFormat(SERVER_DATE_FORMAT) : '',
        });
    };

    const createActions: ISpeedDialActionProps[] = ['en', 'it', 'fr', 'de'].map((lng) => ({
        label: t(`core:languages.${lng}`),
        icon: <LanguageIcon />,
        disabled: props.message && props.message.displayTexts.some((it) => it.language.toLowerCase() === lng),
        onClick: () => props.onCreateDisplayText(lng),
    }));

    return (
        <>
            <div className="form">
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <TextField
                            name="name"
                            label={t('message.fields.name')}
                            icon={<ArticleIcon />}
                            value={props.message.name}
                            required={true}
                            onChange={handleMessageChange('name')}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {props.message.type !== MessageType.EMAIL && (
                            <TextField
                                name="link"
                                label={t('message.fields.link')}
                                icon={true}
                                helperText={t('message.helperTexts.link')}
                                value={props.message.link ?? ''}
                                onChange={handleMessageChange('link')}
                            />
                        )}
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Switch
                            label={t('message.fields.validity')}
                            checked={isDefaultMessage}
                            onChange={handleSwitchChange}
                            tooltip={t('message.infoText.basicInformation')}
                        />
                    </Grid>
                    <div className="row">
                        <Grid item xs={6} md={4}>
                            <DateField
                                name="from"
                                label={t('message.fields.from')}
                                icon={true}
                                value={
                                    props.message.from &&
                                    DateTime.fromFormat(props.message.from, SERVER_DATE_FORMAT).toFormat(DATE_FORMAT)
                                }
                                disabled={isDefaultMessage}
                                onChange={handleDateChange('from')}
                            />
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <DateField
                                name="to"
                                label={t('message.fields.to')}
                                icon={true}
                                value={
                                    props.message.to &&
                                    DateTime.fromFormat(props.message.to, SERVER_DATE_FORMAT).toFormat(DATE_FORMAT)
                                }
                                minDate={
                                    props.message.from
                                        ? DateTime.fromFormat(props.message.from, SERVER_DATE_FORMAT)
                                        : undefined
                                }
                                disabled={isDefaultMessage}
                                onChange={handleDateChange('to')}
                            />
                        </Grid>
                    </div>
                </Grid>
            </div>
            <div className="message__subheader">
                <Label
                    type="title"
                    variant="h6"
                    value={t('message.languages')}
                    tooltip={t('message.infoText.languages')}
                />
            </div>
            <List>
                {props.message.displayTexts.map((displayText, i) => (
                    <MessageDisplayTextListItem key={i} displayText={displayText} onClick={props.onEditDisplayText} />
                ))}
            </List>
            <SpeedDial label="create" actions={createActions} />
        </>
    );
};

export const validateMessage = (message: IMessage) => {
    const hasName = message.name !== '';
    if (!hasName) {
        return false;
    }

    const hasValidUrl = !message.link || message.link === '' || StringUtils.isValidUrl(message.link);
    if (!hasValidUrl) {
        return false;
    }

    const { from, to } = message;
    const hasNone = !from && from !== '' && !to && to !== '';
    const hasOnlyFrom = !!from && from !== '' && (!to || to === '');
    const hasOnlyTo = !!to && to !== '' && (!from || from === '');
    const hasBoth = !!from && from !== '' && !!to && to !== '';
    const hasValidDateRange =
        hasNone || hasOnlyFrom || hasOnlyTo || (hasBoth && DateTimeUtils.hasValidDateRange(from, to));
    if (!hasValidDateRange) {
        return false;
    }

    const hasDisplayTexts = message.displayTexts.length !== 0;
    if (!hasDisplayTexts) {
        return false;
    }

    return ValidationUtils.validateObject(message, { optionalFields: ['id', 'link', 'from', 'to'] });
};
