import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Path } from '../enums';

interface IPathParams {
    restaurantId?: string;
    guestId?: string;
    messageId?: string;
    reservationId?: string;
    userId?: string;
    tablePlanId?: string;
}

interface IQueryParams {
    [key: string]: string;
}

const generateUrl = (path: string, params: IPathParams, search?: IQueryParams) => {
    let url = path
        .replace(':restaurantId', params.restaurantId ?? '')
        .replace(':guestId', params.guestId ?? '')
        .replace(':messageId', params.messageId ?? '')
        .replace(':reservationId', params.reservationId ?? '')
        .replace(':userId', params.userId ?? '')
        .replace(':tablePlanId', params.tablePlanId ?? '');

    if (search) {
        const queryParams = new URLSearchParams();
        Object.keys(search).forEach((key) => {
            queryParams.append(key, search[key]);
        });
        url = url.concat(`?${queryParams.toString()}`);
    }

    return url;
};

const useIsPublicPath = () => {
    const location = useLocation();

    return useMemo(() => {
        const publicPaths: string[] = [Path.AUTH, Path.LOGIN, Path.REGISTER, Path.RESET_PASSWORD, Path.VERIFY_EMAIL];
        return publicPaths.includes(location.pathname);
    }, [location.pathname]);
};

export const PathUtils = {
    generateUrl,
};

export { useIsPublicPath };
