var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ChevronRightIcon } from '@localina/icons';
import { ListItem as MuiListItem } from '@mui/material';
import { StringUtils } from '../../..';
import { Label } from '../../Label';
export var ListItem = function (props) {
    var _a;
    var hasRightIcon = props.rightIcon !== undefined || props.onClick !== undefined;
    var classNames = StringUtils.combineStrings([
        'localina-listitem',
        props.classNames && props.classNames,
        props.leftIcon !== undefined && 'has-left-icon',
        hasRightIcon && 'has-right-icon',
    ]);
    var isText = function (value) {
        return typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean';
    };
    return (_jsxs(MuiListItem, __assign({ className: classNames, disableGutters: true, disabled: props.onClick === undefined, button: true, onClick: props.onClick, disableRipple: props.disableRipple }, { children: [props.leftIcon && _jsx("div", __assign({ className: "localina-listitem__left-icon" }, { children: props.leftIcon })), props.leftLabel && (_jsx(Label, { type: "text", value: props.leftLabel, extraClassName: 'localina-listitem__left-label' })), _jsxs("div", __assign({ className: "localina-listitem__content" }, { children: [isText(props.title) ? _jsx(Label, { type: "text", value: props.title }) : props.title, isText(props.subtitle) ? _jsx(Label, { type: "info", value: props.subtitle }) : props.subtitle] })), hasRightIcon && (_jsx("div", __assign({ className: "localina-listitem__right-icon" }, { children: (_a = props.rightIcon) !== null && _a !== void 0 ? _a : _jsx(ChevronRightIcon, {}) })))] })));
};
