import { IReservation, Label, Tooltip, PlatformName } from '@localina/core';
import { MenuBookOutlined, Public } from '@mui/icons-material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LogoLocalsearchIcon, GoogleIcon } from '@localina/icons';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';

interface IReservationOriginIconProps {
    reservation: IReservation;
}

const ReservationOriginIcon = ({ reservation }: IReservationOriginIconProps) => {
    const { t } = useTranslation();
    const isManual = Boolean(reservation.auditInfo?.createdBy);

    function getPlatformIcon(): ReactJSXElement {
        switch (reservation.platformName) {
            case PlatformName.GOOGLE:
                return <GoogleIcon />;
            case PlatformName.LOCALCH:
                return <LogoLocalsearchIcon />;
            default:
                return <Public />;
        }
    }

    return reservation.auditInfo ? (
        <Tooltip
            stopPropagation
            popperClassName="tooltip reservation-origin-icon-tooltip"
            clickAwayMouseEvent="onPointerDown"
            content={
                <Label type="label" value={isManual ? t('reservations.view.manual') : t('reservations.view.online')} />
            }
        >
            <div className="reservation-origin-icon">{isManual ? <MenuBookOutlined /> : getPlatformIcon()}</div>
        </Tooltip>
    ) : null;
};

export default ReservationOriginIcon;
